import { TramOutlined } from '@material-ui/icons';
import React,{Component} from 'react'
// import IDCardFrontView from './IDCardFrontView';
// import HealthCardBackView from './HealthCardBackView';
// import IDBackView from './IDBackView';
import FrontView from './FrontView'
import BackView from './BackView'
export default class HealthToolsFrontRoot extends Component{
    constructor(props){
        super(props)

        this.changeside = this.changeside.bind(this);
        this.printCardView = this.printCardView.bind(this);
        console.log("HEalthcard PAGE PROPS IS===",this.props)
    }

    changeside(){
//    alert("gekas")
     this.props.method(true)
    }

    printCardView() {
        // alert("printcaard call")
		window.print()
    }
    
    

    render(){
        return(
            // <div className='container id-card-front' id="printableId"> 
            <div className='id-card-front' id="printableId">  
         <FrontView {...this.props} printCardView={this.printCardView} data={this.props.data}
          changeside={this.changeside} showEmpId={this.props.showEmpId} network={this.props.network}
          contactNumber={this.props.contactNumber}
       
          />

				<div className="hidden printable"> <BackView {...this.props} changeside={this.changeside} network={this.props.network}
                 contactNumber={this.props.contactNumber} cardId={this.props.cardId} /> </div>

			</div >
        
    )
}
}


// import React,{Component} from 'react'
// import Modal2 from '@material-ui/core/Modal';
// import { withStyles } from '@material-ui/core/styles'
// import Backdrop from '@material-ui/core/Backdrop'
// import Fade from '@material-ui/core/Fade'
// import ReactCardFlip from 'react-card-flip'

// import HealthToolsBack from './HealthToolsBack'
// import HealthToolsFront from './HealthToolsFront'


// export default class HealthToolsFrontRoot extends Component{
//     constructor(props){
//         super(props)
//         this.state={
//             // visible:true,
//             // isFlipped:false,
//         }
//         this.handleClick = this.handleClick.bind(this)
//         this.closeModal = this.closeModal.bind(this)
//         // console.log("===============Health Tools card modal====================",this.props)
//     }

//     handleClick(e) {
//           alert("parent call")
//           this.props.flip(false)
//         // e.preventDefault()
//         // this.setState(prevState => ({ isFlipped: !prevState.isFlipped }))
//       }

//       closeModal(){
//         //   alert("modal close call")
//         //   this.setState({visible:false})
//         // this.props.hide(false)
//         this.props.close(false)
//       }

    

//     render(){
       
//         return(
//             <div>
//                  <HealthToolsFront 
//                   {...this.props} flip={this.handleClick} close={this.closeModal}
//                   />
//             </div>
//         )
//     }
// }

 