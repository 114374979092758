import React,{Component} from 'react'

export default class WebFooter extends Component{
    render() {
        return (
            <div class="webfooter">
                <div></div>
                <div>Copyright © 2020 Universal Health Fellowship. All rights reserved. | Powered by CarynHealth</div>
                <div>Version 4.1.0</div>
            </div>
        )
    }
}
