import React, { Component } from 'react'
import Header from '../Header'
import './ProgramInformation.css'
import { Container, Row, Col } from "react-bootstrap";
import { getProgramInfo,getCardEnableData ,
  getSourceID ,getAgentInfo,getEncryptData,getClientDetails,
  getEnrollMemberInfoById 

} from '../../ApiCall'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import MobileFooter from '../../MobileScreen/MobileFooter'
import WebFooter from '../../WebScreen/WebFooter'
import { Link } from 'react-router-dom'
import CommonLoader from '../../CommonLoader';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import {Modal} from 'react-bootstrap'
import Button from '@material-ui/core/Button';
import customStyle from "./CSS/stylesheet_UHS";
import { withStyles } from '@material-ui/core/styles';
import i18n from './i18next';
import Fab from '@material-ui/core/Fab';
import ChatIcon from '../ChatBox/ChatIcon';

const NextButton = withStyles(
  customStyle.PayButton
)(Button);

export default class ProgramInformation extends Component {
  constructor(props) {
    super(props);
    console.log("ProgramInformation", this.props);

    this.state = {
      loader: false,
      sampleData: sampleData,
      barRange: '0',
      barRangeYellow: '0',
	  acsmTotal:'',
      acsmmet:'',
      acsmremain:'',
      nsamet:'',
      nsaremain:'',
      programname:'',
      programname2:'',
      showwithacsm:false,
      clientID : '',
      associationId :'',
      clientName :'',
      brokerId:'',
      subID:''
    }
    console.log("this.state ", this.state);
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

  componentDidMount() {
    this.setState({
      loader: true
    })
    this.getCardEnable()
    if(localStorage.getItem('SOURCE') !== 'NEO'){
      this.getSourceIDbyemail()
    }
   
    getProgramInfo()
      .then((res) => {
        if (res.data && res.data.programInfo) {

          console.log("Progran info name===========",res.data.programInfo.programName)
          //const { programInfo, planInfo, expenseLimits } = res.data;
          this.setState({
            programInfo: res.data.programInfo,
            eligibleServices: res.data.planInfo,
            expenseLimits: res.data.expenseLimits,
            // programname:res.data.programInfo.programName,
          });

          let clientId={
            "clientId" : localStorage.getItem("CLIENT_ID"),
          }
          getClientDetails(clientId)
            .then(response=>{
              console.log("clientId=====",response.data.response)
                if(response.data.response){
                    this.setState({
                        clientID : response.data.response.clientId,
                        associationId : response.data.response.association,
                        clientName :response.data.response.clientName

                    });
                    
                }
            });
          

          // console.log("string split iss===",res1)

          var str = res.data.programInfo.programName
          if(str.includes("+")){
            var str1 = res.data.programInfo.programName;
             var res1 = str1.split(" ");
             this.setState({programname:res1[0] ,programname2:" with "+res1[2],showwithacsm:true})
             console.log("Program 2 name isssss===",this.state.programname2)
            // alert("+ found");
        } else{
            // alert("+ not fond");
            this.setState({programname:res.data.programInfo.programName,showwithacsm:false})
        }

         

          var percentascm =res.data.acsm.met/res.data.acsm.remaining;
          var percentascm =percentascm*100;

          var percentnsa =res.data.nsa.met/res.data.nsa.remaining;
          var percentnsa =percentnsa*100;

          this.setState({
            acsmmet:res.data.acsm.met,
            acsmTotal:res.data.acsm.total,
            acsmremain:res.data.acsm.remaining,
            nsamet:res.data.nsa.met,
            nsaremain:res.data.nsa.remaining,
            barRange:percentnsa,
            barRangeYellow:percentascm,
            loader: false
          })

          console.log("res Data====ascm", res.data.acsm);
          console.log("res Data====nsa", res.data.nsa);
          console.log("res Data", res);
          console.log("getProgramInfo", this.state);
        } else {
          this.setState({loader: false})
        }

      }).catch();
  }


  getCardEnable=()=>{
 
    let client_id=localStorage.getItem('CLIENT_ID')
    
    
      getCardEnableData(client_id,'ProgramInformation')
      .then(res=>{
        console.log("getCardEnableData=====",res.data.response.enable)
        
          if(res.data.response.enable=='false' || res.data.response.enable==false){
          window.location.href='/'     
          }
        
        
      })
    }

    getSourceIDbyemail() {
      getSourceID().then(res => {
        console.log('Member source id issss in contact cardd', res.data.memberIdSource)
        this.agentInfoget(res.data.memberIdSource)
        this.getEnrollMember(res.data.memberIdSource)
      })
    }

    agentInfoget(sourceid) {
      if(sourceid) {
        getAgentInfo(sourceid).then(res => {
          console.log('agent info get========', res)
          console.log('agent info get========', res.data.response)
          if (res.data.response) {
            this.setState({
              agentemail: res.data.response.email,
              agentname: res.data.response.name,
              agentno: res.data.response.phone,
              brokerId:res.data.response.brokerId
            })
            
          } else {
            
          }

        })
      }
    }
    getEnrollMember = (memberId) =>{
      getEnrollMemberInfoById(memberId)
      .then(response => {
		  if(response && response.data.response){
			this.setState({subID : response.data.response.subId,loader : false})
		  }else{
          this.setState({loader : false})
        }
      })
    }


    changeProgram=()=>{
      
      let obj = {
        "clientId": this.state.clientName,
        "associationId": this.state.associationId,
        "brokerId": this.state.brokerId,
        "fromMember":true,
        "isSelectProgram":true,
        "user_subId": localStorage.getItem("userMail"),
        "memberId": localStorage.getItem("sourceid"),
        "subID":this.state.subID
    };
  if(this.state.subID === null || this.state.subID === undefined || this.state.subID === ""){
    this.setState({
      errorModal : true,      
    })
  }else{
    let windowReference = window.open();
    getEncryptData(obj)
    .then(response => {
                    let URL = process.env.REACT_APP_LOGIN_ENROLLMENT +'login#state='+response.data.response
                    windowReference.location = URL;
                  })
  }
  
      
    }

    updateHousehold=()=>{
     
      let obj = {
        "clientId": this.state.clientName,
        "associationId": this.state.associationId,
        "brokerId": this.state.brokerId,
        "fromMember":true,
        "user_subId": localStorage.getItem("userMail"),
        "isHouseholdUpdate":true,
        "memberId": localStorage.getItem("sourceid"),
        "subID":this.state.subID ,
        "isEditCensus":false   //namita 26 april
    };
    if(this.state.subID === null || this.state.subID === undefined || this.state.subID === ""){
      this.setState({
        errorModal : true,      
      })
    }else{
      let windowReference = window.open();
      getEncryptData(obj)
      .then(response => {
        
        let URL = process.env.REACT_APP_LOGIN_ENROLLMENT + 'login#state='+response.data.response

        windowReference.location = URL;
      })
    }
   
   
    }

  goBack = () => {
    this.props.history.push('/')
  }

  handleCloseErrorModal =()=>{
    this.setState({
      errorModal : false
    })
  }


  render() {

    

    return (
      <div className="progInfoMainWrapper">

        {this.state.loader ? <CommonLoader /> : null}

        <div className="mobileViewHeader">
          <div className="mobile_header">
            <Link to="/"><ArrowBackIcon style={{ width: '24px', height: '24px', color: '#ffffff' }} /></Link>
            <div className="mobile_header_title">Program Information </div>
            {/* <NotificationsIcon style={{width:'24px',height:'24px',color:'rgba(255, 255, 255, 0.74)'}}/> */}
          </div>
        </div>

        <div className="webHeader">
          <Header name={"Program Information"} />
        </div>

        <div className="container progInfoContainer">
        <sapn className="Back_page" onClick={this.goBack}><ArrowBackIcon style={{ width: '24px', height: '24px', color: ' #543379',marginRight:'5px'}} onClick={this.goBack} />BACK</sapn>

          <div className="commonWrap" >
            <div className="progInfoSection">
              <h2 className="progInfoTitle">Program Information</h2>
              <div  className="row">
                <div className="col-12 col-md-3" style={{marginBottom: '30px'}}>
                  <h1 className="logoFont UHS6">{this.state.programname}</h1>
                  {
                    this.state.showwithacsm
                    ? <h1 className="logoFont ACSM">{this.state.programname2}</h1>
                    :null
                    // <h1 className="logoFont ACSM">{this.state.programname2}</h1>
                  }
                 
                </div>
                <div className="col-12 col-md-4 borderRight">

                  <h6 className="infoHeading">Summary</h6>
                  {
                    localStorage.getItem("NETWORK_NAME")=='smartshare50' || localStorage.getItem("NETWORK_NAME")=='smartshare25'?                    
                  
                  null
                  :
                  <div className="progessBarWrapper">
                  <div className="barTitle">Non-Sharable amount</div>
                  <div className="progressContainer">
                    <div className="progressIndicator" style={{width: this.state.barRange+'%'}}><ArrowRightAltIcon viewBox="0 6 24 24" style={{ color: '#ffffff' }} /></div>
                  </div>

                  <div className="amtWrapper">
                    <span className="metAmt">${this.numberWithCommas(this.state.nsamet)} met</span>
                    <span className="remainingAmt">${this.numberWithCommas(this.state.nsaremain)} remaining</span>
                  </div>
                </div>

                 }
                  

                  <div className="progessBarWrapper">
				  {
					   (this.state.acsmmet ==0 && this.state.acsmremain == 0
                         && this.state.acsmTotal == 0 )|| (this.state.acsmmet ==0.0 && this.state.acsmremain == 0.0
                          && this.state.acsmTotal == 0.0 )
                          ?
					  null
					:
				  <>
                    <div className="barTitle">Annual Co-Share Maximum Amount</div>
                    <div className="progressContainerYellow">
                      <div className="progressIndicatorYellow" style={{width: this.state.barRangeYellow+'%'}}><ArrowRightAltIcon viewBox="0 6 24 24" style={{ color: '#ffffff' }} /></div>
                    </div>

                    <div className="amtWrapper">
                      <span className="metAmt metAmtYellow">${this.numberWithCommas(this.state.acsmmet)} met</span>
                      <span className="remainingAmt">${this.numberWithCommas(this.state.acsmremain)} remaining</span>
                    </div>
					</>
				  }
                  </div>


                </div>
                <div className="col-12 col-md-5">
                <div className="leftData">
                  <div className="row">
                    <div className="col-12 col-md-5" style={{paddingBottom: '60px'}}>
                      <h6 className="infoHeading">Eligible Services</h6>
                      <div className="row">
                        {
                          this.state.eligibleServices && this.state.eligibleServices.map((col) => (
                            <div className="col-6 col-md-12" style={{marginBottom: '20px'}}>
                              <p className="label">{col.idcardField}</p>
                              <p className="number"  dangerouslySetInnerHTML={{ __html: col.fieldValue }}></p>
                            </div>
                         ))}
                      </div>
                    </div>
                    <div className="col-12 col-md-7" style={{paddingBottom: '60px'}}>
                      <h6 className="infoHeading">Sharing Limits</h6>
                      <div className="row">
                        {
                          this.state.expenseLimits && this.state.expenseLimits.map((col) => (
                              <div className="col-6 col-md-12" style={{marginBottom: '20px'}}>
                                <p className="label" dangerouslySetInnerHTML={{ __html: col.idcardField }}></p>
                                <p className="number">{col.fieldValue}</p>
                              </div>
                          ))}

                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
            <div className="progInfoFooter">
              <div className="row">
                <div className="col-md-6">
                            {/* onClick={this.changeProgram} */}

                <button type="button" onClick={this.changeProgram} disabled={localStorage.getItem('SOURCE') === 'NEO' ||  (localStorage.getItem("CLIENT_ID")=='6548'|| localStorage.getItem("CLIENT_ID")==6548)||(localStorage.getItem("CLIENT_ID")=='4367'|| localStorage.getItem("CLIENT_ID")==4367) 
                ||  (localStorage.getItem("CLIENT_ID")=='5540'|| localStorage.getItem("CLIENT_ID")==5540)||(localStorage.getItem("CLIENT_ID")=='4376'|| localStorage.getItem("CLIENT_ID")==4376)
              } 
				className={localStorage.getItem('SOURCE') === 'NEO' ||  (localStorage.getItem("CLIENT_ID")=='6548'|| localStorage.getItem("CLIENT_ID")==6548)||(localStorage.getItem("CLIENT_ID")=='4367'|| localStorage.getItem("CLIENT_ID")==4367)  
        ||  (localStorage.getItem("CLIENT_ID")=='5540'|| localStorage.getItem("CLIENT_ID")==5540)||(localStorage.getItem("CLIENT_ID")=='4376'|| localStorage.getItem("CLIENT_ID")==4376)
        ? "blueActionBtnDisable border-0 mr-2 text-uppercase":"blueActionBtn border-0 mr-2 text-uppercase"} style={{ marginTop: '10px' }}>
                   Change add-ons
                 </button>

                 {/* onClick={this.updateHousehold} */}

                 <button type="button" onClick={this.updateHousehold} disabled={localStorage.getItem('SOURCE') === 'NEO' } 
				 className={localStorage.getItem('SOURCE') === 'NEO' ? "blueActionBtnDisable border-0 mr-2 text-uppercase":"blueActionBtn border-0 mr-2 text-uppercase"} style={{ marginTop: '10px' }}>
                   Change Dependents
                 </button>
                </div>
                <div className="col-md-6 dpFlex">
                
                  {/*<div className="elevation"></div>*/}
                  <div className="footerText" style={{display:'flex', flexDirection:'row'}}>
                    <div>
                      <p>Need help?</p>
                      <p>Chat with our Health Share Representative</p>
                      <p>or call 1 (888) 366 6243.</p>
                    </div>
                    <div style={{marginLeft:12,}} className="web-Chat-Icon">
                      <Fab color="#41B5C2" style={{backgroundColor:"#533278"}} aria-label="add" onClick={()=>this.openChat()}>
                          <img src={require('../../../assets/images/carynIcon.jpg')} />
                      </Fab>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>

        </div>


        <div className="webFooter">
          <WebFooter />
        </div>

        <div className="mobileFooter">
          <MobileFooter/>
        </div>



      {/* ===============================Error Modal====================================== */}

<Modal size="small" show={this.state.errorModal} onHide={this.handleCloseErrorModal} centered  backdrop='static'>


<Modal.Header>
  <Modal.Title>Message</Modal.Title>
  <IconButton aria-label="close"  onClick={this.handleCloseErrorModal} style={{marginTop:'-13px'}}>
                          <CloseIcon />
                        </IconButton>
</Modal.Header>

  <Modal.Body>
    {/* {this.state.loader?<CommonLoader/>:null} */}
 <b> {i18n.t('PROGRAM_INFORMATION_ERROR_MESSAGE.SUBID_MISSING')}</b>
  </Modal.Body>

  <Modal.Footer>
<Button variant="secondary" onClick={this.handleCloseErrorModal} class="ButtonBG">
                OK
              </Button>
</Modal.Footer>

  </Modal>

  <ChatIcon openChat={()=>console.log('')}/>

      </div>
    )
  }
  openChat=()=>{
    sessionStorage.setItem('chatwindow',true);
    this.props.history.push('/');
  }
}


const names = ['James', 'Paul', 'John', 'George', 'Ringo'];
const sampleData = [
  {id: 733, planId: "7006", idcardField: "Telemedicine", fieldValue: "$0"},
  {id: 735, planId: "7006", idcardField: "PCP Office Visit", fieldValue: "$35"},
  {id: 736, planId: "7006", idcardField: "Urgent Care", fieldValue: "$100" },
  {id: 737, planId: "7006", idcardField: "ER Visit", fieldValue: "$400" }
];



