import React, { Component } from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Container, Row, Col,Navbar } from 'react-bootstrap'
import MobileFooter from './MobileFooter'
import MobileDocuments from './MobileDocuments'
import { BsThreeDotsVertical } from "react-icons/bs";
import CommonLoader from '../CommonLoader'
import { pdf,Document,Page,PDFDownloadLink} from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import {
  gethealthcard,
  getproviderLink,
  getExpensLimit,
  getProgramInfo,
  getsharingguidlineslink,
  getWelcomeBooklet,
  getCardDetails,
  getCardEnableData
} from '../ApiCall'
export default class DocumentsScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      enable : null,
      loader : false,
      providerLink:''
    }
  }

  componentDidMount() {
    this.setState({loader:true})
  
    this.getCardEnable()
    getCardDetails()
      .then( res => {
        if(res.data.response){
          let documentCardData = res.data.response.find(obj => obj.cardtitle === 'Documents');
          this.setState({
            enable : documentCardData.enable,
            loader:false
          });
        }
      })
  }


  getCardEnable=()=>{
 
    let client_id=localStorage.getItem('CLIENT_ID')
    
    this.setState({loader:true})
      getCardEnableData(client_id,'Documents')
      .then(res=>{
        console.log("getCardEnableData=====",res.data.response.enable)
        this.setState({loader:false})
          if(res.data.response.enable=='false' || res.data.response.enable==false){
          window.location.href='/'     
          }
        
        
      })
    }

  goBack = () => {
    this.props.history.push('')
  }

  openwelcome=()=>{
    // window.open("https://carynhealth-memberportal-dev-documents.s3.us-east-2.amazonaws.com/AFA/UHS+Welcome+Booklet+-+6kAFAUHSR0626E060920.pdf");
   
    getWelcomeBooklet().then(res => {
      console.log('Welcome booklet=======', res)
      if (res.data && res.data.length > 0) {
        console.log('Welcome booklet=======')
        let providerLink = res.data[0].fieldValue
        window.location.href=providerLink
       // saveAs(providerLink, "Welcome booklet.pdf");
      } else {
        console.log('Welcome booklet else block')
        alert('Data Not Availabel')
      }
    })

}

opensecond(){
  window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHS+Member+Responsibilities.pdf")
}

  openthird() {
    window.open(" https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHF-Statement-of-Shared-Faith-%26-Beliefs.pdf")

    // window.open(
    //   'https://carynhealth-memberportal-dev-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHF+Statement+of+Faith+%26+Beliefs.pdf'
    // )
  }

  getstartedHealth(){
    window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+How+do+I+Get+Started+with+Health+Sharing.pdf")
}

open5question(){
    window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+5+Questions+about+Health+Sharing.pdf")
}

tipsTelemedicin(){
    window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+Tips+on+Telemedicine.pdf")
}

great4reason(){
    window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+4+Great+Reasons+to+share+Universal+HealthShare+with+Your+Friends.pdf")
}

quick3facts(){
    window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+Doctors!+3+Quick+Facts+about+Health+Sharing.pdf")
}


  opensharingguidlines=()=> {
    

    getsharingguidlineslink().then(res => {
      console.log('Sharing Guidlines', res)
      if (res.data && res.data.length > 0) {
        console.log('Shating guidlines response')
        let providerLink = res.data[0].fieldValue
       window.location.href=providerLink
        //saveAs(providerLink, "Sharing Guidlines.pdf");
      } else {
        console.log('Sharing guidlines else block')
        alert('Data Not Availabel')
      }
    })
  }

  render() {
    return (
    <div>
      {this.state.loader? <CommonLoader/> : null}
        <div class="digitalcard_header">
           <ArrowBackIcon style={{ width: '24px', height: '24px', color: '#ffffff' }} onClick={() => this.goBack()} />
           <div class="digitalcard_headerdiv">Documents</div>
          {/* <NotificationsIcon style={{width:'24px',height:'24px',color:'rgba(255, 255, 255, 0.74)'}}/> */}
        </div>
        
      {
        this.state.enable  ?
          <div className="doc_infomiddiv text-left">
            <div className="doc_welcome_text" onClick={ this.openwelcome}>Welcome Booklet</div>
            <div className="doc_welcome_text" onClick={this.opensharingguidlines}>Sharing Guidelines</div>
            <div className="doc_welcome_text" onClick={() => this.opensecond()}>Member Responsibilities</div>
            <div className="doc_welcome_text" onClick={() => this.openthird()}>Statement of Shared Faith and Beliefs
            </div>

            <div className="doc_welcome_text" onClick={() => this.getstartedHealth()}>Get Started with Health Sharing
            </div>
            <div className="doc_welcome_text" onClick={() => this.open5question()}>5 Questions about Health Sharing
            </div>
            <div className="doc_welcome_text" onClick={() => this.tipsTelemedicin()}>Tips on Telemedicine</div>
            <div className="doc_welcome_text" onClick={() => this.great4reason()}>4 Great Reasons to share UHS</div>
            <div className="doc_welcome_text" onClick={() => this.quick3facts()}>3 Quick Facts for Doctors</div>

          </div>
          :
          this.state.enable!==null?
          <div style={{padding : '35px', marginTop : '50%'}}>
            <div className="tempModalTxt">We’re facing some technical difficulties, due to
              which this feature is currently unavailable. For
              support, call Member Services at {localStorage.getItem('CONTACT_NUMBER')}, Monday through Friday, 8.00am to
              8.00pm CST.</div>
          </div>
          :
          null

      }



    <div>
    <MobileFooter name="Documents"/>
    </div>
  </div>

      // <div class="documentsscreen">

      //    <div class="digitalcard_header">
      //     <ArrowBackIcon style={{ width: '24px', height: '24px', color: '#ffffff' }} onClick={() => this.goBack()} />
      //      <div class="digitalcard_headerdiv">Documents</div>
      //    {/* <NotificationsIcon style={{width:'24px',height:'24px',color:'rgba(255, 255, 255, 0.74)'}}/> */}
      //   </div>




      //   <div class="doc_card">
      //       <div class="doc_top_container">
      //       <img src={require('../Images/LeftDrawer Icon/documents-icon-active.svg')} class="doc_documents_img"/>
      //               <div class="doc_header_text">Documents</div>
      //               {/* < BsThreeDotsVertical  color="#4f4f4f" class="doc_three_dot" /> */}
      //       </div>

      //       <div>
      //              <div class="doc_infomiddiv text-left">
      //                   <div class="doc_welcome_text"  onClick={()=>this.openwelcome()}>Welcome Booklet</div>
      //                   <div class="doc_welcome_text"  onClick={()=>this.opensharingguidlines()}>Sharing Guidelines</div>
      //                   <div class="doc_welcome_text"  onClick={()=>this.opensecond()}>Member Responsibilities</div>
      //                   <div class="doc_welcome_text" onClick={()=>this.openthird()}>Statement of Faith & Beliefs</div>

      //                   <div class="doc_welcome_text"  onClick={()=>this.getstartedHealth()}>Get Started with Health Sharing</div>
      //                   <div class="doc_welcome_text"  onClick={()=>this.open5question()}>5 Questions about Health Sharing</div>
      //                   <div class="doc_welcome_text"  onClick={()=>this.tipsTelemedicin()}>Tips on Telemedicine</div>
      //                   <div class="doc_welcome_text" onClick={()=>this.great4reason()}>4 Great Reasons to share UHS</div>
      //                   <div class="doc_welcome_text" onClick={()=>this.quick3facts()}>3 Quick Facts for Doctors</div>


      //               </div>
      //       </div>

      //       <div class="doc_footer_container">

      //       </div>

      //   </div>

      //   <div>
      //     <MobileFooter name="Documents" />
      //   </div>
      // </div>


    //   <Container fluid>
    //     <Row style={{border:'1px solid red'}}>
    //     <div class="digitalcard_header">
    //       <ArrowBackIcon style={{ width: '24px', height: '24px', color: '#ffffff' }} onClick={() => this.goBack()} />
    //       <div class="digitalcard_headerdiv">Documents</div>
    //       {/* <NotificationsIcon style={{width:'24px',height:'24px',color:'rgba(255, 255, 255, 0.74)'}}/> */}
    //     </div>

    //     <Col>1 of 1</Col>

    //     </Row>
    //   </Container>

      //       <div class="">

      //         <div class="digitalcard_header">
      //           <ArrowBackIcon style={{ width: '24px', height: '24px', color: '#ffffff' }} onClick={() => this.goBack()} />
      //           <div class="digitalcard_headerdiv">Documents</div>
      //           {/* <NotificationsIcon style={{width:'24px',height:'24px',color:'rgba(255, 255, 255, 0.74)'}}/> */}
      //         </div>

      //             {/* <div class="documentscard_container">

      //                 <div class="documentsscreen_card">
      //                     hello
      //                 </div>
      //             </div> */}

      // <Container>
      //   <Row>
      //     <Col>1 of 1</Col>
      //   </Row>
      // </Container>

      //         <div>
      //           <MobileFooter />
      //         </div>
      //       </div>
    )
  }
}
