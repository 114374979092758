import React, { Component } from 'react'
// import '../Card/WebCardCSS/card.css';
import  './Card.css'
import { Container, Row, Col, ProgressBar, Table } from 'react-bootstrap'
export default class FrontView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userchannel: 'AFMC',
      digitalcarddata: this.props.data,
      planinfo: this.props.data.memberIdCardList[0].planInfo,
      plainId: this.props.data.memberIdCardList[0].planId
    }
    console.log('front view data for web', this.props.network)
    console.log("CALL WEB FRONTVIEW",this.state.digitalcarddata.memberIdCardList[0].planId)
  }
  render() {
    console.log('DATA is==', this.state.digitalcarddata.memberIdCardList[0].planId)
    console.log('pan info i is==', this.state.planinfo)
    return (
      <div class="web_fview">
        <div class="web_fv_background_image">
          {/* =============Top Imaghe container========= */}
          <div class="web_fv_top_image_container">
            <img src={require('../../Images/Card/UHS_Logo_RGB_600px_200px.png')} class="web_fv_left_logo img-fluid" />
            {
             this.props.network =='AFMC'
             ? 
             <img src={require('../../Images/Card/AZFMC_logo.png')} class="web_fv_right_logo_afmc  img-fluid" />
              :null
            } 

           {
             this.props.network =='PHCS'
             ? <img src={require('../../Images/Card/phcs_id_practitioner_ancillary_only.jpg')} class="web_fv_right_logo_phcs  img-fluid" />
             :null
            }


           
          </div>

          <div class="web_fv_card ">
            {/* =============ledt container=========  */}
             <div class="web_fv_left_container">
            {
              this.state.digitalcarddata && this.state.digitalcarddata.memberIdCardList.map((data,index)=>
             
                <div>
                  {
                    index==0
                    ? <div>
                    <div class="web_fv_adult_top_name">{data.firstName} {data.lastName}</div>
                    <div class="web_fv_username">
                        {data.memberId}
                    </div>
                  </div>
                    :null
                 
                  }
                </div>
                
              )
            }

          <div class="web_horizontal_scrollbar">

            {
              this.state.digitalcarddata && this.state.digitalcarddata.memberIdCardList.map((data,index)=>
                <div>
                  {
                    index != 0
                    ?
                    <div class="web_fv_list_view">
                    <div class="web_fv_list_adultname">{data.firstName} {data.lastName}</div>
                    <div class="web_fv_list_username">{data.memberId}</div>
                  </div>
                    :null
                  }
                </div>
              )
            }
              </div>


            </div>

            




            {/* <div class="web_fv_left_container">
              <div>
                <div class="web_fv_adult_top_name">Adult 1 First Name Last Name</div>
                <div class="web_fv_username">
                  {this.state.digitalcarddata.memberIdCardList[0].firstName} {this.state.digitalcarddata.memberIdCardList[0].lastName}
                </div>
              </div>

              ================name List==========
              <div class="web_fv_name_container">
                <div class="web_fv_list_view">
                  <div class="web_fv_list_adultname">Adult </div>
                  <div class="web_fv_list_username">XXXXXXXXXX</div>
                </div>

                <div class="web_fv_list_view">
                  <div class="web_fv_list_adultname">Child 1 First </div>
                  <div class="web_fv_list_username">XXXXX</div>
                </div>

                <div class="web_fv_list_view">
                  <div class="web_fv_list_adultname">C Name</div>
                  <div class="web_fv_list_username">XXXXXXXXX</div>
                </div>

                <div class="web_fv_list_view">
                  <div class="web_fv_list_adultname">Child 3 First Name Last Name</div>
                  <div class="web_fv_list_username">XXXXXXXXXX</div>
                </div>

                <div class="web_fv_list_view">
                  <div class="web_fv_list_adultname">Child 4 First Name Last Name</div>
                  <div class="web_fv_list_username">XXXXXXXXXX</div>
                </div>
              </div>
            </div> */}



            {/* =============Right Conrtainer========= */}
            <div class="web_fv_right_container">
              <div class="web_fv_joined_text">Program Details</div>

              <div class="web_fv_right_table1">
                <div class="web_fv_left_table">
                  <div class="web_fv_table_left_text">Member since</div>
                  <div class="web_fv_table_left_text">Group Number</div>
                  <div class="web_fv_table_left_text">Program ID</div>
                </div>
                <div class="web_fv_right_table">
                  <div class="web_fv_table_right_text">{this.state.digitalcarddata.memberIdCardList[0].enrollmentDate}</div>
                  <div class="web_fv_table_right_text">{this.state.digitalcarddata.memberIdCardList[0].groupNo}</div>
                  <div class="web_fv_table_right_text">{this.state.digitalcarddata.memberIdCardList[0].planId}</div>
                </div>
              </div>


           

           
              { this.props.network =='Smartshare' ? (
                 <div class="web_fv_table_middle_text">Sharing for all medical services is limited t0 $27,500/- per Member/per year</div>
              ) : (
                <div class="web_fv_space_2table"></div>
              )}

              {/* ====================Dyanmic content for all user========== */}

              <div>
                {this.state.planinfo &&
                  this.state.planinfo.map(data => (
                    <div class="web_fv_right_new_table">
                      <div class="web_fv_new_table_left_text">{data.idcardField}</div>
                      <div class="web_fv_new_table_right_text">{data.fieldValue}</div>
                    </div>
                  ))}
              </div>
            </div>

           
           
          </div>
 {/* =============Bttom  comntavt container========= */}
          <div class="web_fv_center_details_text fixed-bottom">
              Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs, not insurance. We are IN NETWORK for Multiplan/PHCS Practitioner & Ancillary Network. Providers & Members confirmations and questions {this.props.contactNumber}
          </div>
        </div>

        {/* <div class="fv_bottom" onClick={this.props.printCardView}>Print</div> */}
      </div>
    )
  }
}
