import React,{Component} from 'react';

export default class MobCopyright extends Component{
    render(){
        return(
            <div class="mobile_copyrights">
                Copyright © 2020 Universal Health Fellowship. All rights reserved. | Powered by CarynHealth
            </div>
        )
    }
}