import React,{Component} from 'react';
import DigitalCard from "../Images/Digital Health Card.png";
import Notices from "../Images/Notices.png";
import Provider from "../Images/Find a Provider.png";
import Documents from "../Images/member/Welcome Kit.png";
import Medical from "../Images/Med Q.png";
import Expense from "../Images/Expense Limits.png";
import FAQ from "../Images/FAQs.png";
import { getOs, getDateTime, getPublicIP, getBrowser, getDeviceType } from '../../utils/utility'
export default class MemberApps extends Component{
    constructor(props){
        super(props)

        this.state={
            link:'http://www.mdlive.com/FlexCare',
        }
    }

    componentDidMount(){
        var os = getOs()
        console.log("==========RIGHT DRAWER IS====",os)

        if(os== 'Mac OS'){
        this.setState({link:'http://www.mdlive.com/FlexCare'})
        }
        if(os== 'iOS'){
        this.setState({link:'https://itunes.apple.com/us/app/mdlive/id839671393'})     
        }
        if(os== 'iPadOS'){
            this.setState({link:'https://itunes.apple.com/us/app/mdlive/id839671393'})     
        }
        if(os== 'Windows'){
        this.setState({link:'http://www.mdlive.com/FlexCare'})      
        }
        if(os== 'Android'){
        this.setState({link:'https://play.google.com/store/apps/details?id=com.mdlive.mobile'})      
        }
        if(os== 'Linux'){
        this.setState({link:'http://www.mdlive.com/FlexCare'})     
        }
}

opentelemed(){
    window.open(this.state.link)
}
    render(){
        return(
            <div class="memberapps">

            {/* <div class="memberapps_row_container">
                <div class="memberapps_card_container">
                   <img src={require('../Images/Card/health_services_icon.svg')} class="memberapps_apps_img"/>
                    <div class="memberapps_card_name">Member Services</div>
                </div>
                <div class="memberapps_card_container">
                   <img src={require('../Images/Card/pharma_benefits_icon_active.svg')} class="memberapps_apps_img"/>
                    <div class="memberapps_card_name">Pharma Benefits</div>
                </div>
            </div> */}

                <div class="memberapps_row_container">
                <div class="memberapps_card_container" onClick={()=>this.opentelemed()}>
                   <img src={require('../Images/Card/telemed_icon_active.svg')} class="memberapps_apps_img"/>
                    <div class="memberapps_card_name">Telemed</div>
                </div>

                {/* <div class="memberapps_card_container">
                   <img src={require('../Images/Card/pharma_benefits_icon_active.svg')} class="memberapps_apps_img"/>
                    <div class="memberapps_card_name">Pharma Benefits</div>
                </div> */}

                {/* <div class="memberapps_card_container">
                   <img src={require('../Images/Card/my_vitals_icon.svg')} class="memberapps_apps_img"/>
                    <div class="memberapps_card_name">My Vitals</div>
                </div> */}
                </div>

                {/* <div class="memberapps_row_container">
                <div class="memberapps_card_container">
                   <img src={require('../Images/Card/health_records_icon.svg')} class="memberapps_apps_img"/>
                    <div class="memberapps_card_name">Health Records</div>
                </div>
                <div class="memberapps_card_container">
                   <img src={require('../Images/Card/lab_reports_icon.svg')} class="memberapps_apps_img"/>
                    <div class="memberapps_card_name">Lab Reports</div>
                </div>
                </div> */}

            </div>
        )
    }
}