import React, { Component } from 'react'
import Header from '../Header'
// import './MyNeeds.css'
import './Transaction.css'

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import { gethealthcard, getMyneeds } from '../ApiCall'
import Grid from '@material-ui/core/Grid';
import moment from "moment";
import Button from '@material-ui/core/Button';
// import './transaction.css'
import Sample from './sampleTextField'
import {Modal} from 'react-bootstrap'
import CommonDropDwn from "./CommonDropDwn";
import customStyle from "./stylesheet_UHS";
import CustomeCss from './paymentmode.css.js';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Radio from '@material-ui/core/Radio';
import RadioChecked from '../../Images/radioButtonChecked.png'
import RadioUnchecked from '../../Images/radioButtonUnchecked.png'
import { getSourceCode,getchangePayment,getSourceID,getTransactionData,getAccountNumber,storeTransaction, getNeoTransaction} from '../../ApiCall';
import TransactionDataTable from './TransactionDataTable'
import axios from "axios"
import Fab from '@material-ui/core/Fab';
import CommonLoader from '../../CommonLoader';
const AntTabs = withStyles(
  customStyle.tabs
)(Tabs);

const AntTab = withStyles(theme => (
  customStyle.tab
))(props => <Tab disableRipple {...props} />);

const NextButton = withStyles(
  customStyle.PayButton
)(Button);


export default class Transaction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // rows: myrows
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ],
    disabled:true,
      tableData:[],
      open:false,
      activeTab: 0,
      accountTypes: [],
      bankName: '',
      accountName: localStorage.getItem('subscriberName'),
      accountType: '',
      routingNo: '',
      accountNumber: '',
      cardNumber: '',
      holderName: localStorage.getItem('subscriberName'),
      expiryMonth: '',
      expiryYear: '',
      monthlyDebitDay:'',
      paymentType:'',
      cvv: '',
      selectedValue :'',
      years: [2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031],
      tabFirstIcon: RadioChecked,
      tabSecondIcon: RadioUnchecked,
      sourceCode:localStorage.getItem('sourceid'),
      empid:null,
      disabled2:true,
      errorModal:false,
      MsgModal:'',
      accountNo:null,
      MsgModalerror:'',
      loader:true,
      notfoundmsg:'',
      errCodeCC:false,
      errCodeACH:false,
      MsgModalerrorFooterACH:'',
      MsgModalerrorFooterCC:'',
      reqFlag:true,
    }
  }

  componentDidMount() {
    this.setState({loader:true})
    if(localStorage.getItem('SOURCE') === 'NEO'){
      this.getNeoTransactionData()
    } else {
      this.getTransactionData()
      this.getAccountNumber()
      this.getSourceCodeFun()
    }
  }

  getNeoTransactionData = () =>{
    getNeoTransaction().then(res => {
      if(res.data.totalrecords > 0){
        this.setState({tableData:res.data.pageList,loader:false})
      }else{
        this.setState({notfoundmsg:'My Transactions Data Not Available',loader:false})
      }

    })
  }


  getSourceCodeFun=()=>{
    getSourceCode()
    .then((res) => {

          this.setState({
            sourceCode: res.data.memberIdSource,
            empid: res.data.empId,
            accountTypes:["CHECKING","SAVINGS"]
          });


      }).catch();
  }

  getTransactionData=()=> {
    getTransactionData().then(res => {
        if(res.data.response.length > 0){
          this.setState({tableData:res.data.response,loader:false})
        }else{
          this.setState({notfoundmsg:'My Transactions Data Not Available',loader:false})
        }

    })
  }


  getAccountNumber=()=>{
    // axios.get('http://3.136.92.227:8085/api/v6/transaction/getLast4AccountNumber/')
    getAccountNumber()
    .then(res => {
      // let AccountNo=res.data.response.replaceAll("*", "X");
      if(res.data.code === 200 ){
      this.setState({accountNo:res.data.response.replaceAll("*", "X"),loader:false})
      } else if(res.data.code === 202) {
        let x= JSON.parse(res.data.response).error_message;
        let errMsg = '';
        if(x.includes('-')){
          let cds = x.split(' - ');
          errMsg = cds[1]
        } else {
          errMsg = x;
        }
        if(this.state.activeTab === 0){
          this.setState({
            accountNo:'',
            MsgModalerrorFooterACH : errMsg
          })
        } else {
          this.setState({
            accountNo:'',
            MsgModalerrorFooterCC : errMsg
          })
        }
      }
      else{
        this.setState({accountNo:''})
      }
    })
  }



handleClose = () => {
  this.setState({open:false,
    bankName:'',
    accountName:this.state.accountName,
    accountNumber:'',
    accountType:'',
    routingNo:'',

    cardNumber:'',
    holderName:this.state.holderName,
    expiryMonth:'',
    expiryYear:'',
    cvv:'',
    paymentType:'',
    MsgModalerror:'',
    errCodeACH:false,
    errCodeCC:false,
    MsgModalerrorFooterACH:'',
    MsgModalerrorFooterCC:''
  },()=>this.validateForm())
};

handleClickOpen = () => {
  this.setState({open:true})
};


changeTextFieldHandler = (val, isValid, parentObj) => {
  if(parentObj.label === 'bank_name'){
      if(isValid){
          this.state.bankName = val;
      } else {
          this.state.bankName = '';
      }
  }  else if(parentObj.label === 'Account_Name'){
      if(isValid){
          this.state.accountName = val;
      } else {
          this.state.accountName = '';
      }
  } else if(parentObj.label === 'Routing_Number'){
      if(isValid){
          this.state.routingNo = val;
          this.state.errCodeACH=false
      } else {this.state.routingNo = '';
      }
  } else if(parentObj.label === 'Account_Number'){
      if(isValid){
          this.state.accountNumber = val;
      } else {
          this.state.accountNumber = '';
      }
  } else if(parentObj.label === 'Account_Type'){
      if(isValid){
          this.state.accountType = val;
      } else {
          this.state.accountType = '';
      }
  } else if(parentObj.label === 'Card_Number'){
      if(isValid){
        // if(this.state.MsgModalerror!=''){
        //  let evt = new CustomEvent('errorCode',{detail:{flag:true}});
        //   window.dispatchEvent(evt);
        // }
          this.state.cardNumber = val;
          this.state.MsgModalerror=''
          this.state.errCodeCC=''

      } else {
          this.state.cardNumber = '';
      }
  } else if(parentObj.label === 'Holder_Name'){
      if(isValid){
          this.state.holderName = val;
      } else {
          this.state.holderName = '';
      }
  } else if(parentObj.label === 'expiryMonth'){
      if(isValid){
          let currentDate = new Date ();
          let month = currentDate.getMonth()+1;
          let year = currentDate.getFullYear();
          let selectedMon = moment().month(val).format("M");
          if(this.state.expiryYear){
              if(this.state.expiryYear === year && parseInt(selectedMon) < month){
                  this.state.validMonth = true;
                  let evt = new CustomEvent('month',{detail:{flag:true}});
                  window.dispatchEvent(evt);
              }else{
                  this.state.validMonth = false;
                  let evt = new CustomEvent('month',{detail:{flag:false}});
                  window.dispatchEvent(evt);
              }
          }
          this.state.expiryMonth = val;
      } else {
          this.state.expiryMonth = '';
      }
  } else if(parentObj.label === 'expiryYear'){
      if(isValid){
          let date = new Date ();
          let month = date.getMonth()+1;
          let year = date.getFullYear();
          let selectedMon = moment().month(this.state.expiryMonth).format("M");
          if(val === year && parseInt(selectedMon) < month){
              this.state.validMonth = true;
              let evt = new CustomEvent('month',{detail:{flag:true}});
              window.dispatchEvent(evt);
          }else{
              this.state.validMonth = false;
              let evt = new CustomEvent('month',{detail:{flag:false}});
              window.dispatchEvent(evt);
          }
          this.state.expiryYear = val;
      } else {
          this.state.expiryYear = '';
      }
  } else if(parentObj.label === 'cvv'){
      if(isValid){
          this.state.cvv = val;
      } else {
          this.state.cvv = '';
      }
  }else if(parentObj.label === 'monthlyDebitDay'){
      if(isValid){
          this.state.monthlyDebitDay = val;
      } else {
          this.state.monthlyDebitDay = '';
      }
  }
   else if(parentObj.label === 'Street'){
      if(isValid){
          this.state.userAddress.street = val;
      } else {
          this.state.userAddress.street = '';
      }
  }
  this.setState({
      refresh : true
  }, () => this.validateForm());
};


validateForm() {
if (this.state.activeTab === 0) {
    if (this.state.bankName !== ''  && this.state.accountName !== '' && this.state.accountNumber !== '' && this.state.accountType !== ''  && this.state.routingNo !== ''  ) {
        this.setState({
            disabled: false
        },()=>console.log("====Button==="+this.state.disabled));
    } else {
        this.setState({
            disabled: true
        },()=>console.log("====Button==="+this.state.disabled));
    }
} else if (this.state.activeTab === 1) {
    if (this.state.cardNumber !== '' && this.state.holderName !== '' && this.state.expiryMonth !== '' && this.state.expiryYear !== '' && this.state.cvv !== '' && !this.state.validMonth ) {
        this.setState({
          disabled2: false
        });
    } else {
        this.setState({
          disabled2: true
        });
    }
}

}
handleChange = (event, newValue) => {
console.log("====================New Value"+newValue)
this.setState({
  loaderShow: true,
  refresh : true
});
let flag;

if(newValue === 0){
  this.setState({activeTab:0,errCodeACH:this.state.errCodeACH})
  this.setState({tabFirstIcon:RadioChecked})
  this.setState({tabSecondIcon:RadioUnchecked})
} else {
  this.setState({activeTab:1})
  this.setState({tabFirstIcon:RadioUnchecked})
  this.setState({tabSecondIcon:RadioChecked})
}
}


handleChangeRadio=(event)=>{
this.setState({selectedValue:event.target.value})
}






changePaymentMode=()=>{
this.setState({loader:true,MsgModalerror:''})

  let obj
  var payType=''
  if(this.state.activeTab==0)
  {
    payType="ACH"
  }else{
    payType="CC"
  }

   obj={
    "source":this.state.sourceCode,
    "bankName":this.state.bankName,
    "accountName":this.state.accountName,
    "accountNumber":this.state.accountNumber,
    "accountType":this.state.accountType,
    "routingNo":this.state.routingNo,

    "cardNumber":this.state.cardNumber,
    "holderName":this.state.holderName,
    "expiryMonth":this.state.expiryMonth ? moment().month(this.state.expiryMonth).format("M") : "",
    "expiryYear":this.state.expiryYear,
    "cvv":this.state.cvv,
    "paymentType":payType
  }


//axios.post('http://3.136.92.227:8085/api/v6/transaction/storeTransaction' ,obj)
storeTransaction(obj)
    .then(res => {


       if(res.data.code==200){


          if(this.state.activeTab==0){
          this.setState({
            open:false,
            loader:true

            // refresh:true
          });
        }
        if(this.state.activeTab==0){
          this.setState({

            open:false,
            loader:true

          //  refresh:true
          });
        }
        this.setState({errorModal:true,open:false,loader:false,MsgModal:'Updated payment details successfully!'})

      } else if(res.data.code==202){
         let x= JSON.parse(res.data.response).error_message;
         let errMsg = '';
         if(x.includes('-')){
           let cds = x.split(' - ');
           errMsg = cds[1]
         } else {
           errMsg = x;
         }
        this.setState({MsgModalerror:errMsg,loader:false,errCodeACH:true})

        if(this.state.activeTab==0 && this.state.MsgModalerror!=''){

          this.setState({
            errCodeACH:true,
            cvv:'',
            cardNumber:'',
            errCodeCC:false,
            expiryMonth:'',
            expiryYear:'',
            reqFlag:false,

          })

          let evt = new CustomEvent('errorCode',{detail:{flag:true}});
          window.dispatchEvent(evt);
        }

        if(this.state.activeTab==1){

          this.setState({
            errCodeCC:true,
            bankName:'',
            accountNumber:'',
            accountType:'',
            routingNo:'',
            errCodeACH:false,
          })

          let evt = new CustomEvent('errorCode',{detail:{flag:true}});
          window.dispatchEvent(evt);
        }

      }
      else if(res.data.code==204){

        if(this.state.activeTab==0){
        this.setState({
          MsgModalerrorFooterACH:'Source is not registered',
        loader:false,
        MsgModalerrorFooterCC:'',
        cvv:'',
            cardNumber:'',
            errCodeCC:false,
            expiryMonth:'',
            expiryYear:'',
      })
        }else{
          this.setState({
            MsgModalerrorFooterCC:'Source is not registered',
            loader:false,
            MsgModalerrorFooterACH:'',
            bankName:'',
            accountNumber:'',
            accountType:'',
            routingNo:'',
          })
        }

      }
      else if(res.data.code==500){

        if(this.state.activeTab==0){
          this.setState({
            MsgModalerrorFooterACH:'Internal server error',
            loader:false,MsgModalerrorFooterCC:'',
            cvv:'',
            cardNumber:'',
            errCodeCC:false,
            expiryMonth:'',
            expiryYear:'',
          })
          }else{
            this.setState({MsgModalerrorFooterCC:'Internal server error',
            loader:false,MsgModalerrorFooterACH:'',
            bankName:'',
            accountNumber:'',
            accountType:'',
            routingNo:'',

          })
          }
      }

       else{
        let x= JSON.parse(res.data.response).error_message;
        let cds = x.split(' - ');
        if(this.state.activeTab==0){
        this.setState({MsgModalerrorFooterACH:cds[1],loader:false,
          cvv:'',
          cardNumber:'',
          errCodeCC:false,
          expiryMonth:'',
          expiryYear:'',
        })
        }else{
          this.setState({MsgModalerrorFooterCC:cds[1],loader:false,
            bankName:'',
            accountNumber:'',
            accountType:'',
            routingNo:'',
          })
        }
       }
      })
    }

handleCloseErrorModal=()=>{
  this.getAccountNumber()
  this.setState({errorModal:false,

    bankName:'',
    accountName:this.state.accountName,
    accountNumber:'',
    accountType:'',
    routingNo:'',

    cardNumber:'',
    holderName:this.state.holderName,
    expiryMonth:'',
    expiryYear:'',
    cvv:'',
    paymentType:'',
    MsgModalerror:'',
    errCodeACH:false,
    errCodeCC:false
  })
}

goBack = () => {
  this.props.history.push('/')
}
  render() {
    const { classes } = this.props

    // console.log("=======table data is======",this.state.tableData)


    let currentScreen = '';
    if (this.state.activeTab === 0) {
        currentScreen = <div >
        <Grid container spacing={1} style={{marginTop:'1%'}}>
    <Grid item xs={12} sm={12}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} style={{marginBottom:'-1%'}}>
          <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Bank_Name'} reqFlag={true} label={'Bank Name'} value={this.state.bankName} disable={false} style={CustomeCss.textField} length={120}  fieldType={'bank_name'} errMsg={'Enter valid bank name'} helperMsg={'Bank name required'}  parentDetails={{label : 'bank_name'}} key={0}></Sample>
        </Grid>
        <Grid item xs={12} sm={4} style={{marginBottom:'-1%'}}>
        <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Account_Name'} reqFlag={true} label={'Name on Account'} value={this.state.accountName} disable={false} style={CustomeCss.textField} length={120}  fieldType={'accountName'} errMsg={'Enter valid account name'} helperMsg={'Name on account required'}  parentDetails={{label : 'Account_Name'}} key={0}></Sample>
        </Grid>
        <Grid item xs={12} sm={4} style={{marginBottom:'-1%'}}>
        <CommonDropDwn setChild={this.changeTextFieldHandler.bind(this)} name={'AccountType'} label={'Account Type'} value={this.state.accountType} disable={false} style={customStyle.dropDown}  fieldType={'dropDwn'}  helperMsg={'Select account type'} List={this.state.accountTypes}  parentDetails={{label:'Account_Type'}}></CommonDropDwn>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{marginTop:'1.5%'}}>
        <Grid item xs={12} sm={6} style={{marginBottom:'-2%'}} >
        {
         this.state.MsgModalerror!=''?
         <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Routing_Number'} MsgModalerror={this.state.MsgModalerror} ErrCode={this.state.errCodeACH} reqFlag={true} label={'Routing Number'} value={this.state.routingNo} disable={false} style={CustomeCss.textField} length={9}  fieldType={'routingNo'} errMsg={'Enter valid routing number'} helperMsg={this.state.MsgModalerror}  parentDetails={{label : 'Routing_Number'}} key={0}></Sample>

        :
        <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Routing_Number'} MsgModalerror={this.state.MsgModalerror} ErrCode={this.state.errCodeACH} reqFlag={true} label={'Routing Number'} value={this.state.routingNo} disable={false} style={CustomeCss.textField} length={9}  fieldType={'routingNo'} errMsg={'Enter valid routing number'} helperMsg={'Routing number required'}  parentDetails={{label : 'Routing_Number'}} key={0}></Sample>

        }    </Grid>
        <Grid item xs={12} sm={6}>
        <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Account_Number'} reqFlag={true} label={'Account Number'} value={this.state.accountNumber} disable={false} style={CustomeCss.textField} length={26} fieldType={'accountNumber'} errMsg={'Account number must be 4 and up to 26 digits'} helperMsg={'Account number required'}  parentDetails={{label : 'Account_Number'}} key={0}></Sample>
        </Grid>
      </Grid>
    </Grid>


  </Grid>



        </div>
    } else if (this.state.activeTab === 1) {
        currentScreen = <div >

            <Grid container spacing={1} style={{marginTop:'1%'}}>
    <Grid item xs={12} sm={12}>
    <Grid container spacing={3} >
        <Grid item xs={12} sm={6} style={{marginBottom:'-3%'}}>
       {
         this.state.MsgModalerror!=''?
      //  <span>{this.state.MsgModalerror}</span>
      <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Card_Number'} MsgModalerror={this.state.MsgModalerror} ErrCode={this.state.errCodeCC} reqFlag={this.state.reqFlag} label={'Card Number'} value={this.state.cardNumber} disable={false} style={CustomeCss.textField} length={16} fieldType={'cardNumber'} errMsg={'Card number up to 16 digits'} helperMsg={this.state.MsgModalerror}  parentDetails={{label:'Card_Number'}}></Sample>

       :
       <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Card_Number'} MsgModalerror={this.state.MsgModalerror} ErrCode={this.state.errCodeCC} reqFlag={this.state.reqFlag} label={'Card Number'} value={this.state.cardNumber} disable={false} style={CustomeCss.textField} length={16} fieldType={'cardNumber'} errMsg={'Card number up to 16 digits'} helperMsg={'Card number required'}  parentDetails={{label:'Card_Number'}}></Sample>

       }
       </Grid>
        <Grid item xs={12} sm={6} >
        <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Holder_Name'} reqFlag={true} label={'Card Holder Name'} value={this.state.holderName} disable={false} style={CustomeCss.textField} length={25}  fieldType={'holderName'} errMsg={'Enter valid card holder name'} helperMsg={'Card holder name required'}  parentDetails={{label:'Holder_Name'}}></Sample>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{marginTop:'2%'}}>
        <Grid item xs={12} sm={4}>
        <CommonDropDwn setChild={this.changeTextFieldHandler.bind(this)} name={'expiryMonth'}  label={'Expiration Month'} value={this.state.expiryMonth} disable={false} style={customStyle.dropDown}  fieldType={'dropDwn'}  helperMsg={'Select expiration month'} errMsg={"The expiration date is before today's date. Enter valid expiration month"} List={this.state.months}  parentDetails={{label:'expiryMonth', val : this.state.expiryYear}} key={1}></CommonDropDwn>
        </Grid>
        <Grid item xs={12} sm={4}>
        <CommonDropDwn setChild={this.changeTextFieldHandler.bind(this)} name={'expiryYear'} label={'Expiration Year'} value={this.state.expiryYear} disable={false} style={customStyle.dropDown}  fieldType={'dropDwn'}  helperMsg={'Select expiration year'} errMsg={"The expiration date is before today's date. Enter valid expiration year"} List={this.state.years}  parentDetails={{label:'expiryYear', val : this.state.expiryMonth}} key={1}></CommonDropDwn>
        </Grid>
        <Grid item xs={12} sm={4}>
        <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'cvv'} label={'CVV'} reqFlag={true} value={this.state.cvv} disable={false} style={CustomeCss.textField} length={4}  fieldType={'cvv'} errMsg={'Enter valid CVV'} helperMsg={'CVV required'}  parentDetails={{label : 'cvv'}} key={1}></Sample>
        </Grid>
      </Grid>

    </Grid>


  </Grid>


        </div>
    }
    return (
      <div className="progInfoMainWrapper">
         {this.state.loader?<CommonLoader/>:null}
        <Header name={'MyTransactions'} />
        <div className="container progInfoContainer" style={{zIndex:'0'}} >
        <sapn className="Back_page" onClick={this.goBack}><ArrowBackIcon style={{ width: '24px', height: '24px', color: ' #543379',marginRight:'5px'}} onClick={this.goBack} />BACK</sapn>
          <div className="commonWrap" style={{marginTop: '-27px'}}>

            <div className="progInfoSection" style={{marginTop: '-15px'}}>
              <span className="progInfoTitle">MY TRANSACTIONS</span>
            </div>



            <div className="tablebackgroundouter">

              {
                this.state.tableData &&  this.state.tableData.length > 0
                ? <TransactionDataTable tableData={this.state.tableData} />
              : <div className="data_not_found">
                 <h5 class="noneeds_msg_display">{this.state.notfoundmsg}</h5>
                </div>
              }

            </div>

            <div className="progInfoFooter">
              <div className="row">
                <div className="col-md-6" >
                 <button type="button" onClick={this.handleClickOpen} className="blueActionBtn border-0 mr-2 text-uppercase" style={{ marginTop: '10px' }}>
                   Change Payment Method
                 </button>
                </div>
                <div className="col-md-6 dpFlex">
                  {/* <div className="elevation"></div> */}
                  <div className="footerText" style={{display:'flex', flexDirection:'row'}}>
                    <div>
                      <p>Need help?</p>
                      <p>Chat with our Health Share Representative</p>
                      <p>or call 1 (888) 366 6243.</p>
                    </div>
                    <div style={{marginLeft:12}}>
                      <Fab color="#41B5C2" style={{backgroundColor:"#533278"}} aria-label="add" onClick={()=>this.openChat()}>
                          <img src={require('../../../assets/images/carynIcon.jpg')} />
                      </Fab>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



 {/* =================================================== */}


 <Modal size="lg" show={this.state.open} onHide={this.handleClose} centered className="ChangPayModal" backdrop='static'>



                {
                  localStorage.getItem('SOURCE') === 'NEO' ?
                    <Modal.Body>
                      {/*<Grid container direction="row" justify="space-between" alignItems="flex-start">
                        <span className="Change-Payment-Method">Change Payment Method</span>
                        <IconButton aria-label="close" onClick={this.handleClose} style={{ marginTop: '-13px' }}>
                          <CloseIcon/>
                        </IconButton>

                      </Grid>*/}
                      <Grid container direction="row" justify="space-between" alignItems="flex-start">
                        <span style={{textAlign:'center', fontSize: '17px', fontWeight: 'bold'}}>To change your payment method, call Member Services team at : {localStorage.getItem('CONTACT_NUMBER')} , Monday through Friday 8:00 am to 8:00 pm CST.</span>
                      </Grid>
                    </Modal.Body>
                    : <Modal.Body>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                      >
                        <span class="Change-Payment-Method">Change Payment Method</span>
                        <IconButton aria-label="close"  onClick={this.handleClose} style={{marginTop:'-13px'}}>
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                      <Grid container spacing={2} >
                        <Grid item xs={12} sm={12} >
                          <p class="The-fellowship-incurs-additional-fees-related-to-p">The fellowship incurs additional fees related to processing credit cards that we do not incur when processing
                            ACH payments. We therefore encourage our members to pay by ACH, yet offer credit card payments as an option as well. If you choose to pay by credit card, you will incur an additional 3.5% fee to cover the fellowship’s cost.
                          </p>

                        </Grid>
                      </Grid>
                      <Grid container spacing={2} >
                        <Grid item xs={12} sm={12} >
                          <AntTabs
                            value={this.state.activeTab}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                          >

                            <AntTab label="ACH DEBIT" className='ant-col-15 tabBtn tabFirst'  icon={ <img className="raidoBtn" src={this.state.tabFirstIcon} />} />
                            <AntTab label="CREDIT CARD" className='ant-col-15 tabBtn tabSecond'  icon={ <img className="raidoBtn" src={this.state.tabSecondIcon} />} />

                          </AntTabs>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justify="flex-end" alignItems="flex-end">
                        <Grid item xs={12} sm={3} md={4} >
                          <table className="Last_4_digit" >
                            <td className="td_style1">Account Number currently on file</td>
                            <td className="td_style" style={{verticalAlign:'middle'}}>
                              {/* XXXXXX4567 */}{this.state.accountNo}
                            </td>
                          </table>
                        </Grid>

                      </Grid>
                      {currentScreen}
                    </Modal.Body>
                }

                <Modal.Footer>

                  {
                    localStorage.getItem('SOURCE') === 'NEO' ?
                      <Button variant="secondary" onClick={this.handleClose} style={{marginRight:'15px'}} class="ButtonBG">
                        OK
                      </Button>
                      :
                      <>
                        { this.state.activeTab === 0 ?
                          <span className="Footer-error-msg" style={{width:'500px'}}>{this.state.MsgModalerrorFooterACH}</span>
                          :
                          <span className="Footer-error-msg" style={{width:'500px'}}>{this.state.MsgModalerrorFooterCC}</span>
                        }
                        <Button variant="secondary" onClick={this.handleClose} style={{marginRight:'15px'}} class="ButtonBG">
                          CANCEL
                        </Button>
                        <NextButton variant="contained" disabled={this.state.activeTab == 0 ? this.state.disabled : this.state.disabled2} color="primary" onClick={this.changePaymentMode} >
                          DONE
                        </NextButton>
                      </>

                  }





                </Modal.Footer>
              </Modal>


{/* ===============================Error Modal====================================== */}

<Modal size="small" show={this.state.errorModal} onHide={this.handleCloseErrorModal} centered  backdrop='static'>


    <Modal.Header>
      <Modal.Title>Message</Modal.Title>
    </Modal.Header>

      <Modal.Body>
        {/* {this.state.loader?<CommonLoader/>:null} */}
     <b> {this.state.MsgModal}</b>
      </Modal.Body>

      <Modal.Footer>
<Button variant="secondary" onClick={this.handleCloseErrorModal} class="ButtonBG">
                    OK
                  </Button>
    </Modal.Footer>

      </Modal>




      </div>


    )
  }
  openChat=()=>{
    sessionStorage.setItem('chatwindow',true);
    this.props.history.push('/');
  }
}

// export default withStyles(useStyles)(MyNeeds)

