import React from 'react'
import { gethealthcard, getNotificationDetails,healthtools, updateNotificationStatus, saveUserNotification, getproviderLink,
     getExpensLimit, getProgramInfo, getHealthqnInfo, 
     getCardDetails, getCardEnableData } from '../../ApiCall'


export const NavigateURL = (id, url,type) => {
    let client_id = localStorage.getItem('CLIENT_ID')
    let obj = { "notificationId": id }
    let cardName = ''
	if(type == undefined){
		updateNotificationStatus(obj)
      .then(res => {
        console.log("saveUserNotificationDetails=====", res)

      })
		
	}
    if(url == 'notices' || url == 'Notices' || url == 'NOTICES'){
        cardName='notices'
    }
    
    if (url == 'Medical') {
      cardName = 'HealthQuestionnaire'
    }
    if (url == 'Needs' || url == 'MyNeeds') {
      cardName = 'MyNeeds'
    }
    if (url == 'Transaction' || url == 'MobileTransaction') {
      cardName = 'MyTransaction'
    }
    if (url == 'ProgramInformation') {
      cardName = 'ProgramInformation'
    }
    if (url == 'MemberIdcard' || url == 'DigitalHealthCardNew') {
      cardName = 'MembershipId'

    }
    if (url == 'HealthToolCard' || url == 'HealthToolsCard') {
      cardName = 'HealthTool'
      // this.showHealthTool()
    }
    if (url == "DocumentsScreen") {
      cardName = 'Documents'
    }
    if (url == 'HealthyLife') {
      cardName = 'HealthyLife'
    }
    if (url == 'PaymentWallet') {
      cardName = 'PaymentWallet'
    }
    console.log("saveUserNotificationDetails=====Notice")

    getCardEnableData(client_id, cardName)
      .then(res => {
        if (cardName == 'HealthTool') {
          if ((res.data.response.enable == 'true' || res.data.response.enable == true)) {
            localStorage.setItem("healthTool",true)

            window.location.href ="/"
          
            // window.location.href ="/?healthTool=true"
        }else{
       
         
          localStorage.setItem("openModal",true)

          window.location.href ="/"
         
          // window.location.href ="/?openModal=true"

        }
      }
        if (res.data.response.enable == 'false' || res.data.response.enable == false) {
          // localStorage.setItem("tempModal",true)
          // return this.props.openTempModal=true

          localStorage.setItem("openModal",true)

          window.location.href ="/"

          //  window.location.href ="/?openModal=true"

          // this.getNotification()
		if(type == undefined){
			updateNotificationStatus(obj)
            .then(res => {
              console.log("saveUserNotificationDetails=====", res)
            })
			
		}
			
          
        } else {
          if (cardName == 'HealthQuestionnaire') {
            window.location.href = '/Medical'

          }
          if (cardName == 'MyNeeds') {
            window.location.href = '/MyNeeds'

          }

          if (cardName == 'ProgramInformation') {
            window.location.href = '/ProgramInformation'

          }
          if (cardName == 'MembershipId' || cardName == 'HealthyLife') {

            // this.props.leftdrawerclose()
            // this.getNotification()
            localStorage.setItem("memberId",true)

            window.location.href ="/"

          }
          
          if (cardName == 'Documents') {

            // this.documentscardopen()
            // this.getNotification()
            // localStorage.setItem("DEEPLINK",{"document":'true'})
            localStorage.setItem("document",true)

            window.location.href ="/"
            // window.location.href ="/?document=true"

          }
          if (cardName == 'MyTransaction') {
            window.location.href = '/Transaction'
          }
          if (cardName == 'PaymentWallet') {
            window.location.href = '/PaymentWallet'
          }
          if (cardName == 'notices') {
            window.open('https://www.universalhealthfellowship.org/notices/')

          }
        }
      })

  }