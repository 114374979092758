import React,{Component} from 'react'
import IDBackView from './IDBackView';
export default class HealthCardBackView extends Component{
    constructor(props){
        super(props)

        this.changeside = this.changeside.bind(this);
    }

    changeside(){
     this.props.method(true)
    }
    render(){
        return(
            <div>
                <IDBackView {...this.props} changeside={this.changeside} network={this.props.network}
                 contactNumber={this.props.contactNumber} cardId={this.props.cardId}
                />
            </div>
        
    )
}
}