import React, { Component } from 'react'
import Fab from '@material-ui/core/Fab';
import { withRouter } from 'react-router';
 class ChatIcon extends Component {
    constructor(props) {
        super(props)
        this.state = {
         
        }
    }
    openChat=()=>{
        sessionStorage.setItem('chatwindow', true);
        this.props.history.push('/');
        this.props.openChat();
    }
    
    render(){
        return(
            <div className="fixed-bottom bottonChatIcon" style={{ zIndex:1000, position:'fixed', paddingBottom:this.props.shiftup?'18vh':'10vh', }}>
            <Fab color="#41B5C2" style={{backgroundColor:"#543379"}} aria-label="add" onClick={()=>this.openChat()}>
                <img src={require('../../../assets/images/carynIcon.jpg')} />
            </Fab>
          </div>
        )}
    }
    export default withRouter(ChatIcon)