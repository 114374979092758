import React, { Component } from 'react';
import {Form} from "react-bootstrap";
import SendIcon from '@material-ui/icons/Send';
import Draggable from 'react-draggable';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import './ChatBox.css'
import { Link } from 'react-router-dom'

export default class ChatBox extends Component {
    constructor(props) {
        super(props)
        this.state = {
          chat:[],
          message:'',
          chats:[],
          fullScreen:false,
        }
        this.mesRef = React.createRef();
    }
    componentDidMount(){
        const messages =[
            {
             message:"Good Morning",
             from:'incoming'
            },
            {
                message:"Hi",
                from:'outgoing'
            },
            {
                message:"How can I help You?",
                from:'incoming'
            },
            {
                message:"Know my Payment Information",
                from:'outgoing'
            },
            {
                message:"Want to you Payment Information",
                from:'incoming',
                intent:'ProgramInformation',
            }
        ];
        this.setState({chats: messages},
            () => {
                this.scrollToBottom();
            });
    
    }
    
    render() {
        return (
            <Draggable handle="#draggable-dialog-title">
            <div class="border" style={{height: this.state.fullScreen ? 500 :200, width: 315, backgroundColor: '#D6CDCB',margin: 10,cursor: 'pointer', position: 'relative', zIndex:3}}>
                <div class="myneeds_top_container" style={{backgroundColor:'orange', cursor: 'move'}} id="draggable-dialog-title">
                    <div class="myneeds_header_text" style={{color:'#fff', fontSize:15, fontFamily:'Anton'}}>YOUR AI-POWERED ASSISTANT</div>
                    <div>{this.state.fullScreen ?<FullscreenExitIcon onClick={()=>this.fullScreen(false)} style={{color:'white', cursor:'pointer'}}/>:<FullscreenIcon onClick={()=>this.fullScreen(true)} style={{color:'white', cursor:'pointer'}}/> }</div>
                </div>
                <div id="chatbody" class="chatbody"
                    ref={this.mesRef}
                    style={{height:this.state.fullScreen? 400 :90, marginBottom:50, paddingTop:10, paddingLeft:10}}>
                    {/* <div class="incoming">
                        <div class="bubble">
                            <p>Hey,</p>
                        </div>
                    </div> 
                    <div class="outgoing">
                        <div class="bubble lower">
                            <p>Nah, it's cool </p>
                        </div>
                    </div> */}
                   {
                         this.state.chats.map((message)=>( 
                                <div class={message.from} style={{cursor:'context-menu'}}>
                                    <div class="bubble">
                                        <p>{message.message}</p>
                                    </div>
                                        {message.intent &&
                                         <Link to={"/"+ message.intent}>
                                            <div class="bubble" style={{backgroundColor:'#54337A', color:'#fff', cursor:'pointer'}}>
                                                View Details <ChevronRightIcon style={{ fontSize: 26 }}/>
                                            </div>
                                        </Link>}
                                </div>))
                   }
                 
                            <div class="spinner">
                                <div class="bounce1"></div>
                                <div class="bounce2"></div>
                                <div class="bounce3"></div>
                            </div>
                   
                </div>
            
                    {/* <div class="typing" style={{bottom:50, position:'absolute', paddingLeft:10}}>
                        <div class="bubble">
                            <div class="ellipsis dot_1"></div>
                            <div class="ellipsis dot_2"></div>
                            <div class="ellipsis dot_3"></div>
                        </div>
                    </div> */}

               <form onSubmit={this.sendMessage}> <div style={{flexDirection:'row',display:'flex' , bottom:10, position:'absolute',}}>
                    <div style={{width:250, paddingLeft:15}}> <Form.Control placeholder="Type to chat with Bomba" value={this.state.message} onChange={e=>this.setState({message: e.target.value})}/> </div> 
                    <div style={{paddingTop:4, paddingLeft:10}}><SendIcon type="submit" value="Submit" style={{color: this.state.message? 'blue': null}} onClick={()=>this.sendMessage(true)}/></div>
                </div>  
                </form>
            </div>
            </Draggable>
        )
    }
    sendMessage=(e)=>{        
        if(e!==true)
        e.preventDefault();
        if(this.state.message !== ''){
    
                var message={
                    message:this.state.message,
                    from:'outgoing'
                }
                this.setState({chats: this.state.chats.concat(message), message:''},
                    () => {
                        this.scrollToBottom();
                    })
        }
    }
    fullScreen=(parameters)=>{
        this.setState({fullScreen:parameters}, () => {
            this.scrollToBottom();
        })
    }
    scrollToBottom = () => {
        this.mesRef.current.scrollTop = this.mesRef.current.scrollHeight;
    }
      
}

