import React from 'react'
import { Auth } from 'aws-amplify'
import '../authentication/Login.css'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Link } from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu'
// import './style.css'
import { useState, useEffect } from 'react'
import InputPassword from './InputPasswordBox'
import { BrowserView, MobileView, isBrowser, isMobile, isTablet, deviceType } from 'react-device-detect'
import PasswordValidations from './PwdValidations';
import { getQueryParams } from '../authentication/utils';
import WebFooter from '../../source/WebScreen/WebFooter';
function ResetPasswordForm(props) {
  const [verificationCode, setVerification] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [disableSendBtn, setDisableSendBtn] = useState(true)


  const handleConfirmVerification = e => {
    // alert("handle call")
    e.preventDefault()
    console.log('calling reset')
    console.log(props.email)
    console.log(verificationCode)
    console.log(password)
    if (password == confirmPassword) {
      props.toggleLoader(true)
      Auth.completeNewPassword(
        props.user, // the Cognito User Object
        password // the new password
      )
        .then(user => {
          // at this time the user is logged in if no MFA required
          console.log(user)
          props.toggleLoader(false)
          let queryParams = getQueryParams()
          console.log(queryParams)
          if (queryParams.u) {
            let userName = decodeURI(queryParams.u);
            let url = window.location.href.split('?')[0] + '?u=' + userName + '&p=' + password;
            window.location.replace(url);
          } else {
            window.location.reload();
          }
        })
        .catch(err => {
          props.toggleLoader(false)
          console.log(err.message)
          setErrorMessage(err.message)
        })
    } else {
      setErrorMessage('Please enter same password')
      // setbuttonStatus(true)
    }
  }

  const handleChange = e => {
    // console.log(e.target.value)
    if (e.target.name == 'password') {
      setPassword(e.target.value)
    } else if (e.target.name == 'confirmPassword') {
      setConfirmPassword(e.target.value)
    }
    // if(password==confirmPassword){
    //   setbuttonStatus(false)
    // }
    // else{
    //   console.log(password)
    //   console.log(confirmPassword)
    // }
  }
  const toggleResetBtn = allPassed => {
    setDisableSendBtn(!allPassed)
  }

  return (
    <div>
      { deviceType == 'browser' && (isMobile == false || isMobile == 'false') && (isTablet == false || isTablet == 'false') ? (
        <form className="main-form">
          <div class="rege">
            <div class="rege_container">
              <div class="login_image_container">
                <img src={require('../../source/Images/Banner/welcome_image_desktop.png')} class="reset_login_main_image" />
                <img src={require('../../source/Images/Banner/uhs-logo.svg')} class="reset_login_uhs_logo" />
              </div>

              <div class="firsttime_changepassword_text">Change Password</div>
              <div class="firsttime_changepassword_subtext">Please enter your new password below.</div>

              {/* <p class="reset_errormsg" hidden={errorMessage.length <= 0} style={{  }}>
               {errorMessage}
               </p> */}

              <p className="reset_errormsg" hidden={errorMessage.length <= 0} style={{}}>
                {errorMessage}
              </p>

              <div class="reset_container">

                <div className="a-form-ctrl_web firsttime_margin_class">
                  <p className="">New Password</p>
                  <InputPassword handleChange={handleChange} name={'password'} />
                </div>

                <div className="a-form-ctrl_web firsttime_margin_class">
                  <p className="">Enter New Password Again</p>
                  <InputPassword handleChange={handleChange} name={'confirmPassword'} />
                </div>
              </div>
              <button type="submit" className="firsettime_send_button" onClick={handleConfirmVerification}>
                Send
              </button>

              <div class="mb-3">
                <PasswordValidations password={password} allPassed={toggleResetBtn} />
              </div>
              <WebFooter />
            </div>
          </div>

        </form>
      ): (
        <div class="loginmobile">
          <div class="loginmobile_header">
            {/* <MenuIcon style={{ color: '#ffffff', marginRight: '4vw',width:'8vw' }} /> */}
            <div class="loginmobile_header_text">Member Portal</div>
          </div>

          <div class="loginmobile_container">
            <form >
              <div class="loginmobile_image_container">
                <img src={require('../../source/Images/Banner/uhs-logo.svg')} class="loginmobile_logo" />
                <img src={require('../../source/Images/Banner/welcome_image_mobile.png')} class="loginmobile_banner" />
              </div>



              <div class="firsttimemobile_bottom_text">
                <div class="firsttimemobile_bottom_subtext">Change Password</div>
                <div class="firsttimemobile_bottom_subtext2">
                  <div>
                    Please enter your new passsword below.
                  </div>
                </div>
              </div>


              <div class="loginmobile_input_container">

                {/* <p class="mobile_login_new_error_text" hidden={this.props.errorMsg.length <= 0}>{this.props.errorMsg}</p> */}
                <p className="a-errorMessage" hidden={errorMessage.length <= 0} style={{ marginTop: '10px' }}>
                  {errorMessage}
                </p>



                <div class="mobile-linput">
                </div>
                <div style={{position:'relative',marginBottom:'40px'}}>

                  <InputPassword handleChange={handleChange} name={'password'} placeholder="NEW PASSWORD"/>


                </div>

                <div style={{position:'relative',marginBottom:'30px'}}>
                  <InputPassword handleChange={handleChange} name={'confirmPassword'}  placeholder="NEW PASSWORD AGAIN" />
                </div>





                <Button type="submit" variant="contained" color="" class="loginmobile_button"
                        onClick={handleConfirmVerification}
                >

                  Send
                </Button>
              </div>

              <div className="mt-3 passowrd_validation_center">
                <PasswordValidations password={password} allPassed={toggleResetBtn} />
              </div>



            </form>
          </div>
        </div>
      )}

    </div>

    // <div className="login">
    //   <CssBaseline />
    //   <Container maxWidth="xs">
    //     <Card className="login-card" style={{ marginTop: "50px" }}>

    //       <div className="logo">
    //         <img alt="logo" className="logo-custom" src={require('./images/auth-logo.jpg')} />
    //       </div>
    //       <form className="main-form">

    //         <h3>Change Password</h3>
    //         <p className="">Please enter your new password below.</p>

    //         <div className="a-form-ctrl">
    //           <p className="">New Password</p>
    //           <InputPassword handleChange={handleChange} name={'password'} />
    //         </div>
    //         <div className="a-form-ctrl">
    //           <p className="">Enter New Password Again</p>
    //           <InputPassword handleChange={handleChange} name={'confirmPassword'} />
    //         </div>
    //         <p className="a-errorMessage" hidden={errorMessage.length <= 0} style={{ marginTop: '10px' }}>
    //           {errorMessage}
    //         </p>

    //         <div>
    //           <button type="submit" disabled={disableSendBtn} className="a-btn" onClick={handleConfirmVerification}>
    //             Send
    //             </button>
    //           {/* <p style={{ textAlign: 'center' }}><span>Go back to</span>&nbsp;
    //             <span className="forgot-pawd"><Link to="/login">Sign In</Link></span></p> */}
    //         </div>
    //         <PasswordValidations password={password} allPassed={toggleResetBtn} />
    //       </form>

    //     </Card>
    //   </Container>
    // </div>

    // {/* <form onSubmit={props.handleSubmit}>
    //         <div className="container">
    //           <h1>reset password</h1>
    //           <p>Please fill in this form to login.</p>
    //           <hr />
    //           <label >
    //             <b>verificationCode</b>
    //           </label>
    //           <input
    //             type="text"
    //             placeholder="Enter verificationCode"
    //             name="verificationCode"
    //             required
    //             onChange={props.handleChange}
    //           />
    //     <br/>
    //     <label >
    //                     <b>new Password</b>
    //                   </label>
    //                   <input
    //                     type="password"
    //                     placeholder="Enter new Password"
    //                     name="password"
    //                     required
    //                     onChange={props.handleChange}
    //                   />
    //           <div className="clearfix">

    //             <button type="submit" className="signupbtn" onClick={props.handleConfirmVerification}>
    //               send verification code
    //             </button>
    //           </div>
    //         </div>
    //       </form> */}
  )
}

export default ResetPasswordForm




// ======================27-10-2020======================

// <div class="loginmobile">
// <div class="loginmobile_header">
//   <MenuIcon style={{ color: '#ffffff', marginRight: '4vw',width:'8vw' }} />
//   <div class="loginmobile_header_text">Member Portal</div>
// </div>



// <div class="loginmobile_container">
//   <form
//   // onSubmit={this.handleSubmit.bind(this)}
//   >
//     <div class="loginmobile_image_container">
//       <img src={require('../../source/Images/Banner/uhs-logo.svg')} class="loginmobile_logo" />
//       <img src={require('../../source/Images/Banner/welcome_image_mobile.png')} class="loginmobile_banner" />
//     </div>

//     <div class="firsttimemobile_bottom_text">
//       <div class="firsttimemobile_bottom_subtext">Change Password</div>
//       <div class="firsttimemobile_bottom_subtext2">
//         <div>
//           Please enter your new passsword below.
//         </div>
//       </div>
//     </div>

//        {/* <p class="mobile_login_new_error_text" hidden={this.props.errorMsg.length <= 0}>{this.props.errorMsg}</p> */}

//     <div class="loginmobile_input_container">

//     <div className="a-form-ctrl_mobile ">
//       <p className="">New Password</p>
//      <InputPassword handleChange={handleChange} name={'password'} />
//      </div>

//      <div className="a-form-ctrl_mobile">
//        <p className="">Enter New Password Again</p>
//      <InputPassword handleChange={handleChange} name={'confirmPassword'} />
//      </div>

//       {/* <input
//         type="text"
//         // ref={this.emailInput}
//         placeholder="ENTER YOUR EMAIL"
//         name="username"
//         required
//         // onChange={this.handleChange}
//         class="loginmobile_input_username"
//       />

//       <input
//         type="password"
//         placeholder="ENTER YOUR PASSWORD"
//         name="password"
//         // ref={this.passwordInput}
//         required
//         // onChange={this.handleChange.bind(this)}
//         class="loginmobile_input_username"
//       /> */}


//       <Button type="submit" variant="contained" color="" class="loginmobile_button"
//       //  disabled={this.props.disableSiginBtn}
//        >
//         SIGN IN
//       </Button>
//     </div>


//   </form>
// </div>
// </div>
