import React, { Component } from 'react'
import MobileFooter from './../MobileFooter';
import SimpleAccordion from '../MobileCommonComponents/SimpleAccordion'
import MobileHeader from '../MobileHeader'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles'
import CommonLoader from '../../CommonLoader';
import Modal1 from 'react-awesome-modal'
import {MobilegetTransactionData, getNeoTransactionMobile,getCardEnableData} from '../../ApiCall'
import { MdSignalCellularNull } from 'react-icons/md';

import customStyle from '../../../components/healthqn/CSS/stylesheet_UHS'
import ChatIcon from '../../WebScreen/ChatBox/ChatIcon';
const NextButton = withStyles(customStyle.viewBtn)(Button)

export default class MyNeedsMobile extends Component {

    constructor(props) {
      super(props)
      this.state = {
        open:false,
        response:[],
        formatedjsonData:null,
        page:0,
        loader: true,
        loadMoreBtnshow:false,
        notfoundmsg:'',
        tempNotAvailableModal:false
      }
    }


    componentDidMount(){
      this.setState({loader:true})
      // this.getCardEnable()
      if(localStorage.getItem('SOURCE') === 'NEO'){
        this.getNeoTransaction();
      } else {
        this.callapi()
      }
      // this.createjson()
    }

    callapi(){
      MobilegetTransactionData(this.state.page).then(res => {
        if(res.data.response.length > 0){
          if(res.data.response[0].totalRecord > 10){
            this.setState({loadMoreBtnshow:true})
          } else {
            this.setState({loadMoreBtnshow:false})
          }
          this.setState({response:res.data.response, page : this.state.page+1, loader:false})
        }else{
          this.setState({notfoundmsg:'My Transactions Data Not Available',loader:false})
        }
        this.createjson()
      })
    }

    getNeoTransaction = () =>{
      getNeoTransactionMobile(this.state.page)
        .then(res =>{
          if(res.data.totalrecords > 0){
            this.setState({response:res.data.pageList, page : this.state.page+1, loader:false})
          }else{
            this.setState({notfoundmsg:'My Transactions Data Not Available',loader:false})
          }
          if(res.data.totalrecords > 10){
            this.setState({loadMoreBtnshow:true})
          } else {
            this.setState({loadMoreBtnshow:false})
          }
          this.createjson();
        })
    }


    getCardEnable=()=>{
 
      let client_id=localStorage.getItem('CLIENT_ID')
      
      
        getCardEnableData(client_id,'MyTransaction')
        .then(res=>{
          console.log("getCardEnableData=====",res.data.response.enable)
          
            if(res.data.response.enable=='true' || res.data.response.enable==true){
                   
            this.setState({tempNotAvailableModal:true})  
            
            }
          
          
        })
      }

    callapiLoadmore(){
      this.setState({loader:true})
      if(localStorage.getItem('SOURCE') === 'NEO'){
        getNeoTransactionMobile(this.state.page)
          .then(res =>{
            var resnew = res.data.pageList;
            var data = this.state.response;
            var newdata = data.concat(resnew);

            this.setState({response:newdata, page : this.state.page+1, loader:false})
            if(res.data.totalrecords === newdata.length){
              this.setState({loadMoreBtnshow:false })
            } else {
              this.setState({loadMoreBtnshow:true })
            }
            this.createjson();
          })
      } else {
        MobilegetTransactionData(this.state.page).then(res => {
          var resnew = res.data.response;
          var data = this.state.response;
          var newdata = data.concat(resnew)

          if(res.data.response[0].totalRecord === newdata.length){
            this.setState({loadMoreBtnshow:false })
          } else {
            this.setState({loadMoreBtnshow:true })
          }

          this.setState({ response: newdata, loader: false, page: this.state.page + 1 })
          this.createjson()
        })
      }

      }

      onCloseTempModal=()=>{
        this.setState({tempNotAvailableModal:false})
        window.location.href='/' 
  
      }
    createjson(){
      // var newobject = {};
      var formatredjson=[];
      this.state.response.map((item,i)=>{
        // console.log("crate json call=============",item.transactionId)
        var newobject = {};
        newobject.ExpenseNumberLabel= "Transaction ID";
        newobject.ExpenseNumberVal= item.transactionId ? item.transactionId : item.transaction_id;

        newobject.DateOfServiceLabel= "Transaction Date";
        newobject.DateOfServiceVal= item.createdDate ? item.createdDate : item.transaction_date;

        newobject.statusClass= item.authorizationMessage ? item.authorizationMessage : item.authorization_message;
        newobject.status= item.authorizationMessage ? item.authorizationMessage : item.authorization_message;

        newobject.ProviderLabel= "Payment Method";
        newobject.ProviderVal= item.payment_method ? item.payment_method: item.type;

        newobject.ChargedLabel= "Total Amount";
        newobject.ChargedVal= item.transactionAmount ? item.transactionAmount : item.transaction_amount;


         /*if(item.transactionAmount || item.transaction_amount){
          newobject.ChargedLabel= "Total Amount";
          newobject.ChargedVal= item.transactionAmount ? item.transactionAmount : item.transaction_amount;
        }else{
          newobject.ChargedLabel= "Total Amount";
          newobject.ChargedVal= "NA";
        }*/
        newobject.PaidLabel= "";
        newobject.PaidVal= "";

        var innerobject={
          MemberLabel: "Monthly Share Contribution",
          MemberVal: item.monthlyShare ? item.monthlyShare : item.monthly_share,
          PaidDateLabel: "UHF Monthly Membership Fee",
          PaidDateVal:item.uhfMonthlyFee ? item.uhfMonthlyFee : item.ufh_monthly_fee,
          EOSLabel: "",
        RepricingDiscountLabel: "Application Fee",
        RepricingDiscountVal: item.applicationFee ? item.applicationFee : item.application_fee,
        IneligibleAmountLabel: "",
        IneligibleAmountVal: "",
        NonSharableAmountLabel: "Refund Amount",
        NonSharableAmountVal: item.refundAmount ? item.refundAmount : 'NA',
        ConsultationFeeLabel: "Refund Description",
        ConsultationFeeVal: item.refundDescription ? item.refundDescription : 'NA',
        SharingPercentageLabel: "",
        SharingPercentageVal: "",
        };



        newobject.MoreDetail =innerobject ;
        // console.log("New Object is=============",newobject)

        formatredjson=formatredjson.concat(newobject)

        // console.log("=====formated json state os=============",formatredjson)
      })

      this.setState({formatedjsonData:formatredjson})

    }

    gotoPayement(){
      this.props.history.push('/MobileTransaction')
    }


      render() {
        // var x=this.state.formatedjsonData
        const { formatedjsonData } = this.state;
        // console.log("====xxxxxxxxxxxxxxxxxx=============",x)
        console.log("=====state formatred json is=============",this.state.formatedjsonData)
          return(
            <div className="">

                {this.state.loader ? <CommonLoader /> : null}

                <MobileHeader name="My Transactions " />

                    {/* ------------------------------------------------ */}

                {
                  this.state.formatedjsonData
                  ?
                  <div className="mobileCommonBody">
                    <div style={{marginBottom: '51px'}}>
                      <SimpleAccordion data={formatedjsonData} />


                      {
                        this.state.loadMoreBtnshow
                        ? <div className="loadMoreWrapper">
                        <button className="loadMoreBtn" onClick={()=>this.callapiLoadmore()}>Load More</button>
                      </div>
                        :null
                      }



                    </div>

                    <div style={{padding:'180px 0px',textAlign:'center'}} className="mobile_data_not_found">{this.state.notfoundmsg}</div>
                  </div>
                   :null
                }

                     {/* ------------------------------------------------ */}
                 <div className="Bottom-Blue">
                    <Grid container>
                        <Grid item xs={12} style={{textAlign:"center"}} >
                                <Button variant="secondary" onClick={()=>this.gotoPayement()} class="mytransaction_changepaymentbutton">
                                  CHANGE PAYMENT METHOD
                                </Button>
                        </Grid>
                    </Grid>
                </div>
                <Modal1 visible={this.state.tempNotAvailableModal} effect="fadeInUp" >
          <div style={{ width: '80vw', height : '38vw' }}>
            <div className="tempModalTxt">
              We’re facing some technical difficulties, due to
              which this feature is currently unavailable. For
              support, call Member Services at {localStorage.getItem('CONTACT_NUMBER')}, Monday through Friday, 8.00am to
              8.00pm CST.
            </div>

          <div className="mqalert_button_div">
            <NextButton variant="contained" class='yellow_popup_caption_button' onClick={this.onCloseTempModal}
                        style={{paddingLeft:'1rem',paddingRight:'1rem',paddingTop:'0.5rem',paddingBottom:'0.5rem',height:'37px',backgroundColor:'#eb5757',borderRadius:'20px',color:'#fff',fontWeight:500}} >
              CLOSE
            </NextButton>
          </div>
          </div>
        </Modal1>
        <ChatIcon openChat={()=>console.log('')} shiftup={true} />
                <MobileFooter />
            </div>

          )

    }
}

