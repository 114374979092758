import React, { Component } from 'react';
import style from './style.css';
import Grid from '@material-ui/core/Grid';
import { gethealthcard, getExpensLimit ,getProgramInfo} from '../../ApiCall';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


export default class ProgramInfo extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);
    // this.state = {
    // cardDetails: this.props.cardDetails,
    // expensData: this.props.expensData,
    // }
    this.state = {
      cardDetails: this.props.expensData,
      expensData: this.props.cardDetails,
      programInfo: this.props.programInfo
    }
  }


  // componentDidMount() {
  //   this.getHealthCardDetails();
  //   this.handleExpenseLimit();
  // }

  // getHealthCardDetails = () => {
  //   gethealthcard()
  //     .then((res) => {
  //       console.log('Program info', res)
  //       if (res.data.memberIdCardList != null) {

  //         console.log(res.data);

  //         this.setState({ cardDetails: res.data.memberIdCardList });
  //       } else {
  //       }

  //     })
  // }

  // handleExpenseLimit = () => {
  //   getExpensLimit()
  //     .then((res) => {
  //       console.log('======================= get getExpensLimit =================');
  //       console.log(res);
  //       if (res.data !== null) {
  //         this.setState({
  //           expensData: res.data
  //         });
  //       }
  //     })
  // }



  render() {

    return (

      <div className={style.root} style={{ maxHeight: '90vh', overflowY: 'scroll' ,backgroundColor:'#fff'}}>

        <Grid container className={style.mainContainer}>
          <Grid container style={{ margin: "10px" }} item xs={12} spacing={2}>
            {/* <Grid item xs={6} md={6} lg={6}>
              <span>Program Name</span>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <span className="break-word"> {this.state.programInfo.programName} </span>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <span>Non-Shareable Amount (NSA) for family</span>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <span className="break-word"> {this.state.programInfo.non_Shareable_Amount} </span>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <span>Physician and Ancillary Services Network</span>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <span className="break-word"> {this.state.programInfo.physician_and_Ancillary_Services_Network} </span>
            </Grid> */}


            <div className="expense-section w-100" style={{ backgroundColor: '#fafafa' }}>

              <div className={style.root_tab}>
                <Paper className={style.paper}>
                  <Table className={style.table} size="small" aria-label="a dense table">
                    <TableBody className={style.tableHead}>
                      <TableRow>
                        <TableCell className="w-50" component="th" scope="row">Program Name</TableCell>
                        <TableCell className="w-50" component="th" scope="row" className="break-word"  dangerouslySetInnerHTML={{ __html: this.state.programInfo.programName }}></TableCell> {/*style={{textTransform:'uppercase'}}*/}
                      </TableRow>
                      <TableRow>
                        <TableCell className={style.tableHeadCell}>Non-Shareable Amount (NSA) for family</TableCell>
                        <TableCell className="w-50" component="th" scope="row" className="break-word" dangerouslySetInnerHTML={{ __html: this.state.programInfo.non_Shareable_Amount }}></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={style.tableHeadCell}>Physician and Ancillary Services Network</TableCell>
                        <TableCell className="w-50" component="th" scope="row" className="break-word" dangerouslySetInnerHTML={{ __html: this.state.programInfo.physician_and_Ancillary_Services_Network }}></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </div>
            </div>

            {
              // this.state.cardDetails && this.state.cardDetails.length > 0 && this.state.cardDetails.map((card, key) => (
              //   <Grid item xs={12}>
              //     <Grid item xs={12} md={12} lg={12} style={{ fontWeight: 'bold', fontSize: '14px' }}>
              //       {card.firstName + ' ' + card.lastName}
              //       {/*<span >{card.firstName + ' ' + card.lastName}</span>*/}
              //     </Grid>
              //     <Grid container spacing={2}>
              //       <Grid item xs={6} md={6} lg={6}>Email</Grid>
              //       <Grid item xs={6} md={6} lg={6} style={{ 'word-break': 'break-word' }}>{card.email}</Grid>
              //     </Grid>
              //     <Grid container spacing={2}>
              //       <Grid item xs={6} md={6} lg={6}>Phone Number</Grid>
              //       <Grid item xs={6} md={6} lg={6}>{card.phone}</Grid>
              //     </Grid>
              //   </Grid>

              // ))
            }

            <Grid item xs={12} md={12} lg={12} p={0} className="mt-4">
              <h5>Eligible Services</h5>
            </Grid>
            <div className="expense-section w-100" style={{ backgroundColor: '#fafafa' }}>

              <div className={style.root_tab}>
                <Paper className={style.paper}>
                  <Table className={style.table} size="small" aria-label="a dense table">
                    <TableHead className={style.tableHead}>
                      <TableRow>
                        <TableCell className={style.tableHeadCell}>Eligible Services</TableCell>
                        <TableCell className={style.tableHeadCell} >Amount&nbsp;($)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ maxHeight: '10vh', overflowY: 'scroll' }}>
                      {
                        this.state.cardDetails.map((col) => (
                          col.fieldValue ?
                            <TableRow key={col.idcardField}>
                              <TableCell className="w-50" component="th" scope="row" dangerouslySetInnerHTML={{ __html: col.idcardField }}></TableCell>
                              <TableCell className="w-50" component="th" scope="row" dangerouslySetInnerHTML={{ __html: col.fieldValue }}></TableCell>
                            </TableRow> :
                            <TableRow key={col.idcardField}>
                              <TableCell style={{ background: 'aliceblue' }} colSpan={2} component="th" scope="row" dangerouslySetInnerHTML={{ __html: col.idcardField }}></TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Paper>
              </div>
            </div>


            <Grid item xs={12} md={12} lg={12} className="mt-4">
              <h5>Expense Limit</h5>
            </Grid>
            <div className="expense-section w-100" >

              <div className={style.root_tab}>
                <Paper className={style.paper}>
                  <Table className={style.table} size="small" aria-label="a dense table">
                    <TableHead className={style.tableHead}>
                      <TableRow>
                        <TableCell className={style.tableHeadCell}>Expense Limit</TableCell>
                        <TableCell className={style.tableHeadCell} >Amount&nbsp;($)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        this.state.expensData.map((col) => (
                          col.fieldValue ?
                            <TableRow key={col.idcardField}>
                              <TableCell className="w-50" component="th" scope="row" dangerouslySetInnerHTML={{ __html: col.idcardField }}></TableCell>
                              <TableCell className="w-50" component="th" scope="row" dangerouslySetInnerHTML={{ __html: col.fieldValue }}></TableCell>
                            </TableRow> :
                            <TableRow key={col.idcardField}>
                              <TableCell style={{ background: 'aliceblue' }} colSpan={2} component="th" scope="row" dangerouslySetInnerHTML={{ __html: col.idcardField }}></TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Paper>
                <p className="p-2" style={{fontSize: '0.875rem',
    textAlign: 'left',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.43',marginBottom:'40px',backgroundColor:'#fff'}}>Please refer to the Sharing Guidelines for detailed information. In case of any discrepancies, the Sharing Guidelines will prevail.</p>
              </div>
            </div>

          </Grid>
        </Grid>


      </div >
    )
  }

}
