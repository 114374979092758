import React,{Component} from 'react';
// import MainCard from "../Components/MainCard";
// import DigitalCard from "../Images/";
import DigitalCard from "../Images/Digital Health Card.png";
import Notices from "../Images/Notices.png";
import Provider from "../Images/Find a Provider.png";
import Documents from "../Images/member/Welcome Kit.png";
import Medical from "../Images/Med Q.png";
import Expense from "../Images/Expense Limits.png";
import FAQ from "../Images/FAQs.png";
import { getOs, getDateTime, getPublicIP, getBrowser, getDeviceType } from '../../utils/utility'

export default class RightDrawer extends Component{
        constructor(props){
                super(props)
                this.state={
                        link:'http://www.mdlive.com/FlexCare',
                }
        }

        componentDidMount(){
                var os = getOs()
                console.log("==========RIGHT DRAWER IS====",os)

                if(os== 'Mac OS'){
                this.setState({link:'http://www.mdlive.com/FlexCare'})
                }
                if(os== 'iOS'){
                this.setState({link:'https://apps.apple.com/us/app/mdlive/id839671393'})     
                }
                if(os== 'Windows'){
                this.setState({link:'http://www.mdlive.com/FlexCare'})      
                }
                if(os== 'Android'){
                this.setState({link:'https://play.google.com/store/apps/details?id=com.mdlive.mobile'})      
                }
                if(os== 'Linux'){
                this.setState({link:'http://www.mdlive.com/FlexCare'})     
                }
        }

        opentelemed(){
                window.open(this.state.link)
        }
    render(){
        return(
            <div class="rightdrawer">

               {/* <div class="rightdrawer_card text-center">
                    <img src={require('../Images/Card/health_services_icon.svg')} style={{height:'84px',width:'84px'}}/>
                    <div class="rightdrawer_card_name">Member Services</div>
              </div> */}

            <div class="rightdrawer_card text-center" onClick={()=>this.opentelemed()}>
            <img src={require('../Images/Card/telemed_icon_active.svg')} style={{height:'84px',width:'84px'}}/>
                    <div class="rightdrawer_card_name">Telemed</div>
            </div>

            {/* <div class="rightdrawer_card text-center" >
            <img src={require('../Images/Card/pharma_benefits_icon_active.svg')} style={{height:'84px',width:'84px'}}/>
                    <div class="rightdrawer_card_name">Pharma Benefits</div>
            </div> */}

           

            {/* <div class="rightdrawer_card text-center">
            <img src={require('../Images/Card/my_vitals_icon.svg')} style={{height:'84px',width:'84px'}}/>
                    <div class="rightdrawer_card_name">My Vitals</div>
            </div> */}

            {/* <div class="rightdrawer_card text-center">
            <img src={require('../Images/Card/health_records_icon.svg')} style={{height:'84px',width:'84px'}}/>
                    <div class="rightdrawer_card_name">Health Records</div>
            </div> */}

            {/* <div class="rightdrawer_card text-center">
            <img src={require('../Images/Card/lab_reports_icon.svg')} style={{height:'84px',width:'84px'}}/>
                    <div class="rightdrawer_card_name">Lab Reports</div>
            </div> */}

            </div>
        )
    }
}