import React, { Component } from 'react';
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiFillAlipayCircle } from "react-icons/ai";
import { FaHeart } from "react-icons/fa";
import { FaShareAlt } from "react-icons/fa";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoMdShare } from "react-icons/io";
import CloseIcon from '@material-ui/icons/Close';
import {Form} from "react-bootstrap";
import { Fab } from '@material-ui/core';
import { withRouter } from 'react-router';
import { gethealthcard, getproviderLink, getExpensLimit, getProgramInfo,getsharingguidlineslink,getWelcomeBooklet } from '../ApiCall'
class DocumentsCardNew extends Component {

constructor(props){
    super(props)
    this.state={
       documentmessage:'',
    }
}


    openwelcome(){
        // window.open("https://carynhealth-memberportal-dev-documents.s3.us-east-2.amazonaws.com/AFA/UHS+Welcome+Booklet+-+6kAFAUHSR0626E060920.pdf");
        var windowReference = window.open();

        getWelcomeBooklet().then(res => {
          console.log('Welcome booklet=======', res)
          if (res.data && res.data.length > 0) {
            console.log('Welcome booklet=======')
            let providerLink = res.data[0].fieldValue
            windowReference.location = providerLink;
            // window.open('' + providerLink, '_blank')
          } else {
            console.log('Welcome booklet else block')
            alert('Data Not Availabel')
          }
        })

    }

    opensecond(){
        window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHS+Member+Responsibilities.pdf")
    }

    openthird(){
        window.open(" https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHF-Statement-of-Shared-Faith-%26-Beliefs.pdf")
        // window.open("https://carynhealth-memberportal-dev-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHF+Statement+of+Faith+%26+Beliefs.pdf")
    }

    getstartedHealth(){
        window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+How+do+I+Get+Started+with+Health+Sharing.pdf")
    }

    open5question(){
        window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+5+Questions+about+Health+Sharing.pdf")
    }

   tipsTelemedicin(){
        window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+Tips+on+Telemedicine.pdf")
    }

    great4reason(){
        window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+4+Great+Reasons+to+share+Universal+HealthShare+with+Your+Friends.pdf")
    }

    quick3facts(){
        window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+Doctors!+3+Quick+Facts+about+Health+Sharing.pdf")
    }

    // const openWelcomeKit = () => {
    //     let mail = localStorage.getItem('userMail')
    //     onMouseLeaveImpDocList()
    //     props.openDocumentInNewWindow(mail, 'welcomekit')
    // }


    opensharingguidlines() {
        var windowReference = window.open();

        getsharingguidlineslink().then(res => {
          console.log('Sharing Guidlines', res)
          if (res.data && res.data.length > 0) {
            console.log('Shating guidlines response')
            let providerLink = res.data[0].fieldValue
            windowReference.location = providerLink;
            // window.open('' + providerLink, '_blank')
          } else {
            console.log('Sharing guidlines else block')
            alert('Data Not Availabel')
          }
        })
      }

openAskCaryn=()=> {
   window.open(`https://inf-labs.com/?isnav=true&memberId=${localStorage.getItem('Member_EMPID')}&query=${this.state.documentmessage}`)
}


    render() {

        return (

            <div class="contactinfocard" style={{margin:0}}>
              <div class="myneeds_top_container">
                <img src={require('../Images/LeftDrawer Icon/documents-icon-active.svg')} class="myneeds_header_image"/>
                    <div class="myneeds_header_text">Documents</div>
                    {/* <div>{this.props.close}</div> */}
                    {this.props.close?
                    <CloseIcon  class="modal_close_cursor" onClick={this.props.onClick}/>
                    :null
                }
                    {/* < BsThreeDotsVertical   color="#4f4f4f" class="card_threedot" /> */}
                </div>
                <div  style={{backgroundColor:'#CDACCF', height:53}}>
                    <div style={{paddingTop:7, paddingLeft:18, justifyContent:'space-between', display:'flex'}}>
                      <Form.Control value={this.state.documentmessage} placeholder="Search Within documents"  style={stylesheet.searchInputBox} onChange={e=>this.setState({documentmessage: e.target.value})}/>
                        <Fab style={{backgroundColor:"#543379", width:38, height:38, right:32}} onClick={()=>this.openAskCaryn()}>
                        <img src={require('../../assets/images/carynIcon.jpg')} />
                        </Fab>
                    </div> 
                </div>
              {
                this.props.documentCardData && this.props.documentCardData.enable ?
                  <div className="">
                    <div className="documents_infomiddiv text-left">
                      <div className="documentscardnew_welcome_div" onClick={() => this.openwelcome()}>Welcome Booklet
                      </div>
                      <div className="documentscardnew_welcome_div" onClick={() => this.opensharingguidlines()}>Sharing
                        Guidelines
                      </div>
                      <div className="documentscardnew_welcome_div" onClick={() => this.opensecond()}>Member
                        Responsibilities
                      </div>
                      <div className="documentscardnew_welcome_div" onClick={() => this.openthird()}>Statement of Shared
                        Faith and Beliefs
                      </div>

                      <div className="documentscardnew_welcome_div" onClick={() => this.getstartedHealth()}>Get Started
                        with Health Sharing
                      </div>
                      <div className="documentscardnew_welcome_div" onClick={() => this.open5question()}>5 Questions
                        about Health Sharing
                      </div>
                      <div className="documentscardnew_welcome_div" onClick={() => this.tipsTelemedicin()}>Tips on
                        Telemedicine
                      </div>
                      <div className="documentscardnew_welcome_div" onClick={() => this.great4reason()}>4 Great Reasons
                        to share UHS
                      </div>
                      <div className="documentscardnew_welcome_div" onClick={() => this.quick3facts()}>3 Quick Facts for
                        Doctors
                      </div>

                    </div>
                    <div className="container contactcard_footer">
                      {/* <div class="row">
                        <div class="col text-left">
                            <FaHeart color="rgba(0, 0, 0, 0.54)" size={16} />
                            <IoMdShare color="rgba(0, 0, 0, 0.54)" size={18} class="ml-4" />
                        </div>
                        <div class="col text-right">
                            <MdKeyboardArrowDown color="rgba(0, 0, 0, 0.54)" size={20} />
                        </div>
                    </div> */}
                    </div>

                  </div>
                  :
                  <div style={{padding : '35px'}}>
                    <div className="tempModalTxt">We’re facing some technical difficulties, due to
                      which this feature is currently unavailable. For
                      support, call Member Services at {localStorage.getItem('CONTACT_NUMBER')}, Monday through Friday, 8.00am to
                      8.00pm CST.</div>
                  </div>
              }
            </div>
        )
    }
}
export default withRouter(DocumentsCardNew);
const stylesheet={
  searchInputBox:{
      borderRadius:40, 
      paddingRight:32, 
      paddingLeft:12,
      width:'19vw', 
      borderColor:'#420045', 
      borderWidth:'revert',
  }
}