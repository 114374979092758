import React, { Component } from 'react'
import ReactCardFlip from 'react-card-flip'
// import HealthCardFrontView from '../HealthCard/HealthCardFrontView'
// import HealthCardBackView from '../HealthCard/HealthCardBackView'

import HealthtoolsFrontRoot from './HealthToolsFrontRoot'
import HealthtoolsBackRoot from './HealthToolsBackRoot'

import  './HealthToolsStyle.css'




class HealthTools extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFlipped: false,
      network:this.props.network,
      contactNumber:this.props.contactNumber,
    }
    this.handleClick = this.handleClick.bind(this)
    console.log("IDCARD PAGE PROPS IS===",this.props)
  }

  
  handleClick(e) {
    //   alert("parent call")
    // e.preventDefault()
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }))
  }

 

  render() {
    console.log("STATE DATA IS===",this.state.templateData)
    return (
      <div className=''>
        {localStorage.getItem('USER_PORTAL') == 'serviceCloud' ? 
           <ReactCardFlip isFlipped={this.state.isFlipped} flipDirection="vertical">
            <div>
              <HealthtoolsFrontRoot method={this.handleClick} data={JSON.parse(localStorage.getItem('MEMBER_IDCARD_DATA'))} {...this.props}  
              onClose={this.props.handleClose} showEmpId={this.props.showEmpId} network={this.props.network}
              contactNumber={this.state.contactNumber}
              //  templateData={this.props.templateData}
              />
            </div>

            <div className="id-card-back-main">
              <HealthtoolsBackRoot method={this.handleClick} network={this.state.network}  onClose={this.props.handleClose}
              contactNumber={this.state.contactNumber} cardId={this.props.cardId}
                //  templateData={this.props.templateData}
              />
            </div>
          </ReactCardFlip> : 
          <ReactCardFlip isFlipped={this.state.isFlipped} flipDirection="vertical">
            <div>
              <HealthtoolsFrontRoot method={this.handleClick} data={this.props.data} {...this.props}  
              onClose={this.props.handleClose} showEmpId={this.props.showEmpId} network={this.props.network}
              contactNumber={this.state.contactNumber}
              //  templateData={this.props.templateData}
              />
            </div>

            <div className="id-card-back-main">
              <HealthtoolsBackRoot method={this.handleClick} network={this.state.network}  onClose={this.props.handleClose}
              contactNumber={this.state.contactNumber} cardId={this.props.cardId}
                //  templateData={this.props.templateData}
              />
            </div>
          </ReactCardFlip>
        }
        
        
      </div>
    )
  }
}
export default HealthTools



// import React,{Component} from 'react'
// import Modal2 from '@material-ui/core/Modal';
// import { withStyles } from '@material-ui/core/styles'
// import Backdrop from '@material-ui/core/Backdrop'
// import Fade from '@material-ui/core/Fade'
// import IDCard from '../HealthCard/IDCard'
// import ReactCardFlip from 'react-card-flip'
// import HealthToolsFrontRoot from './HealthToolsFrontRoot'
// import HealthToolsFront from './HealthToolsFront'
// import HealthToolsBack from './HealthToolsBack'
// const useStyles = theme => ({
//     modal: {
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//       border:'2px solid red',
    
//     },
//     paper: {
//       backgroundColor: theme.palette.background.paper,
//       border: '2px solid #000',
//       boxShadow: theme.shadows[5],
//       padding: theme.spacing(2, 4, 3)
     
//     }
//   })

// class HealthTools extends Component{
//     constructor(props){
//         super(props)
//         this.state={
//             visible:true,
//             isFlipped:false,
//         }
//         this.handleClick = this.handleClick.bind(this)
//         this.closeModal = this.closeModal.bind(this)
//         // this.print = this.print.bind(this)

        
//         console.log("===============Health Tools card modal====================",this.props)
//     }
//     handleClick(e) {
//         //   alert("parent call")
//         // e.preventDefault()
//         this.setState(prevState => ({ isFlipped: !prevState.isFlipped }))
//       }

//       closeModal(){
//         //   alert("modal close call")
//         //   this.setState({visible:false})
//         this.props.hide(false)
//       }

//     //   print(){
//     //       alert("hello")
//     //       window.print()
//     //   }

//     render(){
//         const { classes } = this.props
//         return(
//             <div>
//                 {
//                     this.props.show?
//                     <Modal2
//                     style={{
//                       overflow: 'auto',
//                       height: '100%'
        
//                     }}
//                     aria-labelledby="transition-modal-title"
//                     aria-describedby="transition-modal-description"
//                     className={classes.modal}
//                     open={this.props.show}
//                     closeAfterTransition
//                     BackdropComponent={Backdrop}
//                     BackdropProps={{
//                       timeout: 500
//                     }}
//                     // onClose={this.handleClose}
//                   >
//                     <Fade in={ this.props.show}>
//                     <ReactCardFlip isFlipped={this.state.isFlipped} flipDirection="vertical">
//                   <div style={{backgroundColor:'#ffffff'}}>
//                   <HealthToolsFrontRoot  
//                   flip={this.handleClick} close={this.closeModal}
//                   />
                   
//                    </div>

//                      <div style={{backgroundColor:'#ffffff'}}>
//                      <HealthToolsBack   flip={this.handleClick} close={this.closeModal}/>
//                      </div>
//                     </ReactCardFlip>
//                     </Fade>
//                   </Modal2>
//                     :null
//                 }
               
//             </div>
//         )
//     }
// }

// export default withStyles(useStyles)(HealthTools)