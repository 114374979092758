import React from 'react'
import { Auth } from 'aws-amplify'

import Card from '@material-ui/core/Card'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Link } from 'react-router-dom'
// import './style.css'
import { useState, useEffect } from 'react'
import InputPassword from './InputPasswordBox'
import PasswordValidations from './PwdValidations'
import { BrowserView, MobileView, isBrowser, isMobile, isTablet, deviceType } from 'react-device-detect'
import MenuIcon from '@material-ui/icons/Menu'
import Button from '@material-ui/core/Button'
import './Login.css'

import WebFooter from '../../source/WebScreen/WebFooter';

function ResetPasswordForm(props) {
  const [verificationCode, setVerification] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [disableResetBtn, setdisableResetBtn] = useState(true)

  const handleConfirmVerification = e => {
    e.preventDefault()
    // console.log('calling reset')
    // console.log(props.email)
    // console.log(verificationCode)
    console.log(password)
    if (password == confirmPassword) {
      props.toggleLoader(true)
      Auth.forgotPasswordSubmit(props.email, verificationCode, password)
        .then(data => {
          props.toggleLoader(false)
          console.log('password reset success')
          console.log(data)
          window.location.reload()
        })
        .catch(err => {
          props.toggleLoader(false)
          console.log(err.message)
          setErrorMessage(err.message)
        })
    } else {
      setErrorMessage('Please enter same password')
    }
  }

  const handleChange = e => {
    // console.log(e.target.value)
    // console.log(e.target.name)
    if (e.target.name == 'verificationCode') {
      setVerification(e.target.value)
    } else if (e.target.name == 'password') {
      console.log(e.target.name)
      let password = e.target.value
      setPassword(e.target.value)
    } else if (e.target.name == 'confirmPassword') {
      setConfirmPassword(e.target.value)
    }
  }

  const toggleResetBtn = allPassed => {
    setdisableResetBtn(!allPassed)
  }

  return (

    <div>
      { deviceType == 'browser' && (isMobile == false || isMobile == 'false') && (isTablet == false || isTablet == 'false') ? (
        <div class="loginformnew">
          <form
            // onSubmit={this.handleSubmit.bind(this)}
          >
            <div class="loginform_containernew">
              <div class="login_image_container">
                <img src={require('../../source/Images/Banner/welcome_image_desktop.png')} class="login_main_image" />
                <img src={require('../../source/Images/Banner/uhs-logo.svg')} class="login_uhs_logo" />
              </div>

              <div class="reset_note">We have sent a password reset code by email to {props.destination}. Enter it below to reset your password.</div>


              {/* <p class="login_new_error_text" hidden={this.props.errorMsg.length <= 0}>{this.props.errorMsg}</p> */}

              <div class="login_input_container_reset_div">

                <input

                  placeholder="CODE"
                  class="xxxxxreset_input_username"
                  type="text"
                  name="verificationCode"
                  required
                  onChange={handleChange}
                />

                <div className="inputpassword_box_container">
                  <InputPassword handleChange={handleChange} name={'password'} placeholder="NEW PASSWORD"/>
                </div>

                <div className="inputpassword_box_container">
                  <InputPassword handleChange={handleChange} name={'confirmPassword'}  placeholder="ENTER NEW PASSWORD" />
                </div>
              </div>
              <div style={{marginTop: '2vw'}} >
                <p className="a-errorMessage"  style={{ marginTop: '10px' }} hidden={errorMessage.length <= 0}>
                  {errorMessage}
                </p>
              </div>

              <div class="reset_button_top_div">
                <button type="submit"  className="checkregister_button margin_top mb-2" onClick={handleConfirmVerification}>
                  CHANGE PASSWORD
                </button>
              </div>

              <div class="passowrd_validation_center">
                <PasswordValidations password={password} allPassed={toggleResetBtn} />
              </div>

              <div class="checkregister_goto_subtext">
                Go back to <span class="rege_goto" onClick={() => props.gotoLoginScreen()}>Sign In</span>
              </div>


            </div>
          </form>
          <WebFooter />
        </div>
      ): (
        <div class="loginmobile">
          <div class="loginmobile_header">
            {/* <MenuIcon style={{ color: '#ffffff', marginRight: '4vw',width:'8vw' }} /> */}
            <div class="loginmobile_header_text">Member Portal</div>
          </div>

          <div class="loginmobile_container">
            <form >
              <div class="loginmobile_image_container">
                <img src={require('../../source/Images/Banner/uhs-logo.svg')} class="loginmobile_logo" />
                <img src={require('../../source/Images/Banner/welcome_image_mobile.png')} class="loginmobile_banner" />
              </div>

              <div class="reset_password_mail_text_msg">
                <div class="forgotmobile_text_details">
                  <div>We have sent a password reset code by email to {props.destination}. Enter it below to reset your password.</div>

                </div>
              </div>


              <div class="loginmobile_input_container">

                {/* <p class="mobile_login_new_error_text" hidden={this.props.errorMsg.length <= 0}>{this.props.errorMsg}</p> */}

                <div class="mobile-linput">
                  <input
                    placeholder="CODE"
                    class="loginmobile_input_username"
                    type="text"
                    name="verificationCode"
                    required
                    onChange={handleChange}
                  />



                </div>
                <div style={{position:'relative',marginBottom:'40px'}}>

                  <InputPassword handleChange={handleChange} name={'password'} placeholder="NEW PASSWORD"/>


                </div>

                <div style={{position:'relative',marginBottom:'30px'}}>
                  <InputPassword handleChange={handleChange} name={'confirmPassword'}  placeholder="ENTER NEW PASSWORD" />
                </div>

                <p className="a-errorMessage"  style={{ marginTop: '10px' }} hidden={errorMessage.length <= 0}>
                  {errorMessage}
                </p>
                <Button type="submit" variant="contained" color="" class="loginmobile_button"
                        onClick={handleConfirmVerification}>

                  CHANGE PASSOWRD
                </Button>
              </div>

              <div className="mt-3 passowrd_validation_center">
                <PasswordValidations password={password} allPassed={toggleResetBtn} />
              </div>

              <div class="loginmobile_bottom_text">

                <div class="loginmobile_bottom_subtext2">
                  <div>
                    Go to

                    <span class="loginmobile_please_check" onClick={() => props.gotoLoginScreen()}> Sign In</span>

                  </div>

                </div>
              </div>


            </form>
          </div>
        </div>
      )}


      </div>




// =============================Old Code here===========================


    // <div className="login">
    //   <CssBaseline />
    //   <Container maxWidth="xs">
    //     <Card className="login-card" style={{ marginTop: "50px" }}>

    //       <div className="logo">
    //         <img alt="logo" className="logo-custom" src={require('./images/auth-logo.jpg')} />
    //       </div>
    //       <form className="main-form">

    //         <p>We have sent a password reset code by email to {props.destination}. Enter it below to reset your password.</p>
    //         <div className="a-form-ctrl">
    //           <p className="">Code</p>
    //           <input
    //             className="a-input"
    //             type="text"

    //             name="verificationCode"
    //             required
    //             onChange={handleChange}
    //           />
    //         </div>
    //         <div className="a-form-ctrl">
    //           <p className="">New Password</p>
    //           <InputPassword handleChange={handleChange} name={'password'} />
    //         </div>
    //         <div className="a-form-ctrl">
    //           <p className="">Enter New Password Again</p>
    //           <InputPassword handleChange={handleChange} name={'confirmPassword'} />
    //         </div>
    //         <p className="a-errorMessage" hidden={errorMessage.length <= 0} style={{ marginTop: '10px' }}>
    //           {errorMessage}
    //         </p>

    //         <div>
    //           <button type="submit" disabled={disableResetBtn} className="a-btn" onClick={handleConfirmVerification}>
    //             Change password
    //             </button>
    //           <p style={{ textAlign: 'center' }}><span>Go back to</span>&nbsp;
    //             <span className="forgot-pawd" onClick={() => props.gotoLoginScreen()}>Sign In</span></p>
    //         </div>

    //         <PasswordValidations password={password} allPassed={toggleResetBtn} />

    //       </form>

    //     </Card>
    //   </Container>
    // </div>

    // {/* <form onSubmit={props.handleSubmit}>
    //         <div className="container">
    //           <h1>reset password</h1>
    //           <p>Please fill in this form to login.</p>
    //           <hr />
    //           <label >
    //             <b>verificationCode</b>
    //           </label>
    //           <input
    //             type="text"
    //             placeholder="Enter verificationCode"
    //             name="verificationCode"
    //             required
    //             onChange={props.handleChange}
    //           />
    //     <br/>
    //     <label >
    //                     <b>new Password</b>
    //                   </label>
    //                   <input
    //                     type="password"
    //                     placeholder="Enter new Password"
    //                     name="password"
    //                     required
    //                     onChange={props.handleChange}
    //                   />
    //           <div className="clearfix">

    //             <button type="submit" className="signupbtn" onClick={props.handleConfirmVerification}>
    //               send verification code
    //             </button>
    //           </div>
    //         </div>
    //       </form> */}
  )
}

export default ResetPasswordForm



//  <form className="main-form">
// <div class="rege">
//   <div class="rege_container">
//     <div class="login_image_container">
//       <img src={require('./images/image.png')} class="reset_login_main_image" />
//       <img src={require('./images/uhs-logo.png')} class="reset_login_uhs_logo" />
//     </div>



//     <div class="reset_note">We have sent a password reset code by email to {props.destination}. Enter it below to reset your password.</div>

//     <div class="reset_container">
//     <input
//           type="text"
//           name="verificationCode"

//           required
//           placeholder="Code"
//           class="reset_input_username"
//           onChange={handleChange}
//         />


//            <input
//           type="password"
//           name='password'

//           required
//           placeholder="New Password"
//           class="reset_input_username"
//           onChange={handleChange}
//         />

//           <input
//            type="password"
//            name='confirmPassword'

//           required
//           placeholder="ENTER NEW PASSWORD"
//           class="reset_input_username"
//           onChange={handleChange}
//         />

//     </div>

//     <p class="reset_errormsg" hidden={errorMessage.length <= 0} style={{  }}>
//      {errorMessage}
//   </p>



//      <button type="submit" disabled={disableResetBtn} className="reset_button" onClick={handleConfirmVerification}>
//        Change password
//        </button>



//         <div class="reset_bottom_text">
//       Go back to{' '}
//       <Link>
//         <span
//           class="reset_goto"
//           onClick={() => props.gotoLoginScreen()}
//         >
//           Sign In
//         </span>
//       </Link>
//     </div>

//     <PasswordValidations password={password} allPassed={toggleResetBtn} />

//   </div>
// </div>
// </form>




{/* <MobileView>
<form className="main-form">
  <div class="loginmobile">
    <div class="loginmobile_header">
      <MenuIcon style={{ color: '#ffffff', marginRight: '20px' }} />
      <div class="loginmobile_header_text">Member Portal</div>
    </div>

    <div class="loginmobile_container">

        <div class="loginmobile_image_container">
          <img src={require('../authentication/images/uhs-logo.png')} class="mob_reset_loginmobile_logo" />
          <img src={require('../authentication/images/image.png')} class="mob_reset_loginmobile_banner" />
        </div>

        <div class="mob_reset_note">We have sent a password reset code by email to {props.destination}. Enter it below to reset your password.</div>


        <div class="mob_reset_container">
        <input
            type="text"
            name="verificationCode"
            required
            placeholder="Code"
            class="mob_reset_input_username"
            onChange={handleChange}
          />


             <input
            type="password"
            name='password'

            required
            placeholder="New Password"
            class="mob_reset_input_username"
            onChange={handleChange}
          />

            <input
             type="password"
             name='confirmPassword'

            required
            placeholder="ENTER NEW PASSWORD"
            class="mob_reset_input_username"
            onChange={handleChange}
          />

         <p class="mob_reset_errormsg" hidden={errorMessage.length <= 0} style={{ marginTop: '10px' }}>
       {errorMessage}
         </p>


        <button type="submit" disabled={disableResetBtn} className="reset_button" onClick={handleConfirmVerification}>
         Change password
         </button>



          <div class="mob_reset_bottom_text">
        Go back to{' '}
        <Link>
          <span
            class="mob_reset_goto"
            onClick={() => props.gotoLoginScreen()}
          >
            Sign In
          </span>
        </Link>
      </div>

      <PasswordValidations password={password} allPassed={toggleResetBtn} />

        </div>


    </div>
  </div>
  </form>
</MobileView> */}
