const style = {
    root: {
        width: '100%'
    },
    button: {
        // width: '100px',
        // height: '40px',
        // marginRight: '20px',
        // fontWeight: '400'
    },
    helpText: {
        color: '#304d63',
        fontSize: '14px',
        lineHeight: '16px',
        textAlign: 'right',
        bottom: '15px',
        position: 'absolute',
        right: '15px'
        // float: 'right'
    },
    finishButton: {
        width: '131px',
        height: '40px',
        fontWeight: '400'
    },

    progress: {
        width: '100%',
        marginTop: '24px',
        height: '10px',
        backgroundColor: '#f2f2f2',
        color: '#533278 !important',
        marginBottom: '30px'
    },
    disabledButton: {
        backgroundColor: '#f0f0f1',
        color: '#b4b4bb',
        width: '100px',
        height: '40px',
        marginRight: '20px'
    },
    textField: {
        width: '23%',
        height: '56px',
        backgroundColor: '#f1f1f1',
        color: '#19191d',
        borderRadius: '4px',
        lineHeight: '0px'
    },
    selectField: {
        width: '23%',
        height: '56px',
        backgroundColor: '#f1f1f1',
        color: '#19191d',
        borderRadius: '4px'
    },
    txtField: {
        width: '100%',
        //height: '56px',
        marginTop: '0',
        marginBottom: '0',
        marginLeft: '0',
        borderRadius: '4px'
    },
    subTitle: {
        fontFamily: "Roboto",
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 'bold',
        marginBottom: '18px'
    },
    Title: {
        fontSize: '14px',
        lineHeight: '16px',
        paddingRight: '10px'

    },
    Select: {
        backgroundColor: '#E1E0E0',
        width: '100%',
        height: '140px'
    },

}
export default style;