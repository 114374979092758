// const awsmobile = {
//     "aws_project_region": "us-east-2",
//     "aws_cognito_identity_pool_id": "us-east-2:182591b8-a992-4ad7-a57d-5499afbe32ef",
//     "aws_user_pools_id": "us-east-2_5Jn7qamnM",
//     "aws_user_pools_web_client_id": "6qf7lmvmfpg6dn33pviiebh8ts",
//     "oauth": {}
// };


const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": process.env.REACT_APP_BUILD.REACT_APP_aws_cognito_identity_pool_id,
    "aws_user_pools_id": process.env.REACT_APP_aws_user_pool_id,
    "aws_user_pools_web_client_id":process.env.REACT_APP_aws_user_pools_web_client_id,
    "oauth": {}
};

export default awsmobile;
