import React, { Component } from 'react'
import Header from './Header'
import './MyNeeds.css'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
 
 
import { getMyneedsEOS, getEMPI } from '../ApiCall'
 
import DataTable from './DataTable'
import Fab from '@material-ui/core/Fab';
import CommonLoader from '../CommonLoader';
import { resetWarningCache } from 'prop-types'
 
 
 
 
 
 
 
 
 
 
export default class MyNeeds extends Component {
 constructor(props) {
   super(props)
   this.state = {
     // rows: myrows
     tableData:[],
     loader:true,
     notfoundmsg1:'',
     notfoundmsg2:'',
     notfoundmsg3:'',
   }
 }
 
 componentDidMount() {
  //  this.myneedsdata()
  this.getEMPID()
   
   
 }
 //gets the getEMPI response
 getEMPID(){
   getEMPI().then(res=>
    {
      this.myneedsEOS()
    })
 }
//  myneedsdata() {
//    getMyneeds().then(res => {
 
//      if(res.data.pageList.length > 0){
//        this.setState({tableData:res.data.pageList,loader:false})
//        // this.setState({tableData:res.data.response,loader:false})
//      }else{
//        this.setState({
//          notfoundmsg1:"No needs have been entered into the system.",
//          notfoundmsg2:"Submitted needs may take 2-3 days to be displayed here.",
//          notfoundmsg3:"For any clarifications call Customer Service.",
//          loader:false})
//      }
//    })
//  }
//my Needs EOS implementation
 myneedsEOS=() => {
   getMyneedsEOS().then(res => {
 
   console.log('====MyNeedsEOS1===')
   console.log(res.data)
       if(res.data && res.data.length > 0){
                
                 this.setState({tableData:res.data,loader:false})
                 // this.setState({tableData:res.data.response,loader:false})
                 console.log('====MyNeedsEOS22===')
                 console.log(res.data)
               }else{
                 this.setState({
                  
                  notfoundmsg1:"No needs have been entered into the system.",
                           notfoundmsg2:"Submitted needs may take 2-3 days to be displayed here.",
                           notfoundmsg3:"For any clarifications call Customer Service.",
                   loader:false})
                   console.log('====My Needs EOS No Data===')
               }
  
     console.log('====MyNeedsEOS response===')
     console.log(res)
   })
 }
 
 goBack = () => {
   this.props.history.push('/')
 }
 
 render() {
   const { classes } = this.props
   return (
     <div className="progInfoMainWrapper">
       <Header name={'MyNeeds'} />
       {this.state.loader ? <CommonLoader /> : null}
       <div className="container progInfoContainer" style={{zIndex:'0'}}>
       <sapn className="Back_page" onClick={this.goBack}><ArrowBackIcon style={{ width: '24px', height: '24px', color: ' #543379',marginRight:'5px'}} onClick={this.goBack} />BACK</sapn>
 
         <div className="commonWrap_MyNeeds" style={{marginTop: '15px'}}>
           <div className="progInfoSection" style={{marginBottom:"-20px"}}>
             <h2 className="progInfoTitle">MY NEEDS</h2>
           </div>
 
 
 
           <div className="tablebackgroundouter">
           {
               this.state.tableData &&  this.state.tableData.length > 0
               ?  <DataTable  tableData={this.state.tableData}/>
             :
             <>
             <div className="data_not_found">
               <h5 class="noneeds_msg_display">{this.state.notfoundmsg1}</h5>
               <h5 class="noneeds_msg_display">{this.state.notfoundmsg2}</h5>
               <h5 class="noneeds_msg_display">{this.state.notfoundmsg3}</h5>
            </div>
             </>
             }
 
 
             {/* {
                this.state.tableData
                ?
                <DataTable  tableData={this.state.tableData}/>
                :<div className="data_not_found">
                  No needs have been entered into the system.
Submitted needs may take 2-3 days to be displayed here.
For any clarifications call Customer Service.
                </div>
             } */}
 
           </div>
 
           <div className="progInfoFooter">
             <div className="row">
               <div className="col-md-8">
 
               </div>
               <div className="col-md-4 dpFlex">
                 {/* <div className="elevation"></div> */}
                 <div className="footerText" style={{display:'flex', flexDirection:'row'}}>
                    <div>
                      <p>Need help?</p>
                      <p>Chat with our Health Share Representative</p>
                      <p>or call 1 (888) 366 6243.</p>
                    </div>
                    <div style={{marginLeft:12}}>
                      <Fab color="#41B5C2" style={{backgroundColor:"#533278"}} aria-label="add" onClick={()=>this.openChat()}>
                          <img src={require('../../assets/images/carynIcon.jpg')} />
                      </Fab>
                    </div>
                  </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
 
 
   )
 }
 openChat=()=>{
  sessionStorage.setItem('chatwindow',true);
  this.props.history.push('/');
}
}
