import * as React from 'react'
import { Global } from '@emotion/core'
import Root from './components/layout/Root'
import { connect } from "react-redux";
import normalize from './styles/normalize'
import globals from './styles/globals';

import { startSession, getLoginCount, } from './actions/homeActions';
import { toggleExitHealthqnAlert } from './actions/healthQnActions';

// import { BrowserRouter as Switch, Route, } from "react-router-dom";

import { Route, Switch, Redirect } from 'react-router-dom'



// import WelcomeComponent from './components/welcome/WelcomeComponent'
// import GuideLines from './components/guidelines/GuideLines'
// import Faq from './components/faq/Faq'
// import Provider from './components/provider/Provider';
// import Home from './components/landing/Home'
/* import Homepage from './components/landing/HomePage'; */
/* import MemberServices from './components/landing/MemberServices' */
// import Home from './components/Source/Screens/Home';
/* import Home from './components/landing/Home'; */
import WebMobileRoot from './source/WebMobileRoot';
// import IdCard from './components/UHS_Digital_Health_Card_v0.2/IdCard'
import Footer from './components/layout/Footer'
// import ResponsiveHeader from './components/layout/ResponsiveHeader'
import Header from './components/layout/Header'
import Loader from './components/layout/Loader';
// import DigitalHealthCard from './source/WebScreen/DigitalHealthCard';
import HealthQnModule from './components/healthqn/HealthQnModule';
// medical
import NewMedical from './source/modules/Medical/NewMedical';
import Medical from './source/modules/Medical/Medical';
import MobileMedical from './source/modules/Medical/MobileMedical';
import NewMobileMedical from './source/modules/Medical/NewMobileMedical';

// medicalDetails
import MedicalDetails from './source/modules/MedicalDetails/MedicalDetails';
import MobileMedicalDetails from './source/modules/MedicalDetails/MobileMedicalDetail';
import NewMobileMedicalDetails from './source/modules/MedicalDetails/NewMobileMedicalDetails';
import MobileDigitalHealthCard from '../src/source/MobileScreen/MobileDigitalHealthCard';
import NewMobileDigitalHealthCard from '../src/source/MobileScreen/newMobileDigitalHealthCard';
import DemoReset from '../src/components/authentication/DemoReset';
// import MyneedsMobile from '../src/source/MobileScreen/MyneedsMobile/MyNeedsMobile';
import MyNeedsMobile from '../src/source/MobileScreen/MyNeedsMobile/MyNeedsMobile'
import WebMyNeeds from '../src/source/WebScreen/WebMyNeeds'
import DigitalHealthCardNew from '../src/source/MobileScreen/DigitalHealthCardNew';

import MyPaymentWalletMobile from './source/MobileScreen/MobilePaymentWallet/MyPaymentWallet';
import SendCardRequest from './source/MobileScreen/MobilePaymentWallet/SendCardRequest';

import PaymentWallet from './source/WebScreen/PaymentWallet/PaymentWallet';
import PaymentWalletServiceCloud from './source/WebScreen/PaymentWallet/PaymentWalletServiceCloud';
import HealthyShareCard from '../src/source/MobileScreen/HealthyShareCard';


import CardModal from '../src/source/WebScreen/CardModal';
import DocumentsScreen from './source/MobileScreen/DocumentsScreen'
import ContactScreen from './source/MobileScreen/ContactScreen';


import MobileTransaction from './source/MobileScreen/Transaction/PaymentMode.js'
import PaymentMode from './source/WebScreen/Transaction/PaymentMode.js'


import HealthToolsCard from './source/MobileScreen/HealthToolsCard'
import ProgramInformation from '../src/source/WebScreen/programInfo/ProgramInformation'
import MyNeeds from './source/WebScreen/MyNeeds';
import Transaction from './source/WebScreen/My Transaction/Transaction'
import MyTransactionMobile from './source/MobileScreen/MyTransactionMobile/MyTransactionMobile'

import MobileNotification from './source/MobileScreen/MobileNotification/MobileNotification'
import AnnouncementMobile from './source/MobileScreen/MobileNotification/AnnouncementMobile'

import IdleSessionHandle from './source/WebScreen/IdleSessionHandle'

// =============Notification=================

import Notification from './source/WebScreen/Notification/Notification';
import AnnouncementNotification from './source/WebScreen/Notification/AnnouncementNotification';
import ChatSearch from './source/WebScreen/ChatBox/ChatSearch';
// import IdCard1 from './components/idcard/IdCard'
// import MobileMyNeeds from './source/MobileScreen/MobileMyNeeds'
// import IdCard1 from './components/idcard/IdCard'
// If your app is big + you have routes with a lot of components, you should consider
// code-splitting your routes! If you bundle stuff up with Webpack, I recommend `react-loadable`.
//
// $ yarn add react-loadable
// $ yarn add --dev @types/react-loadable
//
// The given `pages/` directory provides an example of a directory structure that's easily
// code-splittable.

const Routes: React.SFC = (props: any) => {

  React.useEffect(() => {
    console.log('props form route=======',props)
    startSession().then((data: any) => {
      console.log('session started')
      if (!sessionStorage.loginAPICalled) {
        props.getLoginCount();
      }

    });
  }, [])


  return (<Root>
    <Global styles={normalize} />
    <Global styles={globals} />
    {/* <Header healthqnInEditMode={props.healthqnInEditMode} toggleExitHealthqnAlert={props.toggleExitHealthqnAlert} /> */}
    <IdleSessionHandle />
    <Switch>
    {/* <Route exact path="/" component={CardModal} />  */}
      <Route exact path="/" component={WebMobileRoot} />
      <Route exact path="/askCaryn" component={ChatSearch} />
      {/* <Route exact path="/" component={Home} />  */}
      {/* <Route  path="/Medical" component={Medical} /> */}
      <Route  path="/Medical" component={NewMedical} />
      {/* <Route path="/MobileMedical" component={MobileMedical} /> */}
      {/* Medical detail */}
      {/* <Route path="/MobileDigitalHealthCard" component={MobileDigitalHealthCard} /> */}
      <Route path="/DigitalHealthCardNew" component={DigitalHealthCardNew} />
      <Route path="/HealthyShareCard" component={HealthyShareCard} />


      <Route path="/DocumentsScreen" component={DocumentsScreen} />
      <Route path="/ContactScreen" component={ContactScreen} />
      {/* <Route path="/MobileDigitalHealthCard" component={NewMobileDigitalHealthCard} /> */}
      <Route path="/MedicalDetail" component={MedicalDetails} />

      <Route path="/DemoReset" component={DemoReset} />
      <Route path="/MyNeedsMobile" component={MyNeedsMobile} />
      {/*<Route path="/WebMyNeeds" component={WebMyNeeds} />*/}

      <Route path="/MobileMedicalDetail" component={MobileMedicalDetails} />

      {/* <Route path="/MobileMedical" component={MobileMedical} /> */}
      <Route path="/MobileMedical" component={NewMobileMedical} />

      {/* <Route path="/NewMobileMedicalDetail" component={NewMobileMedicalDetails} /> */}
      <Route path="/HealthToolsCard" component={HealthToolsCard}/>

      <Route exact path="/MyNeeds" component={MyNeeds}/>
      <Route path="/Transaction" component={Transaction}/>
	  <Route path="/PaymentWallet" component={PaymentWallet}/>
	  <Route path='/PaymentWalletServiceCloud' component={PaymentWalletServiceCloud}/>
    
      <Route path="/ProgramInformation" component={ProgramInformation} />
	   <Route path="/PaymentMode" component={PaymentMode}/>
      <Route path="/MobileTransaction" component={MobileTransaction}/>

      <Route path="/MyTransactionMobile" component={MyTransactionMobile}/>
	  <Route path="/MyPaymentWalletMobile" component={MyPaymentWalletMobile}/>
	  <Route path="/SendCardRequest" component={SendCardRequest}/>

	  
      <Route path="/Notification" component={Notification}/>
      <Route path="/AnnouncementNotification" component={AnnouncementNotification}/>
      
      
      <Route path="/MobileNotification" component={MobileNotification}/>
      <Route path="/AnnouncementMobile" component={AnnouncementMobile}/>

      <Redirect from="*" to="/" />
      <Route exact path="/healthqns" component={HealthQnModule} />
      {/* <Route exact path="/healthcard" component={DigitalHealthCard} /> */}

      {/* <Redirect from="*" to="/" /> */}


      {/* <Route exact path="/memberportal/" component={() => <div>you are not authorized</div>} />  */}

    </Switch>

    {/* <Footer /> */}
    <Loader showLoader={props.showLoader} />

  </Root>)

}

const mapStateToProps = (state: any, ownProps: any) => {
  console.log('ownProps::', ownProps)
  return {
    showLoader: state.home.showGlobalLoader,
    healthqnInEditMode: state.healthQn.healthqnInEditMode
    // showAlert: state.home.showAlert,
    // alertMsg: state.home.alertMsg,
    // alertTitle: state.home.alertTitle
  }
};
// closeAlert
export default connect(mapStateToProps, { getLoginCount, toggleExitHealthqnAlert })(Routes);

// export default Routes
