import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { BrowserView, MobileView, isBrowser, isMobile, isTablet, deviceType } from 'react-device-detect'
import MenuIcon from '@material-ui/icons/Menu'
import Button from '@material-ui/core/Button'
import './Login.css'
export default class DemoReset extends Component {
  render() {
    return (
      <div>
        { deviceType == 'browser' && (isMobile == false || isMobile == 'false') && (isTablet == false || isTablet == 'false') ? (
          <div class="rege">
            <div class="rege_container">
              <div class="login_image_container">
                <img src={require('./images/image.png')} class="reset_login_main_image" />
                <img src={require('./images/uhs-logo.png')} class="reset_login_uhs_logo" />
              </div>

              {/* {props.destination} */}
              <div class="reset_note">We have sent a password reset code by email to avishcar@gmail.com. Enter it below to reset your password.</div>

              <div class="reset_container">
                <input
                  type="text"
                  name="verificationCode"
                  // pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$"
                  required
                  placeholder="Code"
                  class="reset_input_username"
                  // onChange={props.handleChange}
                />


                <input
                  type="password"
                  // name="email"
                  // pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$"
                  required
                  placeholder="New Password"
                  class="reset_input_username"
                  // onChange={props.handleChange}
                />

                <input
                  type="password"
                  // name="email"
                  // pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$"
                  required
                  placeholder="ENTER NEW PASSWORD"
                  class="reset_input_username"
                  // onChange={props.handleChange}
                />

              </div>

              <div class="reset_errormsg">Please enter same password</div>


              <Button type="submit" variant="contained" color="" class="reset_button">
                CHANGE PASSWORD
              </Button>


              <div class="reset_bottom_text">
                Go back to{' '}
                <Link>
                  <span
                    class="reset_goto"
                    // onClick={() => props.gotoLoginScreen()}
                  >
                    Sign In
                  </span>
                </Link>
              </div>

              {/* <form
               onSubmit={handleSendVerification}
               >
               <div class="login_input_container">
               <div class="rege_left_container">
               <div class="rege_touble_text">Forgot your password?</div>
               <div class="rege_subtext">Enter your Email and we will send a message to reset your password.</div>
               </div>

               <input
               type="email"
               name="email"
               pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$"
               required
               placeholder="ENTER YOUR EMAIL"
               class="login_input_username"
               onChange={props.handleChange}
               />

               <Button type="submit" variant="contained" color="" class="rege_button">
               RESET MY PASSWORD
               </Button>
               </div>
               </form> */}

              {/* <div class="rege_bottom_text">
               Go back to{' '}
               <Link>
               <span
               class="rege_goto"
               onClick={() => props.gotoLoginScreen()}
               >
               Sign In
               </span>
               </Link>
               </div> */}

            </div>
          </div>
        ): (
          <div class="loginmobile">
            <div class="loginmobile_header">
              {/* <MenuIcon style={{ color: '#ffffff', marginRight: '20px' }} /> */}
              <div class="loginmobile_header_text">Member Portal</div>
            </div>

            <div class="loginmobile_container">
              <form
                // onSubmit={handleSendVerification}
              >
                <div class="loginmobile_image_container">
                  <img src={require('../authentication/images/uhs-logo.png')} class="mob_reset_loginmobile_logo" />
                  <img src={require('../authentication/images/image.png')} class="mob_reset_loginmobile_banner" />
                </div>

                <div class="mob_reset_note">We have sent a password reset code by email to avishcar@gmail.com. Enter it below to reset your password.</div>


                <div class="mob_reset_container">
                  <input
                    type="text"
                    name="verificationCode"
                    // pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$"
                    required
                    placeholder="Code"
                    class="mob_reset_input_username"
                    // onChange={props.handleChange}
                  />


                  <input
                    type="password"
                    // name="email"
                    // pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$"
                    required
                    placeholder="New Password"
                    class="mob_reset_input_username"
                    // onChange={props.handleChange}
                  />

                  <input
                    type="password"
                    // name="email"
                    // pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$"
                    required
                    placeholder="ENTER NEW PASSWORD"
                    class="mob_reset_input_username"
                    // onChange={props.handleChange}
                  />

                  <div class="mob_reset_errormsg">Please enter same password</div>

                  <Button type="submit" variant="contained" color="" class="mob_reset_button">
                    CHANGE PASSWORD
                  </Button>

                  <div class="mob_reset_bottom_text">
                    Go back to{' '}
                    <Link>
                  <span
                    class="mob_reset_goto"
                    // onClick={() => props.gotoLoginScreen()}
                  >
                    Sign In
                  </span>
                    </Link>
                  </div>

                </div>




                {/* <div class="loginmobile_input_container">
                 <input
                 type="email"
                 name="email"
                 pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$"
                 required
                 placeholder="ENTER YOUR EMAIL"
                 class="login_input_username"
                 class="loginmobile_input_username"
                 />
                 <Button
                 type="submit"
                 variant="contained"
                 color=""
                 class="loginmobile_button"
                 // disabled={this.props.disableSiginBtn}
                 >
                 RESET MY PASSWORD
                 </Button>
                 </div> */}

                {/* <div class="loginmobile_bottom_text">
                 <div class="loginmobile_text_blue">Forgot your password?</div>
                 <div class="forgotmobile_text_details">
                 <div>Enter your Email and we will send a message to</div>
                 <div>reset your password</div>
                 </div>

                 <div class="loginmobile_bottom_subtext2">
                 <div>
                 Go to
                 <Link>
                 <span
                 class="loginmobile_please_check"
                 onClick={() => props.gotoLoginScreen()}
                 >
                 {' '}
                 Sign In
                 </span>
                 </Link>
                 </div>
                 </div>
                 </div> */}

              </form>
            </div>
          </div>
        )}

      </div>
    )
  }
}
