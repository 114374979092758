import React, { Component } from 'react';
import Header from '../Header'
import WebFooter from '../WebFooter'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import NotificationIcon from "../../Images/notification/notification_icon.svg"
import AlertNotification from "../../Images/notification/notification_alert_icon.svg"
import AnnouncementNotification from "../../Images/notification/notification_announcement_icon.svg"
import PromoNotification from "../../Images/notification/notification_promo_icon.svg"
import ReminderNotification from "../../Images/notification/notification_reminder_icon.svg"
import SurveyNotification from "../../Images/notification/notification_survey_icon.svg"
import UpdateNotification from "../../Images/notification/notification_update_icon.svg"
import { Link } from 'react-router-dom';
import { getNotificationDetails, updateNotificationStatus } from '../../ApiCall'
import CommonLoader from '../../CommonLoader';
import Modal1 from 'react-awesome-modal';
import moment from "moment";
import MobileFooter from '../../MobileScreen/MobileFooter';


import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import {NavigateURL} from './NavigationURL'
import { Row, Container, Col, Button, Card, Modal } from 'react-bootstrap'
import { withStyles } from '@material-ui/core/styles';
import customStyle from '../../../components/healthqn/CSS/stylesheet_UHS';
const NextButton = withStyles(
  customStyle.viewBtn
)(Button);
export default class Notification extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataUnread: [],
      dataRead: [],
      data: [],
      message: null,
      loader: false,
      date: '',
      page: 0,
      rowsPerPage: 20,
      rowCount: 0,
      createdDate: [],
      tempNotAvailableModal:false,
    }
  }
  componentDidMount() {
    this.getNotification();
    localStorage.removeItem("openModal")
    this.loadQueryParams()
    document.addEventListener("myevent1", (event) => {
      this.setState({ page: 0 }, () => this.getNotification());
    });
  }

  loadQueryParams = () => {
   
    let queryParams = getQueryParams();
    if(localStorage.getItem("openModal")=='true'){
      this.openTempModal()
    }

  }

  getNotification = () => {
    this.setState({
      loader: true
    })
    getNotificationDetails(this.state.page)
      .then(res => {
        console.log(res)
        if (res.data && res.data.response) {

          this.setState({
            data: res.data.response.notificationDetails,
            rowCount: res.data.response.pageList,
            loader: false
          })
          // ,()=>{
          //   this.setState({data:[...this.state.data,...this.state.dataRead,...this.state.dataUnread]},()=>console.log("notification===",this.state.data))

          // })
        } else {
          this.setState({ message: "Notification not available !", loader: false })
        }
      })
  }


  getDateInUTC = (date, getInMillisecs) => {

    if (date) {

      let newDateTime = new Date(date)

      return new Date(newDateTime)

    }

    return date

  }



  dateformat(date) {
    const enrollmentDate = this.getDateInUTC(date, true)
    let nowdate = this.getDateInUTC(new Date(), true)

    var day = moment(enrollmentDate).format('DD')

    var mon = moment(enrollmentDate).format('MM')

    var year = moment(enrollmentDate).format('YYYY')

    var date = mon + "/" + day + "/" + year

    var todayDate = moment(nowdate).format('MM/DD/YYYY')
    let hr = moment(enrollmentDate).format('hh')

    if (date !== todayDate) {

      return moment(date).format('MMM DD')

    } else {


      if (hr == '00') {
        return moment(enrollmentDate).format('m') + 'mins'
      }
      if (hr > 12) {
        return moment(enrollmentDate).format('hh:mm A')
      } else {
        return moment(enrollmentDate).format('hh:mm A')
      }

    }

  }


  onClickNotification=(id, url,type)=>{
    NavigateURL(id, url,type)
    
    
  }



  handleChangePage = (event, newPage) => {
    console.log("new page---", newPage)
    this.setState({ page: newPage }, () => this.getNotification())
  };

  //  handleChangeRowsPerPage = event => {
  //   this.setState({
  //     rowsPerPage:parseInt(event.target.value, 20),
  //     page:0
  //   })


  openTempModal = () => {
    this.setState({
      sidebarOpen: false,
      tempNotAvailableModal: true
    })
  }
  
  closeTempModal = () =>{
    localStorage.removeItem("openModal")
    
    this.setState({ tempNotAvailableModal: false })
    window.location.href ='/Notification'
  }


  goBack = () => {
    this.props.history.push('/')
  }
  render() {

    return (
      <div className="progInfoMainWrapper">
        {this.state.loader ? <CommonLoader /> : null}
        <div className="mobileViewHeader">
          <div className="mobile_header">
            <Link to="/"><ArrowBackIcon style={{ width: '24px', height: '24px', color: '#ffffff' }} /></Link>
            <div className="mobile_header_title">My Notifications </div>
            {/* <NotificationsIcon style={{width:'24px',height:'24px',color:'rgba(255, 255, 255, 0.74)'}}/> */}
          </div>
        </div>

        <div className="webHeader">
          <Header name={"My Notifications"} />

        </div>
        {/* <div class="DisplayNotification_Card-BG"> */}
        <div className="container progInfoContainer">
          <span className="Back_page" onClick={this.goBack}><ArrowBackIcon style={{ width: '24px', height: '24px', color: ' #543379', marginRight: '5px' }} onClick={this.goBack} />BACK</span>
          <div className="commonWrap" >
            <div className="myNotificationSection_mob" >
              <h2 className="progInfoTitle">My Notifications </h2>

              <TableContainer component={Paper} style={{maxHeight:'300px',overflow:'auto'}}>
                <Table stickyHeader aria-label="sticky table">


                  <TableBody>

                    {/* {   */}
                    {/*{this.state.data.slice(this.state.page *this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((data,index) => {*/}
                    {this.state.data ?

                      this.state.data.map((data, index) => {

                        return (data.type == 'REMINDER' || data.type == 'reminder') ?
                        <TableRow key={index}>



                          <TableCell class={data.status == 'delivered' ? "notification_details_mainDiv" : 'notification_details_mainDiv_read'}>
                           
                              <>
                                {/* <div key={index} class={data.status=='delivered'?"notification_details_mainDiv":'notification_details_mainDiv_read'} > */}
                                <img src={ReminderNotification} class="Notification_category_img_details" />
                                <div class="notification_category_Message_details" onClick={() =>this.onClickNotification(data.notificationID, data.urlPath)}>
                                  <p class="notification_category_label_details">{data.title}
                                    <span className="notification_details_Date">{this.dateformat(data.createdDate)}</span>

                                  </p>
                                  <div className="notification_details_message">{data.message}</div>
                                </div>
                                {/* </div> */}
                              </>
                              </TableCell>


                            </TableRow>
                              :
                              (data.type == 'ALERT' || data.type == 'alert') ?
                                <>
                                <TableRow key={index}>



                    <TableCell class={data.status == 'delivered' ? "notification_details_mainDiv" : 'notification_details_mainDiv_read'}>
                                  {/* <div key={idx} class={data.status=='delivered'?"notification_details_mainDiv":'notification_details_mainDiv_read'}> */}
                                  <img src={AlertNotification} class="Notification_category_img_details" />
                                  <div class="notification_category_Message_details" onClick={() =>this.onClickNotification(data.notificationID, data.urlPath)}>
                                    <p class="notification_category_label_details">{data.title}
                                      <span className="notification_details_Date">{this.dateformat(data.createdDate)}</span>

                                    </p>
                                    <div className="notification_details_message">{data.message}</div>
                                    {/* <span className="notification_details_Date">{moment(data.created_date).format('MMMM DD, YYYY')}</span>
                            <span className="notification_details_Time">{moment(data.created_date).format("HH:mm A")}</span> */}
                                  </div>
                                  {/* </div> */}
                                  </TableCell>


</TableRow>
                                </>
                                :
                                (data.type == 'UPDATE' || data.type == 'update') ?
                                  <>
                                   <TableRow key={index}>



                              <TableCell class={data.status == 'delivered' ? "notification_details_mainDiv" : 'notification_details_mainDiv_read'}>
                                    <img src={UpdateNotification} class="Notification_category_img_details" />
                                    <div class="notification_category_Message_details" onClick={() =>this.onClickNotification(data.notificationID, data.urlPath)}>
                                      <p class="notification_category_label_details">{data.title}
                                        {/* (moment.duration(moment(new Date()).diff(data.created_date))).asHours()/24 ==1?moment(data.created_date).format('LT'):moment(data.created_date).minutes() */}
                                        <span className="notification_details_Date">{this.dateformat(data.createdDate)}</span>

                                      </p>
                                      <div className="notification_details_message">{data.message}</div>
                                      {/* <span className="notification_details_Date">{moment(data.created_date).format('MMMM DD, YYYY')}</span>
                            <span className="notification_details_Time">{moment(data.created_date).format("HH:mm A")}</span> */}
                                    </div>
                                    </TableCell>


                            </TableRow>
                                  </>
                                  :
                                  (data.type == 'SURVEY' || data.type == 'survey') ?
                                    <>
                                    <TableRow key={index}>



<TableCell class={data.status == 'delivered' ? "notification_details_mainDiv" : 'notification_details_mainDiv_read'}>
                                      <img src={SurveyNotification} class="Notification_category_img_details" />
                                      <div class="notification_category_Message_details" onClick={() =>this.onClickNotification(data.notificationID, data.urlPath)}>
                                        <p class="notification_category_label_details">{data.title}
                                          <span className="notification_details_Date">{this.dateformat(data.createdDate)}</span>

                                        </p>
                                        <div className="notification_details_message">{data.message}</div>
                                        {/* <span className="notification_details_Date">{moment(data.created_date).format('MMMM DD, YYYY')}</span>
                        <span className="notification_details_Time">{moment(data.created_date).format("HH:mm A")}</span> */}
                                      </div>
                                      </TableCell>


                            </TableRow>
                                    </>
                                    :
                                    (data.type == 'PROMOTIONAL' || data.type == 'promotional') ?
                                      <>
                                        <TableRow key={index}>



<TableCell class={data.status == 'delivered' ? "notification_details_mainDiv" : 'notification_details_mainDiv_read'}>
                                        <img src={PromoNotification} class="Notification_category_img_details" />
                                        <div class="notification_category_Message_details" onClick={() =>this.onClickNotification(data.notificationID, data.urlPath)}>
                                          <p class="notification_category_label_details">{data.title}
                                            <span className="notification_details_Date">{this.dateformat(data.createdDate)}</span>

                                          </p>
                                          <div className="notification_details_message">{data.message}</div>
                                          {/* <span className="notification_details_Date">{moment(data.created_date).format('MMMM DD, YYYY')}</span>
                                <span className="notification_details_Time">{moment(data.created_date).format("HH:mm A")}</span> */}
                                        </div>
                                        </TableCell>


                            </TableRow>
                                      </>
                                      :
                                      (data.type == 'ANNOUNCEMENT' || data.type == 'announcement') ?
                                      <>
                                        <TableRow key={index}>



                                      <TableCell class={data.status == 'delivered' ? "notification_details_mainDiv" : 'notification_details_mainDiv_read'}>
                                        <img src={AnnouncementNotification} class="Notification_category_img_details" />
                                        <div class="notification_category_Message_details" onClick={() =>this.onClickNotification(data.notificationID, data.urlPath)}>
                                          <p class="notification_category_label_details">{data.title}
                                            <span className="notification_details_Date">{this.dateformat(data.createdDate)}</span>

                                          </p>
                                          <div className="notification_details_message">{data.message}</div>
                                          {/* <span className="notification_details_Date">{moment(data.created_date).format('MMMM DD, YYYY')}</span>
                                <span className="notification_details_Time">{moment(data.created_date).format("HH:mm A")}</span> */}
                                        </div>
                                        </TableCell>


                            </TableRow>
                                      </>
                                     
                                        :
                                        null
                            // }

                         

                      })


                      :
                      <TableRow >



                      <TableCell align="center" class="notification_details_mainDiv" style={{display:'block',textAlign:'center'}}>
                      <div className="data_not_found" style={{ height: '200px' }}>
                        <p class="noneeds_msg_display">You currently have no new notifications.</p>

                      </div>
                      </TableCell>


                      </TableRow>
                    }

                  </TableBody>

                </Table>

              </TableContainer>
              {this.state.data ?
               <TablePagination

               // rowsPerPageOptions={[10, 15, 20]}
               rowsPerPageOptions={[]}
               component="div"

               count={this.state.rowCount}

               rowsPerPage={this.state.rowsPerPage}

               page={this.state.page}

               onChangePage={this.handleChangePage}

               onChangeRowsPerPage={this.handleChangeRowsPerPage}

             />
                :
                null
            
            }
             

            </div>
          </div>
        
        
        </div>
        <Modal1 visible={this.state.tempNotAvailableModal} width="30%" effect="fadeInUp" >
              <div style={{ textAlign: 'center' }}>
                <div className="tempModalTxt">We’re facing some technical difficulties, due to
                which this feature is currently unavailable. For
                  support, call Member Services at {localStorage.getItem('CONTACT_NUMBER')}, Monday through Friday, 8.00am to
                  8.00pm CST.</div>
                {/*<div className="web_modal_close_button" onClick={() => this.setState({tempNotAvailableModal : false})}>
                  Close
                </div>*/}
                <NextButton variant="contained" class='yellow_popup_caption_button' onClick={this.closeTempModal}
                  style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingTop: '0.5rem', paddingBottom: '0.5rem', height: '37px', backgroundColor: '#eb5757', borderRadius: '20px', color: '#fff', fontWeight: 500 }} >
                  CLOSE
                </NextButton>
              </div>
            </Modal1>


        <div className="webFooter">
          <WebFooter />
        </div>
        <div className="mobileFooter">
          <MobileFooter />
        </div>
      </div>
    )
  }
}

export const getQueryParams = () => {
  let url = window.location.href;
  
  let queryObj = {}

  if (url !== undefined && url !== null && url.split('?').length > 1) {

    let queryString1 = url.split('?openModal=')[1];
    let queryParams = new URLSearchParams(queryString1)
 console.log("queryString1===",queryString1)
    if (queryString1 == 'true') {
      localStorage.setItem("openModal", true)
     

    }
  }

  // let queryParams = new URLSearchParams(queryString)



}