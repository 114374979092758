import React,{Component} from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import AddAlertOutlinedIcon from '@material-ui/icons/AddAlertOutlined';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined';
import CardMembershipOutlinedIcon from '@material-ui/icons/CardMembershipOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import NotificationIcon from "../Images/notification/my_notifications_icon_active.svg"
import Auth from 'aws-amplify';
import { logoutApplication } from '../../components/layout/Header';
import {Link} from 'react-router-dom'
import { gethealthcard, getproviderLink, getMyneeds, getCardDetails } from '../ApiCall'
import Modal from 'react-awesome-modal';
import { getOs, getDateTime, getPublicIP, getBrowser, getDeviceType } from '../../utils/utility'
export default class LeftDrawer extends Component{
    constructor(props){
        super(props)
        this.state={
            username:localStorage.getItem('subscriberName'),
            id:false,
            link:'http://www.mdlive.com/FlexCare',
            showmyneeds:false,
            dashboardCards : []
        }
        console.log("PROPS DRAWER IS==",this.props)
        this.closeparentdrawer = this.closeparentdrawer.bind(this);
        this.opendocuments = this.opendocuments.bind(this);
        this.opencontacts = this.opencontacts.bind(this);
    }

    componentDidMount(){
        var os = getOs()
        console.log("==========RIGHT DRAWER IS====",os)

        if(os== 'Mac OS'){
        this.setState({link:'http://www.mdlive.com/FlexCare'})
        }
        if(os== 'iOS'){
        this.setState({link:'https://apps.apple.com/us/app/mdlive/id839671393'})
        }
        if(os== 'Windows'){
        this.setState({link:'http://www.mdlive.com/FlexCare'})
        }
        if(os== 'Android'){
        this.setState({link:'https://play.google.com/store/apps/details?id=com.mdlive.mobile'})
        }
        if(os== 'Linux'){
        this.setState({link:'http://www.mdlive.com/FlexCare'})
        }
      getCardDetails()
        .then( res => {
          if(res.data.response){
            this.setState({
              dashboardCards : res.data.response,
            });
          }
        })
  }

    closeparentdrawer(enable) {
        if(enable){
          this.props.drawercloseparent(false);
        } else {
          this.props.openTempModal();
        }
      }

      openExpenseCard() {
        this.props.drawercloseExpenseOpen(false);
      }

      opendocuments(){
        this.props.documentsopen(false);
      }

    handleSignout = () => {
      logoutApplication();
    }

    opennotices(enable){
      if(enable){
        window.open("https://www.universalhealthfellowship.org/notices/");
      } else {
        this.props.openTempModal();
      }
    }

    opencontacts(){
        this.props.contactopen(false);
    }

    openprovider(enable) {
      if(enable){
        var windowReference = window.open();
        getproviderLink().then(res => {
          console.log('PROVIDER===', res)
          if (res.data && res.data.length > 0) {
            console.log('PROVIDER===Link Dta is greater than 0')
            let providerLink = res.data[0].fieldValue
            windowReference.location = providerLink;
            // window.open('' + providerLink, '_blank')
          } else {
            console.log('PROVIDER===Link Dta is LESSS 0')
            alert('Data Not Availabel')
          }
        })
      } else {
        this.props.openTempModal();
      }

    }

    openfaqs(enable){
      if(enable){
        window.open("https://www.universalhealthfellowship.org/FAQs/")
      } else {
        this.props.openTempModal();
      }
    }

    openhealthcard(){
        this.setState({id:true})
    }

    opentelemed(){
        window.open(this.state.link)
    }

    render(){
        return(
            <div class="leftdrawer">
              <div className="ld_user_div">
                <AccountCircleIcon/>
                <div className="ld_username">Hello, {this.state.username} </div>
              </div>
              <Link to="/" style={{textDecoration :'none',fontSize:'16px',fontWeight:500}} class={`ld_menu_container ${this.props.name== "Dashboard" && "ld_menu_container_selected"}`}>
                <div class="ld_image_div_container">
                  <img src={require('../Images/LeftDrawer Icon/dashboard-icon-active (1).svg')} style={{marginRight:'20px'}}/>
                </div>
                <div clas="ld_menu_name">Dashboard</div>
              </Link>
              <Link to="/Notification" style={{textDecoration :'none',fontSize:'16px',fontWeight:500}} class={`ld_menu_container ${this.props.name== "My Notifications" && "ld_menu_container_selected"}`}>
                <div class="ld_image_div_container">
                  <img src={NotificationIcon} style={{marginRight:'20px'}}/>
                </div>
                <div clas="ld_menu_name">My Notifications</div>
              </Link>

              <Link to="/AnnouncementNotification" style={{textDecoration :'none',fontSize:'16px',fontWeight:500}} class={`ld_menu_container ${this.props.name== "My Notifications" && "ld_menu_container_selected"}`}>
                <div class="ld_image_div_container">
                  <img src={require('../Images/LeftDrawer Icon/notices-icon-active.svg')} style={{marginRight:'20px'}}/>
                </div>
                <div clas="ld_menu_name">Announcements & Notices</div>
              </Link>
              
              {
                this.state.dashboardCards && this.state.dashboardCards.map(card => (
                  <>
                    {(() => {
                      switch (card.cardtitle) {
                        case 'Documents' :
                          return(
                            <>
                            <div to="/" style={{ textDecoration: 'none', fontSize: '16px', fontWeight: 500 }}
                                 className={`ld_menu_container ${this.props.name == "Documents" && "ld_menu_container_selected"}`}
                                 onClick={() => this.opendocuments()}>
                              <div className="ld_image_div_container">
                                <img src={require('../Images/LeftDrawer Icon/documents-icon-active.svg')}
                                     style={{ marginRight: '20px' }}/>
                              </div>
                              <div clas="ld_menu_name">Documents</div>
                            </div>
                              <div style={{ textDecoration: 'none', fontSize: '16px', fontWeight: 500 }}
                                   onClick={() => this.opentelemed()}
                                   className={`ld_menu_container ${this.props.name == "Telemed" && "ld_menu_container_selected"}`}>
                                <div className="ld_image_div_container">
                                  <img src={require('../Images/LeftDrawer Icon/telemed-icon-active.svg')}
                                       style={{ marginRight: '20px' }}/>
                                </div>
                                <div clas="ld_menu_name">Telemed</div>
                              </div>
                            </>
                          )

                        case 'FindProvider' :
                          return (
                            <div style={{ textDecoration: 'none', fontSize: '16px', fontWeight: 500 }}
                                 className={`ld_menu_container ${this.props.name == "Find a Provider" && "ld_menu_container_selected"}`}
                                 onClick={() => this.openprovider(card.enable)}>
                              <div className="ld_image_div_container">
                                <img src={require('../Images/LeftDrawer Icon/find-a-provider-icon-active.svg')}
                                     style={{ marginRight: '20px' }}/>
                              </div>
                              <div clas="ld_menu_name">Find a Provider</div>
                            </div>
                          )

                        case 'MembershipId':
                          return (
                            <div style={{ textDecoration: 'none', fontSize: '16px', fontWeight: 500 }}
                                 className={`ld_menu_container ${this.props.name == "Membership Id" && "ld_menu_container_selected"}`}
                                 onClick={() => this.closeparentdrawer(card.enable)}>
                              <div className="ld_image_div_container">
                                <img src={require('../Images/LeftDrawer Icon/membership-card-icon-active.svg')}
                                     style={{ marginRight: '20px' }}/>
                              </div>
                              <div clas="ld_menu_name"> Membership Card</div>
                            </div>
                          )

                        case 'ProgramInformation' :
                          return (
                            card.enable ?
                            <Link to="/ProgramInformation"  style={{textDecoration :'none',fontSize:'16px',fontWeight:500}}
                                  class={`ld_menu_container ${this.props.name == "Program Information" && "ld_menu_container_selected"}`}>
                              <div class="ld_image_div_container">
                                <img src={require('../Images/LeftDrawer Icon/program-info-icon-active.svg')} style={{marginRight:'20px'}}/>
                              </div>
                              <div clas="ld_menu_name">Program Information</div>
                            </Link>
                              :
                              <div style={{ textDecoration: 'none', fontSize: '16px', fontWeight: 500 }}
                                   className={`ld_menu_container ${this.props.name == "Program Information" && "ld_menu_container_selected"}`}
                                   onClick={() => this.props.openTempModal()}>
                                <div className="ld_image_div_container">
                                  <img src={require('../Images/LeftDrawer Icon/program-info-icon-active.svg')}
                                       style={{ marginRight: '20px' }}/>
                                </div>
                                <div clas="ld_menu_name">Program Information</div>
                              </div>
                          )

                        case 'MyNeeds' :
                          return (
                            card.enable ?
                            <Link to="/MyNeeds"  style={{textDecoration :'none',fontSize:'16px',fontWeight:500}} class={`ld_menu_container ${this.props.name== "MyNeeds" && "ld_menu_container_selected"}`}>
                              <div class="ld_image_div_container">
                                <img src={require('../Images/LeftDrawer Icon/my-needs-icon-active.svg')} style={{marginRight:'20px'}}/>
                              </div>
                              <div clas="ld_menu_name">My Needs</div>
                            </Link>
                              :
                              <div style={{ textDecoration: 'none', fontSize: '16px', fontWeight: 500 }}
                                   className={`ld_menu_container ${this.props.name == "MyNeeds" && "ld_menu_container_selected"}`}
                                   onClick={() => this.props.openTempModal()}>
                                <div className="ld_image_div_container">
                                  <img src={require('../Images/LeftDrawer Icon/my-needs-icon-active.svg')}
                                       style={{ marginRight: '20px' }}/>
                                </div>
                                <div clas="ld_menu_name">My Needs</div>
                              </div>
                          )

                        case 'MyTransaction' :
                          return (
                            card.enable ?
                            <Link to="/Transaction"  style={{textDecoration :'none',fontSize:'16px',fontWeight:500}} class={`ld_menu_container ${this.props.name== "MyTransactions" && "ld_menu_container_selected"}`}>
                              <div class="ld_image_div_container">
                                <img src={require('../Images/LeftDrawer Icon/my_transactions_icon_active (1).svg')} style={{marginRight:'20px'}}/>
                              </div>
                              <div clas="ld_menu_name">My Transactions</div>
                            </Link>
                              :
                              <div style={{ textDecoration: 'none', fontSize: '16px', fontWeight: 500 }}
                                   className={`ld_menu_container ${this.props.name == "MyTransactions" && "ld_menu_container_selected"}`}
                                   onClick={() => this.props.openTempModal()}>
                                <div className="ld_image_div_container">
                                  <img src={require('../Images/LeftDrawer Icon/my_transactions_icon_active (1).svg')}
                                       style={{ marginRight: '20px' }}/>
                                </div>
                                <div clas="ld_menu_name">My Transactions</div>
                              </div>
                          )
							
							
							case 'Paymentwallet' :
                          return (
                            card.enable ?
                            <Link to="/PaymentWallet"  style={{textDecoration :'none',fontSize:'16px',fontWeight:500}} class={`ld_menu_container ${this.props.name== "MyTransactions" && "ld_menu_container_selected"}`}>
                              <div class="ld_image_div_container">
                                <img src={require('../Images/LeftDrawer Icon/payment_wallet_icon_active.svg')} style={{marginRight:'20px'}}/>
                              </div>
                              <div clas="ld_menu_name">My Payment Wallet</div>
                            </Link>
                              :
                              <div style={{ textDecoration: 'none', fontSize: '16px', fontWeight: 500 }}
                                   className={`ld_menu_container ${this.props.name == "Paymentwallet" && "ld_menu_container_selected"}`}
                                   onClick={() => this.props.openTempModal()}>
                                <div className="ld_image_div_container">
                                  <img src={require('../Images/LeftDrawer Icon/payment_wallet_icon_active.svg')}
                                       style={{ marginRight: '20px' }}/>
                                </div>
                                <div clas="ld_menu_name">My Payment Wallet</div>
                              </div>
                          )
                        case 'HealthQuestionnaire' :
                          return (
                            card.enable ?
                            <Link to="/Medical"  style={{textDecoration :'none',fontSize:'16px',fontWeight:500}}
                                  class={`ld_menu_container ${this.props.name== "Medical Questionnaire" && "ld_menu_container_selected"}`}>
                              <div class="ld_image_div_container">
                                <img src={require('../Images/LeftDrawer Icon/medical-q-icon-active.svg')} style={{marginRight:'20px'}}/>
                              </div>
                              <div clas="ld_menu_name">Health Questionnaire</div>
                            </Link>
                              :
                              <div style={{ textDecoration: 'none', fontSize: '16px', fontWeight: 500 }}
                                   className={`ld_menu_container ${this.props.name == "Medical Questionnaire" && "ld_menu_container_selected"}`}
                                   onClick={() => this.props.openTempModal()}>
                                <div className="ld_image_div_container">
                                  <img src={require('../Images/LeftDrawer Icon/medical-q-icon-active.svg')}
                                       style={{ marginRight: '20px' }}/>
                                </div>
                                <div clas="ld_menu_name">Health Questionnaire</div>
                              </div>
                          )

                        // case 'Notices' :
                        //   return (
                        //     <div style={{ textDecoration: 'none', fontSize: '16px', fontWeight: 500 }}
                        //          className={`ld_menu_container ${this.props.name == "Notices" && "ld_menu_container_selected"}`}
                        //          onClick={() => this.opennotices(card.enable)}>
                        //       <div className="ld_image_div_container">
                        //         <img src={require('../Images/LeftDrawer Icon/notices-icon-active.svg')}
                        //              style={{ marginRight: '20px' }}/>
                        //       </div>
                        //       <div clas="ld_menu_name">Notices</div>
                        //     </div>
                        //   )

                        case 'ContactInformation' :
                          return (
                            <div style={{ textDecoration: 'none', fontSize: '16px', fontWeight: 500 }}
                                 className={`ld_menu_container ${this.props.name == "Contact Information" && "ld_menu_container_selected"}`}
                                 onClick={() => this.opencontacts()}
                            >
                              <div className="ld_image_div_container">
                                <img src={require('../Images/LeftDrawer Icon/contact-info-icon-active.svg')}
                                     style={{ marginRight: '20px' }}/>
                              </div>
                              <div clas="ld_menu_name">Contact Information</div>
                            </div>
                          )

                        case 'FAQs' :
                          return (
                            <div style={{ textDecoration: 'none', fontSize: '16px', fontWeight: 500 }}
                                 className={`ld_menu_container ${this.props.name == "FAQs" && "ld_menu_container_selected"}`}
                                 onClick={() => this.openfaqs(card.enable)}>
                              <div className="ld_image_div_container">
                                <img src={require('../Images/LeftDrawer Icon/FAQs icon (active).svg')}
                                     style={{ marginRight: '20px' }}/>
                              </div>
                              <div clas="ld_menu_name">FAQs</div>
                            </div>
                          )

                      }
                    })()}
                  </>
                ))
              }

                <div   style={{textDecoration :'none',fontSize:'16px',fontWeight:500}} class={`ld_menu_container ${this.props.name== "Sign Out" && "ld_menu_container_selected"}`} onClick={this.handleSignout}>
                <div class="ld_image_div_container">
                    <img src={require('../Images/LeftDrawer Icon/logout-icon-active.svg')} style={{marginRight:'20px'}}/>
                    </div>
                    <div clas="ld_menu_name">Sign Out</div>
                </div>




            </div>
        )
    }
}
