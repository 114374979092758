import React, { Component } from 'react';
import {Form} from "react-bootstrap";
import SendIcon from '@material-ui/icons/Send';
import Draggable from 'react-draggable';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import './ChatBox.css'
import ReadMoreReact from 'read-more-react';
import { Link } from 'react-router-dom'
import { Fab, Hidden } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Button from '@material-ui/core/Button';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import axios from 'axios';
import {ChatInput} from './ConstantChat';

class ChatWindow extends Component {
    constructor(props) {
        super(props)
        this.state = {
          chat:[],
          message:'',
          chats:[],
          fullScreen:[],
          loader:false,
          dismissable:true,
        }
        this.mesRef = React.createRef();
    }
    componentDidMount(){
        console.log(ChatInput)
        const messages =[
            {
                answer:'Hi, I’m Caryn',
                context:`How can I help you today, ${this.props.username}?`,
                type:true,
                from:"incoming",
            },
       
           
        ];
        if(sessionStorage.getItem('chathistory')){
            var messageHistory = JSON.parse(sessionStorage.getItem('chathistory'));
            this.setState({chats: messageHistory},() => { this.scrollToBottom(); });

        }else{
            this.setState({chats: messages},() => {  this.scrollToBottom(); });
        }
       
    }
    
    render(){
        return(
            <div>
                 <div class="web_Chat_container">
                    <div> 
                        <img src={require('../../Images/HomeIcon/welcome-back.svg')} class="web_welcome_back" />
                        <div class="web_top_username">{this.props.username}!</div>
                    </div>


                    <div ref={this.mesRef} style={{maxHeight:300 ,width:760}} id="chatbody" class="chatbody">
                    {
                         this.state.chats.map((message, i)=>( 
                                <div class={message.context? "incoming":"outgoing"} style={{cursor:'context-menu'}}>     
                                   
                                   {message.options &&
                                       <div style={{ display:'flex', flexWrap:'wrap'}}><br/>
                                            {message.options.map((intent)=>
                                            ( 
                                                <div class="intentbubble" onClick={()=>this.navigate(intent.intent)} style={{backgroundColor:'#54337A', color:'#fff', marginRight:5,cursor:'pointer', display:'flex', flexDirection:'row', alignItems:'center'}}>
                                                    <p>{intent.message}</p> <ChevronRightIcon style={{ fontSize: 25 }}/>
                                                </div>
                                            ))}
                                        </div>}
                                   
                                   
                                    <div class="bubble" style={{whiteSpace:'pre-line'}}>
                                      <div style={{display:'flex', flexDirection:'row'}}>
                                            <div>
                                                <p className="searchfont">{this.state.fullScreen[i]? message.message ? message.message : message.context :<ReadMoreReact text={message.message ? message.message : message.context} ideal={80} readMoreText={"..."}/>}</p>
                                                {
                                                this.state.fullScreen[i] && message.meta.url !== null && 
                                                <div><br/>
                                                <Button variant="contained" style={{backgroundColor:'#543379', color:'#fff'}} onClick={()=>window.open(message.meta.url)}>
                                                    Read More 
                                                    <ChevronRightIcon style={{ fontSize: 25 }}/>
                                                </Button> 
                                                </div>}
                                            </div>
                                            <div>{!message.type && message.context && <div>{this.state.fullScreen[i]?<KeyboardArrowUpIcon onClick={()=>this.fullScreen(i)} style={{cursor: 'pointer'}}/>:<KeyboardArrowDownIcon onClick={()=>this.fullScreen(i)} style={{cursor: 'pointer'}}/>}</div>}</div>
                                       </div>
                                    </div>
                                   
                            </div>))
                    }
                            {this.state.loader && <div class="spinner" style={{paddingBottom:10}}>
                                <div class="bounce1"></div>
                                <div class="bounce2"></div>
                                <div class="bounce3"></div>
                            </div>}
                    </div>
                    <form onSubmit={this.sendMessage}> <div style={{flexDirection:'row', display:'flex', position:'absolute', marginTop:13}}>
                        <div style={{width:760}}> <Form.Control placeholder="Type in your question to Caryn..." value={this.state.message} style={stylesheet.searchInputBox} onChange={e=>this.setState({message: e.target.value})}/> </div> 
                            <div className="search-icon">
                                <Fab  style={{backgroundColor:"#543379", width:37, height:37}} type="submit" value="Submit" onClick={()=>this.sendMessage(true)}>
                                   <img src={require('../../../assets/images/carynIcon.jpg')} width="120"/>
                                </Fab>
                            </div>
                        </div>  
                    </form>
                </div>

            </div>

        )
    }
    fullScreen=(i)=>{
        if(this.state.fullScreen[i]){
            var fullScreen = this.state.fullScreen;
            fullScreen[i]=false;
            this.setState({fullScreen: fullScreen});
        }
        else{
            var fullScreen = this.state.fullScreen;
            fullScreen[i]=true;
            this.setState({fullScreen: fullScreen});
        }
        this.scrollToBottom();
    }
    navigate=(intent)=>{
     this.props.navigate(intent);
    }
    sendMessage=(e)=>{        
        if(e!==true)
        e.preventDefault();
        if(this.state.message !== ''){
            var messages={
                text: this.state.message,
                userID: localStorage.getItem('Member_EMPID')
              }
              var message={
                message:this.state.message,
                from:'outgoing'
            }
            this.setState({chats: this.state.chats.concat(message), message:'', loader:true},
                () => {
                    this.fullScreen(this.state.chats.length)
                    this.scrollToBottom();
                    axios.post('https://1dmevvy65l.execute-api.us-east-1.amazonaws.com/dev/lexbot', messages,{ headers: { 'Content-Type': 'application/json','Accept': 'application/json',  'Connection':"keep-alive"}})
                    .then(res=>{
                        this.recieveMessage(res.data.AIKBtext, res.data.intentRecognized, res.data.query)
                      
                        // this.splitMessage(res.data.message, res.data.intentRecognized)
                       
                    })
                    sessionStorage.setItem('chathistory', JSON.stringify(this.state.chats));
                })             
        }
    }
  
    recieveMessage = (message, intent, query) => {
        var messageAskCarynJumpto =[{
            context:"I’ve found other relevant results to your query in related documents.",
            meta:{
                url:`https://inf-labs.com/?isnav=true&memberId=${localStorage.getItem('Member_EMPID')}&query=${query}`
            }

        }]
        this.setState({
            // chats: this.state.chats.concat(message), 
            chats: this.state.chats.concat(messageAskCarynJumpto),
            loader:false},
        () => {
            this.scrollToBottom();
            sessionStorage.setItem('chathistory', JSON.stringify(this.state.chats));
            ChatInput.map((res)=>{
                if(res.query===intent){
                    var chats =this.state.chats;
                    chats[chats.length-1].options=res.options;
                    this.setState({chats: chats},
                        () => {
                            this.scrollToBottom();
                            sessionStorage.setItem('chathistory', JSON.stringify(this.state.chats));
                        });
                    console.log(res.options)
                }
            })
        })
       
       
    }
 
    scrollToBottom = () => {
        this.mesRef.current.scrollTop = this.mesRef.current.scrollHeight;
    }
}
export default ChatWindow;
const stylesheet={
    searchInputBox:{
        borderRadius:40, 
        borderColor:'#420045', 
        borderWidth:'revert',
        borderRadius:40, 
        paddingRight:40, 
        paddingLeft:20
    }
  }

// const ChatInput = [
//     {
//         "query": "What is UHS ?",
//         "no_ans_gap": 0.08761119842529297,
//         "answers": [
//             {
//                 "answer": "Universal HealthShare",
//                 "score": 7.390358924865723,
//                 "probability": 0.20374229550361633,
//                 "context": "Unsharable Expenses means expenses that either are of a type that are generally ineligible for sharing.",
//                 "intent": "",
//                 "offset_start": 280,
//                 "offset_end": 301,
//                 "offset_start_in_doc": 280,
//                 "offset_end_in_doc": 301,
//                 "document_id": "3565f19e07b9a844569751e8e707b246",
//                 "meta": {
//                     "name": "UHS Sharing Program Guidelines - UHFR040721E040121.pdf",
//                     "pageno": "50",
//                     "_split_id": "5",
//                     "vector_id": "1589",
//                     "url": "https://digital-concierge-storage.s3.amazonaws.com/UHS%20Sharing%20Program%20Guidelines%20-%20UHFR040721E040121.pdf?response-content-disposition=inline&response-content-type=application%2Fpdf&AWSAccessKeyId=AKIAW5MZIU5AZ7JBHOFH&Signature=mU1tAbv%2BNcSKvQHYzEnFZ3WczMM%3D&Expires=1627196411#page=50"
//                 }
//             },
//             {
//                 "answer": "Universal HealthShare",
//                 "score": 7.390358924865723,
//                 "probability": 0.20374229550361633,
//                 "context": "Unsharable Expenses means expenses that either are of a type that are generally ineligible for sharing, or that arise from or in connection with a condition that is not eligible for sharing. Usual and Customary means eligible expenses identified by the Medical Expense Auditor or Universal HealthShare to be usual and customary for the service or supply in question, taking into consideration the fee(s) which the Provider most frequently charges and/or accepts as payment for the service or supply from the majority of its patients, the cost to the Provider for providing the services, the prevailing range of fees charged and/or accepted for the service or supply by Providers of similar training and experience in the same geographic locale or area, and the Medicare reimbursement rates for the service or supply.",
//                 "intent": "",
//                 "offset_start": 280,
//                 "offset_end": 301,
//                 "offset_start_in_doc": 280,
//                 "offset_end_in_doc": 301,
//                 "document_id": "3565f19e07b9a844569751e8e707b246",
//                 "meta": {
//                     "name": "UHS Sharing Program Guidelines - UHFR040721E040121.pdf",
//                     "pageno": "50",
//                     "_split_id": "5",
//                     "vector_id": "1589",
//                     "url": "https://digital-concierge-storage.s3.amazonaws.com/UHS%20Sharing%20Program%20Guidelines%20-%20UHFR040721E040121.pdf?response-content-disposition=inline&response-content-type=application%2Fpdf&AWSAccessKeyId=AKIAW5MZIU5AZ7JBHOFH&Signature=mU1tAbv%2BNcSKvQHYzEnFZ3WczMM%3D&Expires=1627196411#page=50"
//                 }
//             },
//             {
//                 "answer": "Which Id card are you looking for?",
//                 "score": 7.390358924865723,
//                 "probability": 0.20374229550361633,
//                 "context": "Which Id card are you looking for?",
//                 "options": [{ message: 'Show me my Membership ID', intent: 'Membership ID' }, { message: "Request a HealthTool Card", intent: 'Payment Card' }],
//                 "offset_start": 280,
//                 "offset_end": 301,
//                 "intent": "",
//                 "offset_start_in_doc": 280,
//                 "offset_end_in_doc": 301,
//                 "document_id": "3565f19e07b9a844569751e8e707b246",
//                 "meta": {
//                     "name": "UHS Sharing Program Guidelines - UHFR040721E040121.pdf",
//                     "pageno": "50",
//                     "_split_id": "5",
//                     "vector_id": "1589",
//                     "url": "https://digital-concierge-storage.s3.amazonaws.com/UHS%20Sharing%20Program%20Guidelines%20-%20UHFR040721E040121.pdf?response-content-disposition=inline&response-content-type=application%2Fpdf&AWSAccessKeyId=AKIAW5MZIU5AZ7JBHOFH&Signature=mU1tAbv%2BNcSKvQHYzEnFZ3WczMM%3D&Expires=1627196411#page=50"
//                 }
//             }
//         ],
//         "node_id": "Reader"
//     },
//     {
//         "query": "Show me my ID Card ?",
//         "no_ans_gap": 0.08761119842529297,
//         "answers": [
//             {
//                 "answer": "Which Id card are you looking for?",
//                 "score": 7.390358924865723,
//                 "probability": 0.20374229550361633,
//                 "context": "Which Id card are you looking for?",
//                 "options": [{ message: 'Show me my Membership ID', intent: 'Membership ID' }, { message: "Request a HealthTool Card", intent: 'Payment Card' }],
//                 "offset_start": 280,
//                 "offset_end": 301,
//                 "intent": "",
//                 "offset_start_in_doc": 280,
//                 "offset_end_in_doc": 301,
//                 "document_id": "3565f19e07b9a844569751e8e707b246",
//                 "meta": {
//                     "name": "UHS Sharing Program Guidelines - UHFR040721E040121.pdf",
//                     "pageno": "50",
//                     "_split_id": "5",
//                     "vector_id": "1589",
//                     "url": "https://digital-concierge-storage.s3.amazonaws.com/UHS%20Sharing%20Program%20Guidelines%20-%20UHFR040721E040121.pdf?response-content-disposition=inline&response-content-type=application%2Fpdf&AWSAccessKeyId=AKIAW5MZIU5AZ7JBHOFH&Signature=mU1tAbv%2BNcSKvQHYzEnFZ3WczMM%3D&Expires=1627196411#page=50"
//                 }
//             }
//         ],
//         "node_id": "Reader"
//     },
//     {
//         "query": "Show me My Needs?",
//         "no_ans_gap": 0.08761119842529297,
//         "answers": [
//             {
//                 "answer": "Which Id card are you looking for?",
//                 "score": 7.390358924865723,
//                 "probability": 0.20374229550361633,
//                 "context": "Tap below to know about your Needs",
//                 "options": [{ message: 'Show me my Needs', intent: 'MyNeeds' },],
//                 "offset_start": 280,
//                 "offset_end": 301,
//                 "intent": "",
//                 "offset_start_in_doc": 280,
//                 "offset_end_in_doc": 301,
//                 "document_id": "3565f19e07b9a844569751e8e707b246",
//                 "meta": {
//                     "name": "UHS Sharing Program Guidelines - UHFR040721E040121.pdf",
//                     "pageno": "50",
//                     "_split_id": "5",
//                     "vector_id": "1589",
//                     "url": "https://digital-concierge-storage.s3.amazonaws.com/UHS%20Sharing%20Program%20Guidelines%20-%20UHFR040721E040121.pdf?response-content-disposition=inline&response-content-type=application%2Fpdf&AWSAccessKeyId=AKIAW5MZIU5AZ7JBHOFH&Signature=mU1tAbv%2BNcSKvQHYzEnFZ3WczMM%3D&Expires=1627196411#page=50"
//                 }
//             }
//         ],
//         "node_id": "Reader"
//     },
//     {
//         "query": "Show me My Program Information?",
//         "no_ans_gap": 0.08761119842529297,
//         "answers": [
//             {
//                 "answer": "Which Id card are you looking for?",
//                 "score": 7.390358924865723,
//                 "probability": 0.20374229550361633,
//                 "context": "Tap below to know about your Program Information",
//                 "options": [{ message: 'Show me my Program Information', intent: 'ProgramInformation' },],
//                 "offset_start": 280,
//                 "offset_end": 301,
//                 "intent": "",
//                 "offset_start_in_doc": 280,
//                 "offset_end_in_doc": 301,
//                 "document_id": "3565f19e07b9a844569751e8e707b246",
//                 "meta": {
//                     "name": "UHS Sharing Program Guidelines - UHFR040721E040121.pdf",
//                     "pageno": "50",
//                     "_split_id": "5",
//                     "vector_id": "1589",
//                     "url": "https://digital-concierge-storage.s3.amazonaws.com/UHS%20Sharing%20Program%20Guidelines%20-%20UHFR040721E040121.pdf?response-content-disposition=inline&response-content-type=application%2Fpdf&AWSAccessKeyId=AKIAW5MZIU5AZ7JBHOFH&Signature=mU1tAbv%2BNcSKvQHYzEnFZ3WczMM%3D&Expires=1627196411#page=50"
//                 }
//             }
//         ],
//         "node_id": "Reader"
//     },
//     {
//         "query": "Show me My Transaction?",
//         "no_ans_gap": 0.08761119842529297,
//         "answers": [
//             {
//                 "answer": "Which Id card are you looking for?",
//                 "score": 7.390358924865723,
//                 "probability": 0.20374229550361633,
//                 "context": "Tap below to know about your Transaction",
//                 "options": [{ message: 'Show me my Transaction', intent: 'Transaction' },],
//                 "offset_start": 280,
//                 "offset_end": 301,
//                 "intent": "",
//                 "offset_start_in_doc": 280,
//                 "offset_end_in_doc": 301,
//                 "document_id": "3565f19e07b9a844569751e8e707b246",
//                 "meta": {
//                     "name": "UHS Sharing Program Guidelines - UHFR040721E040121.pdf",
//                     "pageno": "50",
//                     "_split_id": "5",
//                     "vector_id": "1589",
//                     "url": "https://digital-concierge-storage.s3.amazonaws.com/UHS%20Sharing%20Program%20Guidelines%20-%20UHFR040721E040121.pdf?response-content-disposition=inline&response-content-type=application%2Fpdf&AWSAccessKeyId=AKIAW5MZIU5AZ7JBHOFH&Signature=mU1tAbv%2BNcSKvQHYzEnFZ3WczMM%3D&Expires=1627196411#page=50"
//                 }
//             }
//         ],
//         "node_id": "Reader"
//     },
//     {
//         "query": "Show me My health questionnaire?",
//         "no_ans_gap": 0.08761119842529297,
//         "answers": [
//             {
//                 "answer": "Which Id card are you looking for?",
//                 "score": 7.390358924865723,
//                 "probability": 0.20374229550361633,
//                 "context": "Tap below to know about your health questionnaire",
//                 "options": [{ message: 'Show me my health questionnaire', intent: 'Medical' },],
//                 "offset_start": 280,
//                 "offset_end": 301,
//                 "intent": "",
//                 "offset_start_in_doc": 280,
//                 "offset_end_in_doc": 301,
//                 "document_id": "3565f19e07b9a844569751e8e707b246",
//                 "meta": {
//                     "name": "UHS Sharing Program Guidelines - UHFR040721E040121.pdf",
//                     "pageno": "50",
//                     "_split_id": "5",
//                     "vector_id": "1589",
//                     "url": "https://digital-concierge-storage.s3.amazonaws.com/UHS%20Sharing%20Program%20Guidelines%20-%20UHFR040721E040121.pdf?response-content-disposition=inline&response-content-type=application%2Fpdf&AWSAccessKeyId=AKIAW5MZIU5AZ7JBHOFH&Signature=mU1tAbv%2BNcSKvQHYzEnFZ3WczMM%3D&Expires=1627196411#page=50"
//                 }
//             }
//         ],
//         "node_id": "Reader"
//     },
// ]