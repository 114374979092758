import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { views } from '../CheckRegistration'
import CheckRegistrationForm from '../checkregistration/CheckRegistration';
import { UserConfirmedContent } from '../checkregistration/UserConfirmed';
import { NotFoundContent } from '../checkregistration/UserNotFound';
import { ChangePwdContent } from '../checkregistration/UserForceChangePwd';

export default function AlertDialog(props) {




  const handleClose = () => {
    // setOpen(false);
    props.closeAlert()
  };

  const getView = () => {
    const { currentView } = props;
    console.log(currentView)
    switch (currentView) {

      case views.Confirmed:
        return (
          <UserConfirmedContent gotoResetPassword={props.gotoResetPassword} handleClose={handleClose} />
        );
      case views.NotFound:
        return (
          <NotFoundContent email={props.email} handleClose={handleClose} />
          // <ChangePwdContent />
        );
      case views.ForceChangePassword:
        return (
          <ChangePwdContent resendEmail={props.resendEmail} emailStatus={props.emailStatus} handleClose={handleClose} />
        );
    }
  }


  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"

      >

        <DialogContent >
          {getView()}
        </DialogContent>
        {/* <DialogActions>

          <button onClick={handleClose} className="a-btn" style={{ width: 'auto', minWidth: '50px', height: '30px', margin: '0 20px 15px 0' }}>
            Close
                </button>

        </DialogActions> */}
      </Dialog>
    </div>
  );
}
