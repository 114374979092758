import React, { Component } from 'react'
// import '../Card/WebCardCSS/card.css';
import './Card.css'
import { Container, Row, Col, ProgressBar, Table } from 'react-bootstrap'
export default class BackView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      channel: 'Smartshare'
    }
    console.log("BACK VIEW PROPS====",this.props)
  }
  render() {
    return (
      <div class="web_bv">
        {/* <div class="bv_top_image_container">
                       <img src={require('../../Images/Card/thank-you-for.png')} class="web_bv_thank_image"/>
                       <img src={require('../../Images/Card/sharing.png')} class="web_bv_sharing_image"/>
                   </div> */}

        <div class="web_bv_details_text_div">
          {/* ========================Top Blue container 1st  =================== */}

          
          {(() => {
            switch (this.props.network) {
              case 'PHCS':
                return (
                  <div>
                    <div class="web_bv_bluetext_common">
                      For Preventive Services Appointments visit PreventiveServices.UniversalHealthFellowship.org
                    </div>
                    <div class="web_bv_bluetext_common">For Customer Service call: {this.props.contactNumber}</div>
                    <div class="web_bv_bluetext_common">To find a provider visit FindProvider.UniversalHealthFellowship.org</div>
                  </div>
                )

              case 'Smartshare':
                return (
                  <div>
                    <div class="web_bv_bluetext_common">
                      For Preventive Services Appointments visit PreventiveServices.UniversalHealthFellowship.org
                    </div>
                    <div class="web_bv_bluetext_common">To find a provider visit FindProvider.UniversalHealthFellowship.org</div>
                    <div class="web_bv_bluetext_common">Providers, send needs requests to: P.O Box 211223, Eagan, MN 55121</div>
                    <div class="web_bv_bluetext_common">For Customer Service call: {this.props.contactNumber}</div>
                  </div>
                )

              case 'AFMC':
                return (
                  <div>
                    <div class="web_bv_bluetext_common">
                      For Preventive Services Appointments visit PreventiveServices.UniversalHealthFellowship.org
                    </div>
                    <div class="web_bv_bluetext_common">For Customer Service call: {this.props.contactNumber}</div>

                    <div class="AFA_3rdtext">
                      <div class="web_bv_bluetext_common">To find a provider visit https://azfmc.com/providersearch</div>
                      <div class="AFA_id_no_div">AFMC EDI #86062</div>
                    </div>
                  </div>
                )
            }
          })()}

          {/* ========================Top Blue container 2st  =================== */}
          {(() => {
            switch (this.props.network) {
              case 'PHCS':
                return (
                  <div class="web_bv_second_container">
                    <div class="web_bv_bluetext_common">Providers, send needs to: PO Box 211223, Eagon, MN 55121</div>
                    <div class="web_bv_blacktext_common margintop_small">
                      Pre-notification is required before these procedures will be eligible for sharing: All Inpatient Hospital
                      Confinements, All Surgical Procedures (Inpatient, Outpatient and Ambulatory, Organ and Tissue Transplant Services)
                      Cancer Treatment and Oncology Services, Independent Lab Tests and Imaging, Home Health Care Services, Carpal Tunnel
                      Treatments. In addition, Pre-certification to confirm medical necessity is required before these procedures may be
                      eligible for sharing: Transplant of any organ or tissue, a coronary bypass or graft of any kind, or a knee or hip
                      replacement.<span class="bv_black_bold_text"> For Medical Emergencies Seek Immediate Medical Help.</span>
                    </div>

                    <div class="web_bv_blacktext_common margintop_small">
                      Participating member assignment of eligible medical expense sharing payment is permitted as consideration in full for
                      services rendered. Reimbursement for hospital facility services is determined at a percentage of the facility’s
                      Medicare allowable amounts (140% for Inpatient and 155% for Outpatient Services), or, in the absence of an applicable
                      CMS fee schedule, in accordance with published UHF Sharing Guidelines. Acceptance of sharing payment for Eligible
                      Expenses constitutes waiver of facility/provider right to balance bill patient.
                    </div>

                    <div class="web_bv_blacktext_common margintop_small">
                      See applicable Sharing Guidelines for more details.{' '}
                      <span class="bv_black_bold_text">Universal Health Fellowship (www.UniversalHealthFellowship.org) </span> is a
                      not-for-profit ministry that facilitates medical expense sharing through Universal HealthShare Programs. Universal
                      HealthShare is not insurance and does not guarantee that eligible medical bills will be shared or otherwise paid.
                      Universal HealthShare is not a discount card or program.
                    </div>
                  </div>
                )

              case 'Smartshare':
                return (
                  <div class="web_bv_second_container">
                    <div class="uhs_balck_text_design">
                      All medical services for the UHS Smartshare program are limited to an annual maximum of $27,500 per member
                    </div>
                    <div class="web_bv_blacktext_common margintop_small">
                      Member assignment to Providers of eligible medical expense sharing reimbursement as consideration for services
                      rendered is permitted by Universal Health Fellowship (UHF). Sharing reimbursement for eligible hospital or ambulatory
                      surgical center services/expenses is determined at a percentage of the facility’s Medicare allowable amounts (140% for
                      Inpatient and 155% for Outpatient Services), or, absent an applicable CMS fee schedule, in accordance with current
                      published UHF Sharing Guidelines. See applicable Sharing Guidelines for details.
                    </div>


                    <div class="web_bv_blacktext_common margintop_small">
                    See applicable Sharing Guidelines for details.{' '}
                      <span class="bv_black_bold_text">Universal Health Fellowship </span> 
                     is a not-for-profit ministry that
facilitates medical expense sharing through Universal HealthShare Programs. Universal HealthShare is not
insurance and does not guarantee that eligible medical bills will be shared or otherwise paid. Universal
HealthShare is not a discount card or program.
                    </div>
                  </div>
                )

              case 'AFMC':
                return (
                  <div class="web_bv_second_container">
                    <div class="web_bv_bluetext_common">
                      Providers, send needs requests to: Arizona Foundation, PO Box 2909, Phoenix, AZ 86062-2909
                    </div>
                    <div class="web_bv_blacktext_common margintop_small">
                      Pre-notification is required before these procedures will be eligible for sharing: All Inpatient Hospital
                      Confinements, All Surgical Procedures (Inpatient, Outpatient and Ambulatory, Organ and Tissue Transplant Services)
                      Cancer Treatment and Oncology Services, Independent Lab Tests and Imaging, Home Health Care Services, Carpal Tunnel
                      Treatments. In addition, Pre-certification to confirm medical necessity is required before these procedures may be
                      eligible for sharing: Transplant of any organ or tissue, a coronary bypass or graft of any kind, or a knee or hip
                      replacement.<span class="bv_black_bold_text"> For Medical Emergencies Seek Immediate Medical Help.</span>
                    </div>

                    <div class="web_bv_blacktext_common margintop_small">
                      Participating member assignment of eligible medical expense sharing payment is permitted as consideration in full for
                      services rendered. Reimbursement for hospital facility services is determined at a percentage of the facility’s
                      Medicare allowable amounts (140% for Inpatient and 155% for Outpatient Services), or, in the absence of an applicable
                      CMS fee schedule, in accordance with published UHF Sharing Guidelines. Acceptance of sharing payment for Eligible
                      Expenses constitutes waiver of facility/provider right to balance bill patient.
                    </div>

                    <div class="web_bv_blacktext_common margintop_small">
                      See applicable Sharing Guidelines for more details.{' '}
                      <span class="bv_black_bold_text">Universal Health Fellowship (www.UniversalHealthFellowship.org) </span> is a
                      not-for-profit ministry that facilitates medical expense sharing through Universal HealthShare Programs. Universal
                      HealthShare is not insurance and does not guarantee that eligible medical bills will be shared or otherwise paid.
                      Universal HealthShare is not a discount card or program. <span class="AFA_bottom_id">AFMC EDI #86062</span>
                    </div>
                  </div>
                )
            }
          })()}

{
  this.props.network == 'Smartshare'
   ?<div class="smaratshare_image_container">
   <div class="bv_top_image_container">
     <img src={require('../../Images/Card/thank-you-for.png')} class="smartshare_web_bv_thank_image" />
     <img src={require('../../Images/Card/sharing.png')} class="smartshare_web_bv_sharing_image " />
   </div>
 </div>

   : null
}
</div>    

           

        {this.props.network == 'PHCS' || this.props.network == 'AFMC' ? (
          <div class="web_bv_card_no_bottom fixed-bottom">
            <div class="web_bv_link_url">www.UniversalHealthFellowship.org</div>

            <div>
              <div class="bv_top_image_container">
                <img src={require('../../Images/Card/thank-you-for.png')} class="web_bv_thank_image" />
                <img src={require('../../Images/Card/sharing.png')} class="web_bv_sharing_image" />
              </div>
            </div>

            <div class="web_bv_link_url">{this.props.cardId}</div>
          </div>
        ) : null}

       {this.props.network == 'Smartshare'? (
          <div class="smartshare_web_bv_card_no_bottom fixed-bottom">
            <div class="web_bv_link_url">www.UniversalHealthFellowship.org</div>

          

            <div class="web_bv_link_url">{this.props.cardId}</div>
          </div>
        ) : null}

       
      </div>
    )
  }
}

// Participating member assignment of eligible medical expense sharing payment is permitted as consideration in full for services rendered. Reimbursement for hospital facility services is determined at a percentage of the facility’s Medicare allowable amounts (140% for Inpatient and 155% for Outpatient Services), or, in the absence of an applicable CMS fee schedule, in accordance with published UHF Sharing Guidelines. Acceptance of sharing payment for Eligible Expenses constitutes waiver of facility/provider right to balance bill patient.

// See applicable Sharing Guidelines for more details. Universal Health Fellowship (www.UniversalHealthFellowship.org) is a not-for-profit ministry that facilitates medical expense sharing through Universal HealthShare Programs. Universal HealthShare is not insurance and does not guarantee that eligible medical bills will be shared or otherwise paid. Universal HealthShare is not a discount card or program.
