import React, { Component } from 'react';
import {Form} from "react-bootstrap";
import SendIcon from '@material-ui/icons/Send';
import Draggable from 'react-draggable';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import ReadMoreReact from 'read-more-react';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import './ChatBoxMobile.css'
import axios from 'axios';
class ChatMobile extends Component {
    constructor(props) {
        super(props)
        this.state = {
          chat:[],
          message:'',
          chats:[],
          fullScreen:[],
          loader: false,
        }
        this.mesRef = React.createRef();
    }
    componentDidMount(){
        const messages =[
            {
                answer:'Hi, I’m Caryn.',
                context:`How can I help you today, ${this.props.username}?`,
                type:true,
                from:'incoming'
            },
            // {
            //     message:`Just type what you need or select one of the following:`,
            //     options: [{ message: 'Show me my Membership ID', intent: 'Membership ID' }, { message: "Find a provider", intent: 'Find provider' }, { message: "Request a Payment Card", intent: 'Payment Card' }],
            //     from:'incoming'
            // }, 
        ];
        if(sessionStorage.getItem('chathistory')){
            var messageHistory = JSON.parse(sessionStorage.getItem('chathistory'));
            this.setState({chats: messageHistory},() => { this.scrollToBottom(); });

        }else{
            this.setState({chats: messages},() => {  this.scrollToBottom(); });
        }
    }
    

    render(){
        return(<div style={{backgroundColor:'#fff', height:'100vh', overflowX:'hidden'}}>
                    <div className="chatHeader fixed-top">
                        <div style={{padding:6}}>
                            <img src={require("../../../assets/images/askcaryn.png")} style={{width:80}}/></div>
                        <p>YOUR AI-POWERED ASSISTANT</p>
                        <div><IconButton onClick={()=>this.props.closeChat()}><CloseIcon style={{color:'#fff', float:'right', marginTop:'-77px', marginLeft:'84vw'}}/></IconButton></div>
                    </div>

                    <div ref={this.mesRef} style={{height:'87vh' ,width:'100vw', paddingLeft:6, paddingRight:6, paddingTop:70, overflowX:'hidden', position:'fixed',paddingBottom:50,}} id="chatbody" class="chatbodyMobile">
                    {
                         this.state.chats.map((message, i)=>( 
                                <div class={message.context? "incoming":"outgoing"} style={{cursor:'context-menu'}}>
                                    
                                    {message.options &&
                                       <div style={{ display:'flex', flexWrap:'wrap'}}><br/>
                                            {message.options.map((intent)=>
                                            ( 
                                                <div class="intentbubble" onClick={()=>this.navigate(intent.intent)} style={{backgroundColor:'#54337A', color:'#fff', marginRight:5,cursor:'pointer', display:'flex', flexDirection:'row', alignItems:'center'}}>
                                                    <p>{intent.message}</p> <ChevronRightIcon style={{ fontSize: 25 }}/>
                                                </div>
                                            ))}
                                        </div>}
                                    
                                    <div class="bubble" style={{whiteSpace:'pre-line'}}>
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                        <div>
                                            <p className="searchfont">{this.state.fullScreen[i]? message.message ? message.message : message.context :<ReadMoreReact text={message.message ? message.message : message.context} ideal={80} readMoreText={"..."}/>}</p>
                                            {this.state.fullScreen[i] && message.meta.url !== null && 
                                                <div><br/>
                                                <Button variant="contained" style={{backgroundColor:'#543379', color:'#fff'}} onClick={()=>window.open(message.meta.url)}>
                                                    Read More 
                                                    <ChevronRightIcon style={{ fontSize: 25 }}/>
                                                </Button> 
                                                </div>}
                                        </div>
                                        <div>{!message.type && message.context && <div>{this.state.fullScreen[i]?<KeyboardArrowUpIcon onClick={()=>this.fullScreen(i)} style={{cursor: 'pointer'}}/>:<KeyboardArrowDownIcon onClick={()=>this.fullScreen(i)} style={{cursor: 'pointer'}}/>}</div>}</div>
                                    </div>
                                    </div>
                                   
                            </div>))
                    }
                            {this.state.loader && <div class="spinner fixed-bottom" style={{paddingBottom:80, paddingLeft:6}}>
                                <div class="bounce1"></div>
                                <div class="bounce2"></div>
                                <div class="bounce3"></div>
                            </div>}
                    </div> 

                    <div className="fixed-bottom">
                    <form onSubmit={this.sendMessage}> <div className="Bottom-Controls-BG" style={{flexDirection:'row', display:'flex', position:'absolute',}}>
                        <div style={{width:'85vw'}}> <Form.Control placeholder="Type your request to Caryn..." value={this.state.message} style={{borderRadius:40, paddingRight:20, paddingLeft:20}} onChange={e=>this.setState({message: e.target.value})}/> </div> 
                        <div className="search-icon-mobile"><SendIcon type="submit" value="Submit" style={{color: this.state.message? 'blue': null}} onClick={()=>this.sendMessage(true)}/></div>
                        </div>  
                    </form>
                    </div>
            </div>)
    }
    navigate=(intent)=>{
        this.props.navigate(intent);
    }
    scrollToBottom = () => {
        this.mesRef.current.scrollTop = this.mesRef.current.scrollHeight;
    }
    sendMessage=(e)=>{        
        if(e!==true)
        e.preventDefault();
        if(this.state.message !== ''){
            var messages={
                text: this.state.message,
                userID: localStorage.getItem('Member_EMPID')
              }
              var message={
                message:this.state.message,
                from:'outgoing'
            }
            this.setState({chats: this.state.chats.concat(message), message:'', loader:true},
                () => {
                    this.scrollToBottom();
                    axios.post('https://1dmevvy65l.execute-api.us-east-1.amazonaws.com/dev/lexbot', messages,{ headers: { 'Content-Type': 'application/json','Accept': 'application/json',  'Connection':"keep-alive"}})
                    .then(res=>{
                        this.recieveMessage(res.data.AIKBtext, res.data.intentRecognized, res.data.query)
                      
                        // this.splitMessage(res.data.message, res.data.intentRecognized)
                       
                    })
                    sessionStorage.setItem('chathistory', JSON.stringify(this.state.chats));
                })             
        }
    }
  
    recieveMessage = (message, intent, query) => {
        var messageAskCarynJumpto =[{
            context:"I’ve found other relevant results to your query in related documents.",
            meta:{
                url:`https://inf-labs.com/?isnav=true&memberId=${localStorage.getItem('Member_EMPID')}&query=${query}`
            }
        }]
        this.setState({
            chats: this.state.chats.concat(messageAskCarynJumpto),
            // chats: this.state.chats.concat(message),
             loader:false
            },
        () => {
            this.scrollToBottom();
            sessionStorage.setItem('chathistory', JSON.stringify(this.state.chats));
            ChatInput.map((res)=>{
                if(res.query===intent){
                    var chats =this.state.chats;
                    chats[chats.length-1].options=res.options;
                    this.setState({chats: chats},
                        () => {
                            this.scrollToBottom();
                            sessionStorage.setItem('chathistory', JSON.stringify(this.state.chats));
                        });
                    console.log(res.options)
                }
            })
        })
    }
    fullScreen=(i)=>{
        if(this.state.fullScreen[i]){
            var fullScreen = this.state.fullScreen;
            fullScreen[i]=false;
            this.setState({fullScreen: fullScreen});
        }
        else{
            var fullScreen = this.state.fullScreen;
            fullScreen[i]=true;
            this.setState({fullScreen: fullScreen});
        }
    }
}
export default ChatMobile;


export const ChatInput = 
localStorage.getItem('HealthTool') ==='true' || localStorage.getItem('HealthTool') === true ?
[
    {
        "query": "memberPortal",
        "options": [{ message: "Member Portal", intent: 'memberPortal' }],
    },

    {
        "query": "healthtool_card",
        "options": [{ message: "Show me my Health Tool Card", intent: 'Health Tool' }],
    },
    {
        "query": "call_agent",
        "options": [{ message: "Call Agent", intent: 'Call' }],
    },
    {
        "query": "email_agent",
        "options": [{ message: "Email Agent", intent: 'Email' }],
    },
    {
        "query": "card",
        "options": [{ message: 'Show me my Membership ID', intent: 'Membership ID' },
        { message: "Show me my Health Tool Card", intent: 'Health Tool' },
        { message: "Show me my Payment Card", intent: 'Payment Card' }],
    },
    {
        "query": "digitalCard",
        "options": [{ message: 'Show me my Membership ID', intent: 'Membership ID' },
        { message: "Show me my Health Tool Card", intent: 'Health Tool' },
        { message: "Show me my Payment Card", intent: 'Payment Card' }],
    },
    {
        "query": "transactions",
        "options": [{ message: 'Show me my Transactions', intent: 'Transactions' }],
    }
    ,
    {
        "query": "find_provider",
        "options": [{ message: 'Find a provider', intent: 'find_provider' }],
    }
    ,
    {
        "query": "guidelines_document",
        "options": [{ message: 'Show me my Documents', intent: 'documents' }],
    },

    {
        "query": "notifications",
        "options": [{ message: 'Show me my Notifications', intent: 'notifications' }],
    },

    {
        "query": "telemed",
        "options": [{ message: 'Open Telemed', intent: 'telemed' }],
    },
    {
        "query": "payment_card",
        "options": [{ message: "Show me my Payment Card", intent: 'Payment Card' }],
    },
    {
        "query": "payment_method",
        "options": [{ message: "Change Payment Method", intent: 'Payment Card' }],
    },
    {
        "query": "program_information",
        "options": [{ message: "Show me my Program Information", intent: 'program information' }],
    },
    {
        "query": "change_addons",
        "options": [{ message: "Change your addon", intent: 'program information' }],
    },
    {
        "query": "change_dependants",
        "options": [{ message: "Change your Dependent", intent: 'program information' }],
    },
    {
        "query": "my_needs",
        "options": [{ message: "Show my needs", intent: 'needs' }],
    },
    {
        "query": "print_IDCard",
        "options": [{ message: 'Print my Membership ID', intent: 'Membership ID' },
        { message: "Print my Health Tool Card", intent: 'Health Tool' },
        { message: "Print my Payment Card", intent: 'Payment Card' }],

    },
    {
        "query": "print_IDCard",
        "options": [{ message: 'Print my Membership ID', intent: 'Membership ID' },
        { message: "Print my Health Tool Card", intent: 'Health Tool' },
        { message: "Print my Payment Card", intent: 'Payment Card' }],
    }
] : [
    {
        "query": "memberPortal",
        "options": [{ message: "Member Portal", intent: 'memberPortal' }],
    },

    {
        "query": "healthtool_card",
        "options": [{ message: "Show me my Health Tool Card", intent: 'Health Tool' }],
    },
    {
        "query": "call_agent",
        "options": [{ message: "Call Agent", intent: 'Call' }],
    },
    {
        "query": "email_agent",
        "options": [{ message: "Email Agent", intent: 'Email' }],
    },
    {
        "query": "card",
        "options": [{ message: 'Show me my Membership ID', intent: 'Membership ID' },
        { message: "Show me my Payment Card", intent: 'Payment Card' }],
    },
    {
        "query": "digitalCard",
        "options": [{ message: 'Show me my Membership ID', intent: 'Membership ID' },
        { message: "Show me my Payment Card", intent: 'Payment Card' }],
    },
    {
        "query": "payment_method",
        "options": [{ message: "Change Payment Method", intent: 'Payment Card' }],
    },
    {
        "query": "program_information",
        "options": [{ message: "Show me my Program Information", intent: 'program information' }],
    },
    {
        "query": "change_addons",
        "options": [{ message: "Change your addon", intent: 'program information' }],
    },
    {
        "query": "change_dependants",
        "options": [{ message: "Change your Dependent", intent: 'program information' }],
    },
    {
        "query": "my_needs",
        "options": [{ message: "Show my needs", intent: 'needs' }],
    },
    {
        "query": "transactions",
        "options": [{ message: 'Show me my Transactions', intent: 'Transactions' }],
    }
    ,
    {
        "query": "find_provider",
        "options": [{ message: 'Find a provider', intent: 'find_provider' }],
    }
    ,
    {
        "query": "guidelines_document",
        "options": [{ message: 'Show me my Documents', intent: 'documents' }],
    },

    {
        "query": "notifications",
        "options": [{ message: 'Show me my Notifications', intent: 'notifications' }],
    },

    {
        "query": "telemed",
        "options": [{ message: 'Open Telemed', intent: 'telemed' }],
    },
    {
        "query": "payment_card",
        "options": [{ message: "Show me my Payment Card", intent: 'Payment Card' }],
    },
    {
        "query": "program_information",
        "options": [{ message: "Show me my Program Information", intent: 'program information' }],
    },
    {
        "query": "print_IDCard",
        "options": [{ message: 'Print my Membership ID', intent: 'Membership ID' },
        { message: "Print my Payment Card", intent: 'Payment Card' }],

    },
    {
        "query": "print_IDCard",
        "options": [{ message: 'Print my Membership ID', intent: 'Membership ID' },
        { message: "Print my Payment Card", intent: 'Payment Card' }],
    }
]