import React, { Component } from 'react'
import { BrowserView, MobileView, isBrowser, isMobile, isTablet, deviceType } from 'react-device-detect'
import MobileHome from './MobileScreen/MobileHome'
import WebHome from './WebScreen/WebHome'

import { getEmailIdFromCognito } from '../actions/homeActions'

import {getMyneeds,getSourceID,healthtools, gethealthcard} from './ApiCall';


export default class WebMobileRoot extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {

    this.myneedsdata()
    this.getSourceIDbyemail()
  }

  getSourceIDbyemail() {
    getSourceID().then(res => {
      console.log('Member source id issss', res.data.memberIdSource)
      localStorage.setItem('sourceid',res.data.memberIdSource);
    })
  }

  myneedsdata() {
    getMyneeds().then(res => {
      console.log('My Needs server response innnn WebRoot mobile', res)

      if (res && res.data.totalrecords > 0) {
        // this.setState({ showmyneeds: true })
        sessionStorage.setItem('myneedsfooter','true');
      } else {
        sessionStorage.setItem('myneedsfooter','false');
      }
    })
  }


  render() {
    return (
      <div>
        { deviceType == 'browser' && (isMobile == false || isMobile == 'false') && (isTablet == false || isTablet == 'false') ? <WebHome />
          : <MobileHome />}

        {/*<BrowserView>*/}
          {/*<WebHome />*/}
          {/*/!* <Dashboard /> *!/*/}
        {/*</BrowserView>*/}
        {/*<MobileView>*/}
          {/*<MobileHome />*/}
        {/*</MobileView>*/}
      </div>
    )
  }
}
